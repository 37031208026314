class Firmware {
  constructor (data = {}) {
    const {
      version,
      descriptionShort,
      descriptionLong,
      file,
      isDefault
    } = data

    this.version = version
    this.descriptionShort = descriptionShort
    this.descriptionLong = descriptionLong
    this.file = file
    this.isDefault = isDefault
  }
}

Object.defineProperty(Firmware, 'initialData', {
  value: {
    version: '',
    descriptionShort: '',
    descriptionLong: '',
    file: undefined,
    isDefault: false
  },
  writable: false
})

export default Firmware
