import { useQuery } from '@apollo/react-hooks'
import { formikForm, TextInput } from 'app/components/forms'
import SelectField from 'app/components/forms/SelectField'
import DeviceFields from 'app/pages/devices/forms/components/DeviceFields'
import { Field, FieldArray } from 'formik'
import { DEVICE_TYPES_LIST } from 'gql/schema/devices/queries'
import useFetchStorageList from 'hooks/gql/useFetchStorageList'
import { required } from 'libs/forms/validators'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Col, Form, FormGroup, Row } from 'reactstrap'
import Card from 'reactstrap/es/Card'
import CardBody from 'reactstrap/es/CardBody'
import CardHeader from 'reactstrap/es/CardHeader'

export const DEVICE_INITIAL_VALUES = {
  serialNumber: '',
  hardwareVersion: '',
  version: '',
  deviceType: '',
  buildingDate: '',
  builderId: '',
  currentStorageId: [],
  deviceFields: []
}

const deviceFieldsBuilder = (value) => {
  return value.split(',')
    .map(value => ({
      [value]: {
        value: '',
        command: '',
        validation: '',
        isExternal: false
      }
    }))
}

const DeviceForm = formikForm(({
  isSubmitting, isValid, handleSubmit, error, setFieldValue, values, dirty, editState = false
}) => {
  const { t } = useTranslation()

  const storagesQuery = useFetchStorageList()
  const [storages, setStorages] = useState([])

  const deviceTypesQuery = useQuery(DEVICE_TYPES_LIST)
  const [deviceTypes, setDeviceTypes] = useState([])

  useEffect(() => {
    if (storagesQuery.data) {
      const { storageList } = storagesQuery.data
      setStorages(storageList.map(({ _id, name }) => ({
        value: _id,
        label: `${name}`
      })))
    }
  }, [storagesQuery, error])

  useEffect(() => {
    if (deviceTypesQuery.data) {
      const { deviceTypesList } = deviceTypesQuery.data
      setDeviceTypes(deviceTypesList.map(({ _id, sku }) => {
        return {
          value: _id,
          label: `${sku}`
        }
      }))
    }
  }, [deviceTypesQuery, setDeviceTypes])

  const onDeviceTypeSelected = useCallback((id) => {
    const { deviceTypesList } = deviceTypesQuery.data
    const deviceType = deviceTypesList.find(v => v._id === id)
    setFieldValue('deviceFields', deviceFieldsBuilder(deviceType.deviceFields))
  }, [deviceTypesQuery, setFieldValue])

  return (
    <>
      <Form className='d-flex flex-column justify-content-between' onSubmit={handleSubmit}>
        {error && <Alert color='danger'>{error.message}</Alert>}
        <Row form>
          <Col md={12}>
            <Card>
              <CardHeader>{t('labels.information')}</CardHeader>
              <CardBody>
                <Row form>
                  <Col>
                    <Field
                      name='serialNumber'
                      label={t('labels.serialNumber')}
                      labelClasses='text-muted'
                      component={TextInput}
                      type='text'
                      validate={required}
                    />
                  </Col>
                  <Col>
                    <Field
                      name='hardwareVersion'
                      label={t('labels.hardwareVersion')}
                      labelClasses='text-muted'
                      component={TextInput}
                      type='text'
                      validate={required}
                    />
                  </Col>
                  <Col>
                    <Field
                      name='deviceType'
                      label={t('labels.deviceType')}
                      labelClasses='text-muted'
                      options={deviceTypes}
                      component={SelectField}
                      isMulti={false}
                      isLoading={deviceTypesQuery.loading}
                      validate={required}
                      changeHandler={({ value }) => {
                        onDeviceTypeSelected(value)
                      }}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col>
                    <Field
                      name='buildingDate'
                      label={t('labels.devices.building_date')}
                      labelClasses='text-muted'
                      component={TextInput}
                      type='date'
                      validate={required}
                    />
                  </Col>
                  <Col>
                    <Field
                      name='builderId'
                      label={t('labels.devices.builderId')}
                      labelClasses='text-muted'
                      component={TextInput}
                      type='text'
                      validate={required}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col md={12}>
                    <Field
                      name='currentStorageId'
                      label={t('labels.storage.name')}
                      labelClasses='text-muted'
                      options={storages}
                      component={SelectField}
                      isMulti={false}
                      isLoading={storagesQuery.loading}
                      validate={required}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          {values.deviceFields.length > 0 && <Col md={12}>
            <Card>
              <CardHeader>{t('labels.specifications')}</CardHeader>
              <CardBody>
                <FieldArray
                  name='deviceFields'
                  render={arrayHelpers => {
                    const { deviceFields } = arrayHelpers.form.values
                    return (
                      <>
                        {deviceFields.map(
                          (value, index) => {
                            const key = Object.keys(value)[0]
                            return (<DeviceFields key={key} index={index} fieldsKey={key} fields={value}/>)
                          }
                        )}
                      </>
                    )
                  }}/>
              </CardBody>
            </Card>
          </Col>}
        </Row>
        <FormGroup className='d-flex align-items-center justify-content-between m-0'>
          <Button type='submit' color='primary' disabled={isSubmitting || !isValid || !dirty}>
            {editState ? t('labels.update') : t('labels.create')}
          </Button>
        </FormGroup>
      </Form>
    </>

  )
})

export default DeviceForm
