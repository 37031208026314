import gql from 'graphql-tag'

export const ORDERS = gql`
    query ($id:ID!) {
        order(id: $id){
            _id
            status
            companyId
            customerId
            order
            deliveryStorage {
                name
                address
            }
            trackingNumber
            deliveryInformation
        }
    }
`

export const ORDERS_BY_COMPANY_LIST = gql`
    query ($id:ID!) {
        ordersByCompany(id: $id){
            _id
            status
            companyId
            company {
                _id
                name
            }
            customerId
            customer {
                _id
                name
            }
            order
            orderList {
                _id
                deviceUniqueId
            }
            deliveryStorageId
            deliveryStorage {
                _id
                name
                address
            }
            trackingNumber
            deliveryInformation
        }
    }
`

export const ORDERS_LIST = gql`
    query {
        ordersList{
            _id
            status
            companyId
            customerId
            order
            deliveryStorage {
                name
                address
            }
            trackingNumber
            deliveryInformation
        }
    }
`
