import BaseLayout from 'app/components/layout/BaseLayout'
import CreateCompany from 'app/pages/company/CreateCompany'
import CreateSubCompany from 'app/pages/company/CreateSubCompany'
import EditCompany from 'app/pages/company/EditCompany'
import EditSubCompany from 'app/pages/company/EditSubCompany'
import SubCompanies from 'app/pages/company/SubCompanies'
import DeviceCreate from 'app/pages/devices/DeviceCreate'
import DeviceEdit from 'app/pages/devices/DeviceEdit'
import DeviceMove from 'app/pages/devices/DeviceMove'
import DevicesList from 'app/pages/devices/DevicesList'
import DevicesManage from 'app/pages/devices/DevicesManage'
import DeviceTypeCreate from 'app/pages/devices/DeviceTypeCreate'
import DeviceTypeEdit from 'app/pages/devices/DeviceTypeEdit'
import DeviceTypeFirmwareList from 'app/pages/devices/DeviceTypeFirmwareList'
import DeviceTypesList from 'app/pages/devices/DeviceTypesList'
import DeviceTypeView from 'app/pages/devices/DeviceTypeView'
import DeviceView from 'app/pages/devices/DeviceView'
import LogoutPage from 'app/pages/logout/LogoutPage'
import OrdersList from 'app/pages/orders/OrdersList'
import StorageCreate from 'app/pages/storage/StorageCreate'
import StorageEdit from 'app/pages/storage/StorageEdit'
import StorageList from 'app/pages/storage/StorageList'
import CreateUser from 'app/pages/users/CreateUser'
import EditUser from 'app/pages/users/EditUser'
import { PRIVACY_ACTIONS, PRIVACY_TYPE } from 'models/Privacy'
import UsersType from 'models/UsersType'
import React from 'react'
import { Switch } from 'react-router-dom'
import Companies from './app/pages/company'
import Dashboard from './app/pages/dashboard/DashBoard'
import LoginPage from './app/pages/login'
import RecoverPage from './app/pages/recover'
import ResetPassword from './app/pages/reset'
import Users from './app/pages/users'
import withCurrentUser from './hoc/withCurrentUser'

import { OnlyPublicRoute, PrivateRoute } from './routes'

const Private = withCurrentUser(PrivateRoute)
const OnlyPublic = withCurrentUser(OnlyPublicRoute)

const ApplicationRouter = () => {
  return (
    <Switch>
      <OnlyPublic exact path='/' component={LoginPage}/>
      <OnlyPublic exact path='/recover' component={RecoverPage}/>
      <OnlyPublic exact path='/recover/:token' component={ResetPassword}/>
      <Private exat path='/logout' component={LogoutPage}/>

      {/* USERS */}
      <Private
        exact path='/admin/users'
        layout={BaseLayout}
        component={Users}
        allowedRoles={[UsersType.ADMIN, UsersType.COMPANY_ADMIN]}
      />
      <Private
        exact path='/user/create' layout={BaseLayout} component={CreateUser}
        allowedRoles={[UsersType.ADMIN, UsersType.COMPANY_ADMIN]}
        privacyObject={PRIVACY_TYPE.USERS} privacyAction={PRIVACY_ACTIONS.CREATE}
      />
      <Private
        exact path='/user/edit/:id' layout={BaseLayout} component={EditUser}
        allowedRoles={[UsersType.ADMIN, UsersType.COMPANY_ADMIN]}
        privacyObject={PRIVACY_TYPE.USERS} privacyAction={PRIVACY_ACTIONS.EDIT}
      />

      {/* COMPANIES */}
      <Private
        exact path='/companies' layout={BaseLayout} component={Companies}
        allowedRoles={[UsersType.ADMIN]}
        privacyObject={PRIVACY_TYPE.COMPANIES} privacyAction={PRIVACY_ACTIONS.VIEW}
      />
      <Private
        exact path='/sub-companies' layout={BaseLayout} component={SubCompanies}
        allowedRoles={[UsersType.COMPANY_ADMIN]}
      />
      <Private
        exact path='/company/create' layout={BaseLayout} component={CreateCompany}
        allowedRoles={[UsersType.ADMIN, UsersType.COMPANY_ADMIN]}
        privacyObject={PRIVACY_TYPE.COMPANIES} privacyAction={PRIVACY_ACTIONS.CREATE}
      />
      <Private
        exact path='/sub-company/create' layout={BaseLayout} component={CreateSubCompany}
        allowedRoles={[UsersType.ADMIN, UsersType.COMPANY_ADMIN]}
      />
      <Private
        exact path='/sub-company/edit/:id' layout={BaseLayout} component={EditSubCompany}
        allowedRoles={[UsersType.ADMIN, UsersType.COMPANY_ADMIN]}
      />
      <Private
        exact path='/company/edit/:id' layout={BaseLayout} component={EditCompany}
        allowedRoles={[UsersType.ADMIN, UsersType.COMPANY_ADMIN]}
        privacyObject={PRIVACY_TYPE.COMPANIES} privacyAction={PRIVACY_ACTIONS.EDIT}
      />
      <Private
        exact path='/company/info/' layout={BaseLayout} component={EditCompany}
        allowedRoles={[UsersType.ADMIN, UsersType.COMPANY_ADMIN]}
        privacyObject={PRIVACY_TYPE.COMPANIES} privacyAction={PRIVACY_ACTIONS.VIEW}
      />

      {/* STORAGE */}
      <Private
        exact path='/storage/list' layout={BaseLayout} component={StorageList}
        allowedRoles={[UsersType.COMPANY_ADMIN, UsersType.ADMIN, UsersType.CLIENT_COMPANY_ADMIN]}
        privacyObject={PRIVACY_TYPE.STORES} privacyAction={PRIVACY_ACTIONS.VIEW}
      />
      <Private
        exact path='/storage/create' layout={BaseLayout} component={StorageCreate}
        allowedRoles={[UsersType.COMPANY_ADMIN, UsersType.ADMIN]}
        privacyObject={PRIVACY_TYPE.STORES} privacyAction={PRIVACY_ACTIONS.CREATE}
      />
      <Private
        exact path='/storage/edit/:id' layout={BaseLayout} component={StorageEdit}
        allowedRoles={[UsersType.COMPANY_ADMIN, UsersType.ADMIN]}
        privacyObject={PRIVACY_TYPE.STORES} privacyAction={PRIVACY_ACTIONS.EDIT}
      />

      {/* DEVICES */}
      <Private
        exact path='/devices/manage' layout={BaseLayout} component={DevicesManage}
        allowedRoles={[UsersType.COMPANY_ADMIN, UsersType.ADMIN]}
      />
      <Private
        exact path='/device/move/:id' layout={BaseLayout} component={DeviceMove}
        allowedRoles={[UsersType.COMPANY_ADMIN, UsersType.ADMIN]}
      />

      <Private
        exact path='/devices/list' layout={BaseLayout} component={DevicesList}
        allowedRoles={[UsersType.COMPANY_ADMIN, UsersType.COMPANY_ADMIN, UsersType.CLIENT_COMPANY_ADMIN]}
        privacyObject={PRIVACY_TYPE.DEVICES} privacyAction={PRIVACY_ACTIONS.VIEW}
      />
      <Private
        exact path='/device/create' layout={BaseLayout} component={DeviceCreate}
        allowedRoles={[UsersType.ADMIN]}
        privacyObject={PRIVACY_TYPE.DEVICES} privacyAction={PRIVACY_ACTIONS.CREATE}
      />
      <Private
        exact path='/device/edit/:id' layout={BaseLayout} component={DeviceEdit}
        allowedRoles={[UsersType.ADMIN, UsersType.COMPANY_ADMIN, UsersType.CLIENT_COMPANY_ADMIN]}
        privacyObject={PRIVACY_TYPE.DEVICES} privacyAction={PRIVACY_ACTIONS.EDIT}
      />
      <Private
        exact path='/device/view/:id' layout={BaseLayout} component={DeviceView}
        allowedRoles={[UsersType.ADMIN]}
      />

      {/* DEVICES TYPES */}
      <Private
        exact path='/device/types' layout={BaseLayout} component={DeviceTypesList}
        allowedRoles={[UsersType.ADMIN]}
      />
      <Private
        exact path='/device/types/:id/firmware' layout={BaseLayout} component={DeviceTypeFirmwareList}
        allowedRoles={[UsersType.ADMIN]}
      />
      <Private
        exact path='/device/types/create' layout={BaseLayout} component={DeviceTypeCreate}
        allowedRoles={[UsersType.ADMIN]}
      />
      <Private
        exact path='/device/types/edit/:id' layout={BaseLayout} component={DeviceTypeEdit}
        allowedRoles={[UsersType.ADMIN]}
      />
      <Private
        exact path='/device/types/view/:id' layout={BaseLayout} component={DeviceTypeView}
        allowedRoles={[UsersType.ADMIN]}
      />

      {/* DASHBOARD */}
      <Private exact path='/admin' layout={BaseLayout} component={Dashboard} allowedRoles={[UsersType.ADMIN, UsersType.COMPANY_ADMIN]}/>

      {/* ====  USERS ==== */}
      <Private exact path='/client/dashboard' layout={BaseLayout} component={Dashboard} allowedRoles={[UsersType.CLIENT_COMPANY_ADMIN]}/>

      {/* ====  ORDERS ==== */}
      <Private exact path='/orders/list' layout={BaseLayout} component={OrdersList} allowedRoles={[UsersType.ADMIN, UsersType.COMPANY_ADMIN]}/>
    </Switch>
  )
}

export default ApplicationRouter
