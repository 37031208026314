import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faBookmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Table } from 'reactstrap'

const parseAddress = (value) => {
  return value ? `${value.zip}, ${value.address}, ${value.province}, ${value.region}, ${value.country}` : ''
}

const CompaniesList = ({ companiesList, deleteCompanyHandler }) => {
  const { t } = useTranslation()

  return (
    <Table borderless responsive className='table-hover'>
      <thead>
        <tr>
          <th>{t('labels.company.name')}</th>
          <th>{t('labels.company.contact_name')}</th>
          <th>{t('labels.company.email')}</th>
          <th>{t('labels.company.address')}</th>
          <th className='text-center'>{t('labels.company.admins')}</th>
          <th className='text-center'>Action</th>
        </tr>
      </thead>
      <tbody>
        {companiesList && companiesList.map((value) => {
          return (
            <tr key={value._id} className='font-sm'>
              <td>{value.defaultCompany && <FontAwesomeIcon icon={faBookmark}/>} {value.name} </td>
              <td>{value.contactName}</td>
              <td>{value.email}</td>
              <td>{parseAddress(value.address)}</td>
              <td>
                <div style={{ maxWidth: '200px' }}>{value.companyAdmins && value.companyAdmins.map(userData => {
                  return <span key={userData._id} className='badge badge-primary ml-1'>{userData.name} {userData.surname}</span>
                })}
                </div>
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Link to={`/company/edit/${value._id}`} className='btn btn-ghost-info'>
                    <FontAwesomeIcon icon={faEdit}/>
                  </Link>
                  <button
                    disabled={value.defaultCompany}
                    className={cx('btn', { 'btn-ghost-danger': !value.defaultCompany }, { 'btn-ghost-secondary': value.defaultCompany })}
                    type='button'
                    onClick={() => deleteCompanyHandler && deleteCompanyHandler(value._id)}
                  >
                    <FontAwesomeIcon icon={faTrashAlt}/>
                  </button>
                </div>
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default CompaniesList
