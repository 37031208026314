import { useMutation, useQuery } from '@apollo/react-hooks'
import BreadcrumbCommon from 'app/components/common/BreadcrumbCommon'
import HandlerComponent from 'app/components/common/HandlerComponent'
import BasePage from 'app/components/layout/BasePage'
import CompanyForm from 'app/pages/company/components/CompanyForm'
import { API_HOST } from 'config'
import { UPDATE_COMPANY } from 'gql/schema/company/mutations'
import { COMPANIES_LIST, COMPANY } from 'gql/schema/company/queries'
import { ME_QUERY } from 'gql/schema/user/queries'
import withCurrentUser from 'hoc/withCurrentUser'
import UsersType from 'models/UsersType'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { BreadcrumbItem } from 'reactstrap'
import CardBody from 'reactstrap/lib/CardBody'

/* CREATE COMPANY PAGE */
const EditCompany = ({ user }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { id } = useParams()
  const { companyId, role } = user

  const [company, setCompany] = useState(null)
  /* FETCH COMPANY DATA */
  const { loading, error, data } = useQuery(COMPANY, {
    variables: { id: id || companyId },
    fetchPolicy: 'no-cache'
  })

  const refetchQueries = useMemo(() => {
    const refetchQueriesList = [{ query: COMPANIES_LIST }]
    if (companyId === id || !id) {
      refetchQueriesList.push({
        query: ME_QUERY
      })
    }

    return refetchQueriesList
  }, [companyId, id])

  const [updateCompany] = useMutation(UPDATE_COMPANY, {
    onCompleted: ({ updateCompany: { status } }) => {
      if (status === 'ok') {
        switch (role) {
          case UsersType.ADMIN:
            history.push('/companies')
            break
          case UsersType.COMPANY_ADMIN:
            window.location.reload()
            break
          default:
            break
        }
      }
    },
    refetchQueries
  })

  const handleSubmit = useCallback(async (values) => {
    if (updateCompany) {
      const companyAdmins = values.companyAdmins.map(admin => admin.value)
      if (typeof values.companyLogo === 'string') {
        values.companyLogo = null
      }

      await updateCompany({
        variables: {
          ...values,
          companyAdmins
        }
      })
    }
  }, [updateCompany])

  useMemo(() => {
    if (data) {
      const companyData = { ...data.company }

      const { companyAdmins } = companyData
      companyData.companyAdmins = companyAdmins.map(({ _id, name, surname }) => {
        return {
          value: _id,
          label: `${name} ${surname}`,
          isFixed: user._id === _id
        }
      })

      companyData.companyLogo = `${API_HOST}/${companyData.companyLogo}?rnd=${Math.random()}` // disabled logo cache

      setCompany(companyData)
    }
  }, [data, user, setCompany])

  return (
    <>
      <BreadcrumbCommon>
        <BreadcrumbItem active>{t('common.menu.company')}</BreadcrumbItem>
        <BreadcrumbItem active>{t('common.page.admin.edit')}</BreadcrumbItem>
      </BreadcrumbCommon>
      <BasePage title={t('common.page.admin.edit_company')}>
        <CardBody>
          <HandlerComponent loading={loading} error={error}/>
          {company && <CompanyForm onSubmit={handleSubmit} initialValues={company} enableReinitialize editState/>}
        </CardBody>
      </BasePage>
    </>
  )
}

EditCompany.propTypes = {
  user: PropTypes.object
}

export default withCurrentUser(EditCompany)
