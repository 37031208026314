import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Util } from 'reactstrap'

import cssModule from './Text.module.scss'

const Text = (props) => {
  const {
    tag: Tag,
    className,
    fontWeight,
    size,
    ...attributes
  } = props

  const classes = Util.mapToCssModules(
    cx('text', `text-${fontWeight}`, className),
    cssModule
  )

  return <Tag {...attributes} className={classes} />
}

Text.propTypes = {
  className: PropTypes.string,
  tag: Util.tagPropType,
  link: PropTypes.bool,
  fontWeight: PropTypes.oneOf(['regular', 'medium', 'semi-bold', 'bold'])
}

Text.defaultProps = {
  tag: 'span',
  fontWeight: 'regular'
}

export default Text
