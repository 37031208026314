import { useApolloClient } from '@apollo/react-hooks'
import { AUTH_QUERY } from 'gql/client'
import React from 'react'

const withCurrentUser = (Component) => (props) => {
  const client = useApolloClient()
  const { user, isAuthenticated } = client.readQuery({ query: AUTH_QUERY })

  return <Component user={user} isAuthenticated={isAuthenticated} {...props} />
}

export default withCurrentUser
