import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isAdmin, isCompanyAdmin } from 'models/UsersType'
import React from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'reactstrap'

const DevicesTable = ({ devicesList, deleteCallBack, userRole }) => {
  if (!devicesList) return null

  return (
    <>
      <Table borderless responsive className='table-hover'>
        <thead>
          <tr>
            <th>Storage</th>
            <th>Company</th>
            <th>device Unique Id</th>
            {isAdmin(userRole) && <th className='text-center'>Action</th>}
          </tr>
        </thead>
        <tbody>
          {devicesList.map(value => {
            return (
              <tr key={value._id} className='font-sm'>
                <td>{value.currentStorage.name}</td>
                <td>{value.company.name}</td>
                <td>{value.deviceUniqueId}</td>
                {isAdmin(userRole) && <td>
                  <div className='d-flex justify-content-center'>
                    {/* edit button */}
                    <Link disabled to={`/device/edit/${value._id}`} className='btn btn-ghost-info'>
                      <FontAwesomeIcon icon={faEdit} />
                    </Link>
                    {/* delete button */}
                    <button
                      className='btn btn-ghost-danger'
                      type='button'
                      onClick={() => deleteCallBack && deleteCallBack(value._id)}
                      disabled={isCompanyAdmin(userRole)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </div>
                </td>}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

export default DevicesTable
