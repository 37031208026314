class CustomerCompany {
  constructor (data = {}) {
    const {
      name,
      email,
      phone,
      address,
      contactName,
      companyLogo
    } = data

    this.name = name
    this.email = email
    this.phone = phone
    this.address = address
    this.contactName = contactName
    this.companyLogo = companyLogo
  }
}

Object.defineProperty(CustomerCompany, 'initialData', {
  value: {
    name: '',
    email: '',
    phone: '',
    address: {
      country: '',
      region: '',
      province: '',
      zip: '',
      address: ''
    },
    contactName: '',
    companyLogo: ''
  },
  writable: false
})

export default CustomerCompany
