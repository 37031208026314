import { useMutation } from '@apollo/react-hooks'
import BreadcrumbCommon from 'app/components/common/BreadcrumbCommon'
import BasePage from 'app/components/layout/BasePage'
import DeviceTypeForm from 'app/pages/devices/forms/DeviceTypeForm'
import { CREATE_DEVICE_TYPE } from 'gql/schema/devices/mutations'
import { DEVICE_TYPES_LIST } from 'gql/schema/devices/queries'
import withCurrentUser from 'hoc/withCurrentUser'
import DeviceType from 'models/deviceType'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import CardBody from 'reactstrap/lib/CardBody'

const DeviceTypeCreate = withCurrentUser(() => {
  const { t } = useTranslation()
  const history = useHistory()

  const [createDeviceTypeMutation] = useMutation(
    CREATE_DEVICE_TYPE,
    {
      onCompleted ({ createDeviceType }) {
        createDeviceType && history.push('/device/types')
      },
      refetchQueries: [{ query: DEVICE_TYPES_LIST }]
    }
  )

  const createDeviceTypeHandler = useCallback((values) => {
    if (createDeviceTypeMutation) {
      createDeviceTypeMutation({
        variables: {
          ...values
        }
      })
    }
  }, [createDeviceTypeMutation])

  return (
    <>
      <BreadcrumbCommon
        customPath={'/device/types'}
        customLinkText={t('common.page.admin.device_types')}/>
      <BasePage>
        <CardBody>
          <DeviceTypeForm
            onSubmit={createDeviceTypeHandler}
            initialValues={DeviceType.initialData}
          />
        </CardBody>
      </BasePage>
    </>
  )
})

export default DeviceTypeCreate
