import { useQuery } from '@apollo/react-hooks'
import { StorageListToMoveQuery } from 'gql/schema'

const useFetchStorageListToMove = () => {
  return useQuery(StorageListToMoveQuery, {
    fetchPolicy: 'network-only'
  })
}

export default useFetchStorageListToMove
