import { loader } from 'graphql.macro'

const SignIn = loader('gql/schema/auth/signIn.graphql')
const Me = loader('gql/schema/user/me.graphql')
const AuthQuery = loader('gql/client/authQuery.graphql')
/* USER */
const UserQuery = loader('gql/schema/user/user.graphql')
const UpdateUserMutation = loader('gql/schema/user/update.graphql')
const UsersListQuery = loader('gql/schema/user/usersList.graphql')
const DeleteUserMutation = loader('gql/schema/user/deleteUser.graphql')
const ChangePasswordMutation = loader('gql/schema/user/changePassword.graphql')
/* COMPANY */
const CompanyQuery = loader('gql/schema/company/company.graphql')
const CompaniesListQuery = loader('gql/schema/company/companiesList.graphql')
const CreateCompanyMutation = loader('gql/schema/company/create.graphql')
const DeleteCompanyMutation = loader('gql/schema/company/delete.graphql')
const UpdateCompanyMutation = loader('gql/schema/company/update.graphql')
/* STORAGE */
const StorageQuery = loader('gql/schema/storage/storage.graphql')
const StorageListQuery = loader('gql/schema/storage/storageList.graphql')
const StorageListToMoveQuery = loader('gql/schema/storage/storageListToMove.graphql')
const StorageCreateMutation = loader('gql/schema/storage/storageCreate.graphql')
const StorageUpdateMutation = loader('gql/schema/storage/storageUpdate.graphql')
const StorageDeleteMutation = loader('gql/schema/storage/storageDelete.graphql')

export {
  SignIn,
  Me,
  AuthQuery,
  /* USER */
  UserQuery,
  UsersListQuery,
  DeleteUserMutation,
  UpdateUserMutation,
  ChangePasswordMutation,
  /* COMPANY */
  CompanyQuery,
  CompaniesListQuery,
  CreateCompanyMutation,
  DeleteCompanyMutation,
  UpdateCompanyMutation,
  /* STORAGE */
  StorageQuery,
  StorageListQuery,
  StorageListToMoveQuery,
  StorageCreateMutation,
  StorageDeleteMutation,
  StorageUpdateMutation
}
