import React from 'react'

const BaseFooter = () => {
  return (
    <footer className='app-footer'>
      <div>
        <span>© Provisioning tool V2</span>
      </div>
      <div className='ml-auto'>
        <span>Powered by </span>
        <a href='https://www.devinterface.com'>DevInterface</a>
      </div>
    </footer>
  )
}

export default BaseFooter
