import { useMutation } from '@apollo/react-hooks'
import HandlerComponent from 'app/components/common/HandlerComponent'
import { TextInput } from 'app/components/forms'
import SelectField from 'app/components/forms/SelectField'
import { Field, Formik } from 'formik'
import { ORDER_UPDATE } from 'gql/schema/orders/mutations'
import withCurrentUser from 'hoc/withCurrentUser'
import { required } from 'libs/forms/validators'
import { ORDER_STATUS_LIST } from 'models/orderStatus'
import { isAdmin, isClientCompanyAdmin } from 'models/UsersType'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Form, FormGroup } from 'reactstrap'
import Card from 'reactstrap/lib/Card'
import CardBody from 'reactstrap/lib/CardBody'
import CardFooter from 'reactstrap/lib/CardFooter'
import Col from 'reactstrap/lib/Col'
import Row from 'reactstrap/lib/Row'

const OrderEditForm = ({ order, user }) => {
  const { t } = useTranslation()
  const initialData = {
    status: {
      value: order.status,
      label: order.status.toUpperCase()
    },
    deliveryStorageId: order.deliveryStorage.name,
    deliveryInformation: order.deliveryInformation || '',
    trackingNumber: order.trackingNumber || ''
  }

  const [updateOrder] = useMutation(ORDER_UPDATE, {
    onCompleted: ({ orderUpdate: { status } }) => {
      status === 'ok' && window.location.reload()
    }
  })
  const handleUpdate = useCallback(async formData => {
    if (updateOrder) {
      await updateOrder({
        variables: {
          ...formData,
          id: order._id,
          deliveryStorageId: order.deliveryStorageId,
          status: formData.status.value
        }
      })
    }
  }, [updateOrder, order])

  return (
    <>
      <Card>
        <Formik
          enableReinitialize
          onSubmit={handleUpdate}
          initialValues={initialData}>
          {({
            error,
            isSubmitting,
            isValid,
            handleSubmit
          }) => {
            return (
              <>
                <HandlerComponent error={error}/>
                <Form onSubmit={handleSubmit}>
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <Field
                          name='status'
                          label={t('labels.order_status')}
                          labelClasses='text-muted'
                          options={ORDER_STATUS_LIST.map(v => {
                            return {
                              value: v,
                              label: v.toUpperCase()
                            }
                          })}
                          component={SelectField}
                          isMulti={false}
                          validate={required}
                          disabled={isClientCompanyAdmin(user.role)}
                        />
                      </Col>
                      <Col md={6}>
                        <Field
                          name='trackingNumber'
                          type='text'
                          label={t('labels.trackingNumber')}
                          labelClasses='text-muted'
                          component={TextInput}
                          validate={required}
                          disabled={isClientCompanyAdmin(user.role)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Field
                          name='deliveryStorageId'
                          type='text'
                          label={t('labels.delivery_storage')}
                          labelClasses='text-muted'
                          component={TextInput}
                          validate={required}
                          disabled
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Field
                          name='deliveryInformation'
                          type='text'
                          label={t('labels.delivery_information')}
                          labelClasses='text-muted'
                          component={TextInput}
                          disabled={isClientCompanyAdmin(user.role)}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                  {isAdmin(user.role) && <CardFooter>
                    <FormGroup className='d-flex align-items-center justify-content-between m-0'>
                      <Button type='submit' color='primary' disabled={isSubmitting || !isValid}>
                        {t('labels.update')}
                      </Button>
                    </FormGroup>
                  </CardFooter>}
                </Form>
              </>
            )
          }}
        </Formik>
      </Card>
    </>
  )
}

export default withCurrentUser(OrderEditForm)
