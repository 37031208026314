import { formikForm, TextInput } from 'app/components/forms'
import { Field } from 'formik'
import { required } from 'libs/forms/validators'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Form, FormGroup } from 'reactstrap'
import Button from 'reactstrap/lib/Button'
import Col from 'reactstrap/lib/Col'
import Row from 'reactstrap/lib/Row'

const DeviceTypeForm = formikForm((
  { isSubmitting, isValid, handleSubmit, error, editState, dirty, ...rest }
) => {
  const { t } = useTranslation()
  return (
    <>
      <Form className='d-flex flex-column justify-content-between' onSubmit={handleSubmit}>
        {error && <Alert color='danger'>{error.message}</Alert>}
        <Row form>
          <Col>
            <Field
              name='sku'
              label={t('labels.sku')}
              labelClasses='text-muted'
              component={TextInput}
              type='text'
              validate={required}
            />
          </Col>
          {/* <Col>
            <Field
              name='hardwareVersion'
              label={t('labels.hardwareVersion')}
              labelClasses='text-muted'
              component={TextInput}
              type='text'
              validate={required}
            />
          </Col>
          <Col>
            <Field
              name='serialNumber'
              label={t('labels.serialNumber')}
              labelClasses='text-muted'
              component={TextInput}
              type='text'
              validate={required}
            />
          </Col> */}
        </Row>
        <Row form>
          <Col>
            <Field
              name='deviceFields'
              label={t('labels.deviceFields')}
              labelClasses='text-muted'
              component={TextInput}
              type='text'
              validate={required}
              placeholder={t('labels.deviceFieldsPlaceholder')}
            />
          </Col>
        </Row>
        <Row form>
          <Col>
            <Field
              name='descriptionShort'
              label={t('labels.descriptionShort')}
              labelClasses='text-muted'
              component={TextInput}
              type='textarea'
            />
          </Col>
        </Row>
        <Row form>
          <Col>
            <Field
              name='descriptionLong'
              label={t('labels.descriptionLong')}
              labelClasses='text-muted'
              component={TextInput}
              type='textarea'
            />
          </Col>
        </Row>
        <FormGroup className='d-flex align-items-center justify-content-between m-0'>
          <Button type='submit' color='primary' disabled={isSubmitting || !isValid || !dirty}>
            {editState ? t('labels.update') : t('labels.create')}
          </Button>
        </FormGroup>
      </Form>
    </>
  )
})

export default DeviceTypeForm
