import { useMutation } from '@apollo/react-hooks'
import BreadcrumbCommon from 'app/components/common/BreadcrumbCommon'
import BasePage from 'app/components/layout/BasePage'
import DeviceForm, { DEVICE_INITIAL_VALUES } from 'app/pages/devices/forms/DeviceForm'
import { CREATE_DEVICE } from 'gql/schema/devices/mutations'
import withCurrentUser from 'hoc/withCurrentUser'
import { isCompanyAdmin, isSuperAdmin } from 'models/UsersType'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import CardBody from 'reactstrap/lib/CardBody'

const DeviceCreate = ({ user }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [createDevice] = useMutation(CREATE_DEVICE, {
    onCompleted ({ createDevice: { status } }) {
      if (status === 'ok') {
        if (isSuperAdmin(user.role)) {
          history.push('/devices/manage')
        } else if (isCompanyAdmin(user.role)) {
          history.push('/devices/list')
        }
      }
    }
  })

  const handleSubmit = useCallback(async (values) => {
    const currentStorageId = values.currentStorageId.value
    const deviceType = values.deviceType.value
    const deviceFields = values.deviceFields.map(value => JSON.stringify(value))

    await createDevice({
      variables: {
        ...values,
        currentStorageId,
        deviceType,
        deviceFields
      }
    })
  }, [createDevice])

  return (
    <>
      <BreadcrumbCommon/>
      <BasePage title={t('common.page.admin.create')}>
        <CardBody>
          <DeviceForm
            enableReinitialize
            onSubmit={handleSubmit}
            initialValues={DEVICE_INITIAL_VALUES}
          />
        </CardBody>
      </BasePage>
    </>
  )
}

export default withCurrentUser(DeviceCreate)
