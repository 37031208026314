import gql from 'graphql-tag'

export const COMPANY = gql`
    query company($id:String!) {
        company(_id: $id) {
            _id
            name
            address {
                country
                region
                province
                zip
                address
            }
            phone
            email
            contactName
            companyAdmins {
                _id
                name
                surname
                email
                role
            }
            defaultCompany
            companyLogo
        }
    }
`

export const COMPANIES_LIST = gql`
    query companiesList {
        companiesList {
            _id
            name
            address {
                country
                region
                province
                zip
                address
            }
            email
            contactName
            companyAdmins {
                _id
                name
                surname
                email
                role
            }
            defaultCompany
            companyLogo
        }
    }
`

export const ORDER_COMPANIES = gql`
    query orderCompanies{
        orderCompanies{
            _id
            name
            address {
                country
                region
                province
                zip
                address
            }
            email
            phone
            contactName
        }
    }
`
