import gql from 'graphql-tag'

export const CREATE_CUSTOMER_COMPANY = gql`
    mutation createCustomerCompany(
        $name: String!,
        $address: AddressInputType,
        $email: String!,
        $phone: String!,
        $contactName: String!,
        $companyLogo: Upload
    ){
        createCustomerCompany(
            name: $name, 
            address: $address, 
            email: $email, 
            phone: $phone, 
            contactName: $contactName,
            companyLogo: $companyLogo
        ){
            status
        }
    }
`
export const UPDATE_CUSTOMER_COMPANY = gql`
    mutation updateCustomerCompany(
        $id:ID!, 
        $name: String!, 
        $address: AddressInputType, 
        $email: String!, 
        $phone: String!, 
        $contactName: String!,
        $companyLogo: Upload
    ){
        updateCustomerCompany(
            id: $id,
            name: $name,
            address: $address,
            email: $email,
            phone: $phone,
            contactName: $contactName,
            companyLogo: $companyLogo
        ){
            status
        }
    }
`

export const DELETE_CUSTOMER_COMPANY = gql`
    mutation deleteCustomerCompany($id:ID!) {
        deleteCustomerCompany(id: $id){
            status
        }
    }
`
