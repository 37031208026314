import Select from 'app/components/forms/Select'
import React, { useCallback } from 'react'
import { FormFeedback, FormGroup, Label } from 'reactstrap'

const orderOptions = values => {
  return values ? values.filter(v => v.isFixed)
    .concat(values.filter(v => !v.isFixed)) : []
}

const SelectField = ({
  options,
  field,
  form,
  label,
  labelClasses,
  isMulti,
  id,
  changeHandler,
  isClearable,
  ...restProps
}) => {
  const { name, value } = field
  const { errors, touched } = form
  const invalid = errors[name] && touched[name]

  const onChange = useCallback((newOptions, { action, removedValue }) => {
    switch (action) {
      case 'remove-value':
      case 'pop-value':
        if (removedValue.isFixed) {
          return
        }
        break
      case 'clear':
        newOptions = isMulti ? value.filter(v => v.isFixed) : newOptions
        break
      default:
        break
    }

    if (isMulti) newOptions = orderOptions(newOptions)
    form.setFieldValue(name, isMulti ? (newOptions || []) : newOptions)

    changeHandler && changeHandler(newOptions)
  }, [isMulti, form, name, value, changeHandler])

  return (
    <FormGroup>
      {label && <Label className={labelClasses} for={id}>{label}</Label>}
      <Select
        id={id}
        options={options}
        name={name}
        invalid={invalid}
        isMulti={isMulti}
        value={value}
        onChange={onChange}
        isClearable={isClearable}
        {...restProps}
      />
      {invalid && <FormFeedback>{errors[name]}</FormFeedback>}
    </FormGroup>
  )
}

export default SelectField
