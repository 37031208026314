import React from 'react'
import { ApolloProvider } from 'react-apollo'
import { BrowserRouter } from 'react-router-dom'

import ApplicationRouter from './ApplicationRouter'

// eslint-disable-next-line react/display-name
export default ({ client }) => {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <ApplicationRouter />
      </BrowserRouter>
    </ApolloProvider>
  )
}
