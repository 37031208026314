import BreadcrumbCommon from 'app/components/common/BreadcrumbCommon'
import OrderCreateForm from 'app/pages/orders/OrderCreateForm'
import OrderEditForm from 'app/pages/orders/OrderEditForm'
import OrdersList from 'app/pages/orders/OrdersList'
import withCurrentUser from 'hoc/withCurrentUser'
import { isSuperAdmin } from 'models/UsersType'
import React, { useState } from 'react'
import { Container } from 'reactstrap'

const ORDER_STATE = {
  CREATE: 0,
  EDIT: 1
}

const Dashboard = withCurrentUser(({ props, user }) => {
  const [orderState, setOrderState] = useState(ORDER_STATE.CREATE)
  const [order, setOrder] = useState(null)
  return (
    <>
      <BreadcrumbCommon/>
      <Container>
        {!isSuperAdmin(user.role) && orderState === ORDER_STATE.CREATE && <OrderCreateForm/>}
        {orderState === ORDER_STATE.EDIT && <OrderEditForm order={order}/>}
        <OrdersList
          editClick={value => {
            setOrderState(ORDER_STATE.EDIT)
            setOrder(value)
          }}
          createClick={_ => {
            if (orderState !== ORDER_STATE.CREATE) {
              setOrderState(ORDER_STATE.CREATE)
              setOrder(null)
            }
          }}
        />
      </Container>
    </>
  )
})

export default Dashboard
