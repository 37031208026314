import { useMutation, useQuery } from '@apollo/react-hooks'
import { faEdit, faEye, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HandlerComponent from 'app/components/common/HandlerComponent'
import Select from 'app/components/forms/Select'
import cx from 'classnames'
import { ORDER_DELETE, ORDER_STATUS_UPDATE } from 'gql/schema/orders/mutations'
import { ORDERS_BY_COMPANY_LIST } from 'gql/schema/orders/queries'
import withCurrentUser from 'hoc/withCurrentUser'
import { COMPLETED, ORDER_STATUS_LIST } from 'models/orderStatus'
import { isClientCompanyAdmin, isCompanyAdmin, isSuperAdmin } from 'models/UsersType'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from 'reactstrap'
import Card from 'reactstrap/lib/Card'
import CardBody from 'reactstrap/lib/CardBody'

const StatusSelect = withCurrentUser(({ order, statusUpdate, user }) => {
  const [status, setStatus] = useState({
    value: order.status,
    label: order.status.toUpperCase()
  })
  return (
    <div style={{ width: '150px' }}>
      <Select
        options={ORDER_STATUS_LIST.map(v => {
          return {
            value: v,
            label: v.toUpperCase()
          }
        })}
        isMulti={false}
        value={status}
        onChange={(status) => {
          statusUpdate && statusUpdate(order, status.value)
          setStatus(status)
        }}
        isClearable={false}
        disabled={order.companyId !== user.companyId || order.status === COMPLETED}
      />
    </div>
  )
})

const OrdersListTable = withCurrentUser(({ ordersList, t, deleteCallBack, editCallBack, statusUpdate, user }) => {
  if (!ordersList) return null

  return (
    <>
      <Table borderless className='table-hover'>
        <thead>
          <tr>
            <th>{t('labels.status')}</th>
            <th>{t('labels.trackingNumber')}</th>
            <th>{t('labels.customer')}</th>
            <th>{t('labels.storage.name')}</th>
            <th>{t('labels.delivery_information')}</th>
            <th className='text-center'>Action</th>
          </tr>
        </thead>
        <tbody>
          {ordersList.sort((a, b) => {
            if (a.status === COMPLETED && b.status !== COMPLETED) return 1
            if (a.status !== COMPLETED && b.status === COMPLETED) return -1
            return 0
          })
            .map(value => {
              const actionsAllowed = value.status !== COMPLETED && value.companyId === user.companyId
              const isOrderComplete = value.status === COMPLETED
              return (
                <tr key={value._id} className='font-sm'>
                  <td>
                    <StatusSelect order={value} statusUpdate={statusUpdate}/>
                  </td>
                  <td>{value.trackingNumber || '-'}</td>
                  <td>{value.customer.name || '-'}</td>
                  <td>{value.deliveryStorage.name || '-'}</td>
                  <td>{value.deliveryInformation || '-'}</td>
                  <td>
                    <div className='d-flex justify-content-center'>
                      {isSuperAdmin(user.role) && <button
                        disabled={false}
                        className={cx('btn', { 'btn-ghost-primary': true }, { 'btn-ghost-secondary': false })}
                        type='button'
                        onClick={() => editCallBack && editCallBack(value)}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>}
                      {isCompanyAdmin(user.role) && <button
                        disabled={!actionsAllowed}
                        className={cx('btn', { 'btn-ghost-primary': actionsAllowed }, { 'btn-ghost-secondary': !actionsAllowed })}
                        type='button'
                        onClick={() => editCallBack && editCallBack(value)}
                      >
                        <FontAwesomeIcon icon={faEdit}/>
                      </button>}
                      {isClientCompanyAdmin(user.role) && <button
                        disabled={isOrderComplete}
                        className={cx('btn', { 'btn-ghost-primary': !isOrderComplete }, { 'btn-ghost-secondary': isOrderComplete })}
                        type='button'
                        onClick={() => editCallBack && editCallBack(value)}
                      >
                        <FontAwesomeIcon icon={faEye}/>
                      </button>}
                      <button
                        disabled={!actionsAllowed}
                        className={cx('btn', { 'btn-ghost-danger': actionsAllowed }, { 'btn-ghost-secondary': !actionsAllowed })}
                        type='button'
                        onClick={() => deleteCallBack && deleteCallBack(value)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt}/>
                      </button>
                    </div>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </Table>
    </>
  )
})

const OrdersList = ({ user, editClick, createClick }) => {
  const { t } = useTranslation()

  const { data, loading, error } = useQuery(ORDERS_BY_COMPANY_LIST,
    {
      variables: {
        id: user.companyId
      }
    }
  )

  /* DELETE ORDER */
  const [orderDelete] = useMutation(ORDER_DELETE, {
    onCompleted: ({ orderDelete: { status } }) => {
      status === 'ok' && window.location.reload()
    }
  })

  const deleteOrderCallback = useCallback(async ({ _id }) => {
    if (orderDelete) {
      await orderDelete(
        {
          variables: {
            id: _id
          }
        }
      )
    }
  }, [orderDelete])

  /* ORDER STATUS UPDATE */
  const [orderStatusUpdate] = useMutation(ORDER_STATUS_UPDATE, {
    onCompleted: ({ orderStatusUpdate: { status } }) => {
      status === 'ok' && window.location.reload()
    }
  })

  const updateStatusCallback = useCallback(async ({ _id }, value) => {
    if (orderStatusUpdate) {
      await orderStatusUpdate(
        {
          variables: {
            id: _id,
            status: value
          }
        }
      )
    }
  }, [orderStatusUpdate])

  return (
    <>
      <Card>
        <div className='card-header'>Orders List
          <div className='card-header-actions'>
            <button
              className='bnt btn-ghost-primary'
              disabled={!createClick} type='button' onClick={createClick}
            >
              <FontAwesomeIcon className='nav-icon' icon={faPlus}/>
            </button>
          </div>
        </div>
        <CardBody>
          <HandlerComponent loading={loading} error={error}/>
          {data && <OrdersListTable
            ordersList={data.ordersByCompany} t={t}
            editCallBack={editClick}
            deleteCallBack={deleteOrderCallback}
            statusUpdate={updateStatusCallback}
          />}
        </CardBody>
      </Card>
    </>
  )
}

export default withCurrentUser(OrdersList)
