/* eslint-disable camelcase */
import { useQuery } from '@apollo/react-hooks'
import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BreadcrumbCommon from 'app/components/common/BreadcrumbCommon'
import HandlerComponent from 'app/components/common/HandlerComponent'
import BasePage from 'app/components/layout/BasePage'
import cx from 'classnames'
import { StorageListQuery } from 'gql/schema'
import { CLIENTS_STORAGES, STORAGES_LIST } from 'gql/schema/storage/queries'
import withCurrentUser from 'hoc/withCurrentUser'
import useDeleteStorage from 'hooks/gql/useDeleteStorage'
import { isAdmin, isClientCompanyAdmin, isCompanyAdmin } from 'models/UsersType'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { BreadcrumbItem, Table } from 'reactstrap'

const StorageTable = ({ data: { storageList, customerStorageList }, t, deleteCallBack }) => {
  if (!storageList && !customerStorageList) return null
  const storages = storageList || customerStorageList
  return (
    <>
      <Table borderless responsive className='table-hover'>
        <thead>
          <tr>
            <th>{t('labels.storage.name')}</th>
            <th>{t('labels.storage.address')}</th>
            <th>{t('labels.storage.type')}</th>
            <th className='text-center'>Action</th>
          </tr>
        </thead>
        <tbody>
          {storages.map(value => {
            return (
              <tr key={value._id} className='font-sm'>
                <td>{value.name}</td>
                <td>{value.address || '-'}</td>
                <td>{value.type}</td>
                <td>
                  <div className='d-flex justify-content-center'>
                    {isAdmin(value.role) && <Link to={`/storage/edit/${value._id}`} className='btn btn-ghost-info'>
                      <FontAwesomeIcon icon={faEdit}/>
                    </Link>}
                    <button
                      disabled={value.default}
                      className={cx('btn', { 'btn-ghost-danger': !value.default }, { 'btn-ghost-secondary': value.default })}
                      type='button'
                      onClick={() => deleteCallBack && deleteCallBack(value._id)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt}/>
                    </button>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

const StorageList = ({ user }) => {
  const { t } = useTranslation()
  /* FETCH STORAGE LIST */
  const { data, loading, error } = useQuery(isClientCompanyAdmin(user.role) ? CLIENTS_STORAGES : STORAGES_LIST)

  const [deleteStorage] = useDeleteStorage({
    refetchQueries: StorageListQuery
  })

  const handleDelete = useCallback(async (id) => {
    if (deleteStorage) {
      await deleteStorage({ variables: { id } })
    }
  }, [deleteStorage])

  return (
    <>
      <BreadcrumbCommon>
        <BreadcrumbItem active>{t('common.page.admin.storage_list')}</BreadcrumbItem>
        <li className='breadcrumb-menu'>
          {isCompanyAdmin(user.role) && <div className='btn-group' role='group'>
            <Link to='/storage/create' className='btn btn-transparent'>
              <FontAwesomeIcon className='nav-icon' icon={faPlus}/>
              <span className='ml-1'>{t('labels.create')}</span>
            </Link>
          </div>}
        </li>
      </BreadcrumbCommon>
      <BasePage title={t('common.page.admin.storage_list')}>
        <HandlerComponent loading={loading} error={error}/>
        {data && <StorageTable data={data} t={t} deleteCallBack={handleDelete}/>}
      </BasePage>
    </>
  )
}

export default withCurrentUser(StorageList)
