import gql from 'graphql-tag'

export const CREATE_DEVICE = gql`
    mutation createDevice($currentStorageId:ID!, $buildingDate:String, $builderId:String, $deviceType: ID!, $deviceFields: [String], $serialNumber:String, $hardwareVersion:String) {
        createDevice(currentStorageId: $currentStorageId, buildingDate:$buildingDate, builderId:$builderId, deviceType: $deviceType, deviceFields: $deviceFields, serialNumber: $serialNumber, hardwareVersion: $hardwareVersion){
            status
            data
        }
    }
`
export const UPDATE_DEVICE = gql`
    mutation updateDevice($id:ID!, $currentStorageId:ID!, $buildingDate:String, $builderId:String, $deviceType: ID!, $deviceFields: [String], $serialNumber:String, $hardwareVersion:String ) {
        updateDevice(id:$id, currentStorageId: $currentStorageId, buildingDate:$buildingDate, builderId:$builderId, deviceType: $deviceType, deviceFields: $deviceFields, serialNumber: $serialNumber, hardwareVersion: $hardwareVersion) {
            status
        }
    }
`

export const DELETE_DEVICE = gql`
    mutation deleteDevice($id:ID!) {
        deleteDevice(id:$id) {
            status
        }
    }
`

export const MOVE_DEVICE = gql`
    mutation moveDevice($id:ID!, $storeID:ID!) {
        moveDevice(id:$id, storeID: $storeID) {
            status
        }
    }
`

export const UPLOAD_FIRMWARE = gql`
    mutation ($deviceTypeId:ID!, $version:String!, $descriptionShort:String, $descriptionLong:String, $isDefault:Boolean, $file:Upload!) {
        uploadFirmware(deviceTypeId: $deviceTypeId, version: $version, descriptionShort: $descriptionShort, descriptionLong: $descriptionLong, isDefault: $isDefault, file: $file) {
            _id
            version
            filePath
            isDefault
        }
    }
`

export const DELETE_DEVICE_TYPE = gql`
    mutation ($id:ID!){
        deleteDeviceType(id: $id){
            status
        }
    }
`

export const CREATE_DEVICE_TYPE = gql`
    mutation ($sku:String!, $deviceFields: String, $descriptionShort:String, $descriptionLong:String){
        createDeviceType(
            sku: $sku,
            deviceFields: $deviceFields,
            descriptionShort: $descriptionShort,
            descriptionLong: $descriptionLong) {
            status
        }
    }
`

export const UPDATE_DEVICE_TYPE = gql`
    mutation ($id: ID!, $sku:String!, $deviceFields: String, $descriptionShort:String, $descriptionLong:String){
        updateDeviceType(
            id: $id,
            sku: $sku,
            deviceFields: $deviceFields,
            descriptionShort: $descriptionShort,
            descriptionLong: $descriptionLong) {
            status
        }
    }
`

export const DELETE_FIRMWARE = gql`
    mutation ($id: ID!) {
        deleteFirmware(id: $id){
            status
        }
    }
`
