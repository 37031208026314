import gql from 'graphql-tag'

export const CUSTOMER_COMPANY = gql`
    query customerCompany($id:ID!){
        customerCompany(id: $id){
            _id
            name
            address {
                country
                region
                province
                zip
                address
            }
            email
            phone
            contactName
            companyLogo
        }
    }
`
export const CUSTOMER_COMPANIES = gql`
    query customerCompaniesList($id:ID){
        customerCompaniesList(id: $id){
            _id
            name
            address {
                country
                region
                province
                zip
                address
            }
            email
            phone
            contactName
            companyLogo
        }
    }
`
export const CUSTOMER_COMPANIES_FOR_ORDER = gql`
    query customerCompaniesListForOrder($id:ID){
        customerCompaniesListForOrder(id: $id){
            _id
            name
            address {
                country
                region
                province
                zip
                address
            }
            email
            phone
            contactName
            companyLogo
        }
    }
`
