import BreadcrumbCommon from 'app/components/common/BreadcrumbCommon'
import HandlerComponent from 'app/components/common/HandlerComponent'
import BasePage from 'app/components/layout/BasePage'
import EditStorageForm from 'app/pages/storage/EditStorageForm'
import useFetchStorage from 'hooks/gql/useFetchStorage'
import useUpdateStorage from 'hooks/gql/useUpdateStorage'
import React, { useCallback } from 'react'
import { geocodeByAddress } from 'react-google-places-autocomplete/src/utils/googleGeocodesHelper'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import CardBody from 'reactstrap/lib/CardBody'

const StorageEdit = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { id } = useParams()

  const { data, loading, error } = useFetchStorage({ variables: { id } })

  const [updateStorage] = useUpdateStorage({
    onComplete: ({ updateStorage: { status } }) => {
      status === 'ok' && history.push('/storage/list')
    }
  })

  const handleSubmit = useCallback(async (values) => {
    if (updateStorage) {
      const [geocode] = await geocodeByAddress(values.address)
      const address = JSON.stringify(geocode)

      await updateStorage({
        variables: {
          id,
          ...values,
          address
        }
      })
    }
  }, [updateStorage, id])

  return (
    <>
      <BreadcrumbCommon />
      <BasePage title={t('common.page.admin.edit')}>
        <HandlerComponent error={error} loading={loading} />
        {data && <CardBody><EditStorageForm onSubmit={handleSubmit} initialValues={data.storage} /></CardBody>}
      </BasePage>
    </>
  )
}

export default StorageEdit
