import UsersType from 'models/UsersType'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'

const OnlyPublicRoute = ({ user, isAuthenticated, lastError, component: Component, layout: Layout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        // eslint-disable-next-line no-nested-ternary
        !isAuthenticated ? (
          Layout === undefined ? (
            <Component {...props} lastError={lastError} />
          ) : (
            <Layout><Component {...props} lastError={lastError} /></Layout>
          )
        ) : (user.role === UsersType.ADMIN || user.role === UsersType.COMPANY_ADMIN) ? (
          <Redirect to={{ pathname: '/admin/' }} />
        ) : (
          <Redirect to={{ pathname: '/client/dashboard' }} />
        )
      )}
    />
  )
}

export default OnlyPublicRoute
