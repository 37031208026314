export const NEW = 'new'
export const DELIVERING = 'delivering'
export const COMPLETED = 'completed'

export const ORDER_STATUS = {
  NEW: NEW,
  DELIVERING: DELIVERING,
  COMPLETED: COMPLETED
}

export const ORDER_STATUS_LIST = [
  NEW,
  DELIVERING,
  COMPLETED
]
