import { useMutation, useQuery } from '@apollo/react-hooks'
import BreadcrumbCommon from 'app/components/common/BreadcrumbCommon'
import HandlerComponent from 'app/components/common/HandlerComponent'
import BasePage from 'app/components/layout/BasePage'
import CompanyForm from 'app/pages/company/components/CompanyForm'
import { API_HOST } from 'config'
import { UPDATE_CUSTOMER_COMPANY } from 'gql/schema/customerCompany/mutations'
import { CUSTOMER_COMPANY } from 'gql/schema/customerCompany/queries'
import withCurrentUser from 'hoc/withCurrentUser'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { BreadcrumbItem } from 'reactstrap'
import CardBody from 'reactstrap/lib/CardBody'

/* CREATE COMPANY PAGE */
const EditSubCompany = ({ user }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { id } = useParams()
  const [company, setCompany] = useState(null)
  /* FETCH COMPANY DATA */
  const { loading, error, data } = useQuery(CUSTOMER_COMPANY, {
    variables: {
      id
    }
  })

  const [updateCustomerCompany] = useMutation(UPDATE_CUSTOMER_COMPANY, {
    onCompleted: ({ updateCustomerCompany: { status } }) => {
      if (status === 'ok') {
        history.push('/sub-companies')
      }
    }
  })

  const handleSubmit = useCallback(async (values) => {
    if (updateCustomerCompany) {
      if (typeof values.companyLogo === 'string') {
        values.companyLogo = null
      }
      await updateCustomerCompany({
        variables: {
          id,
          ...values
        }
      })
    }
  }, [updateCustomerCompany, id])

  useMemo(() => {
    if (data) {
      const companyData = { ...data.customerCompany }
      companyData.companyLogo = `${API_HOST}/${companyData.companyLogo}?rnd=${Math.random()}` // disabled logo cache
      setCompany(companyData)
    }
  }, [data, user, setCompany])

  return (
    <>
      <BreadcrumbCommon>
        <BreadcrumbItem active>{t('common.page.admin.sub_company')}</BreadcrumbItem>
        <BreadcrumbItem active>{t('common.page.admin.edit')}</BreadcrumbItem>
      </BreadcrumbCommon>
      <BasePage title={t('common.page.admin.edit_company')}>
        <CardBody>
          <HandlerComponent loading={loading} error={error}/>
          {company && <CompanyForm onSubmit={handleSubmit} initialValues={company} enableReinitialize editState/>}
        </CardBody>
      </BasePage>
    </>
  )
}

EditSubCompany.propTypes = {
  user: PropTypes.object
}

export default withCurrentUser(EditSubCompany)
