import { useMutation } from '@apollo/react-hooks'
import { DeleteUserMutation } from 'gql/schema'

const useDeleteUser = ({ onComplete, refetchQueries }) => {
  return useMutation(DeleteUserMutation, {
    onCompleted (data) {
      onComplete && onComplete(data)
    },
    refetchQueries: refetchQueries ? [{ query: refetchQueries }] : null
  })
}

export default useDeleteUser
