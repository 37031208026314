import React from 'react'
import { Redirect, Route } from 'react-router-dom'

// TODO: Refactor: do not nest ternary operator
const PrivateRoute = ({
  user, isAuthenticated, component: Component, layout: Layout, container, allowedRoles, privacyObject, privacyAction, ...rest
}) => {
  let allowed = isAuthenticated && user && (Array.isArray(allowedRoles)
    ? allowedRoles.includes(user.role)
    : allowedRoles === 'all')

  if (user && privacyObject && privacyAction && !allowed) {
    allowed = user.privacy && user.privacy[privacyObject].includes(privacyAction)
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          // eslint-disable-next-line no-nested-ternary
          !isAuthenticated ? (
            <Redirect to={{
              pathname: '/',
              state: { from: props.location }
            }}
            />
          ) : (
            // eslint-disable-next-line no-nested-ternary
            allowed ? (
              Layout === undefined ? (
                <Component {...props} />
              ) : (
                <Layout container={container}><Component {...props} /></Layout>
              )
            ) : (
              <div>Forbidden</div>
            )
          )
        )
      }}
    />
  )
}

PrivateRoute.defaultProps = {
  allowedRoles: 'all'
}

export default PrivateRoute
