import { Formik } from 'formik'

import handleSubmitError from 'libs/forms/handleSubmitError'
import * as PropTypes from 'prop-types'
import React from 'react'

const propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.any,
  enableReinitialize: PropTypes.bool,
  children: PropTypes.node
}

const defaultProps = {
  enableReinitialize: false,
  initialValues: {},
  onSubmit: () => {
  }
}

const FormikForm = (Form) => {
  // eslint-disable-next-line react/display-name
  return (props) => {
    const { children, onSubmit, onReset, ...restProps } = props

    const onSubmitWrapper = (values, formikActions) => {
      if (onSubmit) {
        // formikActions.setFormikState({ error: null })

        const submitResult = onSubmit(values, formikActions)
        if (submitResult instanceof Promise) {
          return submitResult
            .catch((error) => {
              handleSubmitError(error, formikActions)
            })
        }
      }

      return undefined
    }

    const onResetWrapper = (values, formikActions) => {
      if (onReset) {
        // formikActions.setFormikState({ error: null })

        const resetResult = onReset(values, formikActions)
        if (resetResult instanceof Promise) {
          return resetResult
            .catch((error) => {
              handleSubmitError(error, formikActions)
            })
        }
      }
      return undefined
    }

    return (
      <Formik
        {...restProps}
        validateOnMount
        onSubmit={onSubmitWrapper}
        onReset={onResetWrapper}
      >
        {(renderProps) => {
          return (
            <Form {...renderProps} {...restProps}>
              {children}
            </Form>
          )
        }}
      </Formik>
    )
  }
}

FormikForm.propTypes = propTypes
FormikForm.defaultProps = defaultProps

export default FormikForm
