import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import withCurrentUser from 'hoc/withCurrentUser'
import React from 'react'
import { NavLink as NavLinkRouter } from 'react-router-dom'
import { Button, Nav, NavItem, NavLink } from 'reactstrap'
import SIDEBAR_ITEM_TYPE from './SidebarItemType'

const NavLinkTemplate = ({ data }) => {
  return (
    <NavItem>
      <NavLink to={data.to} tag={NavLinkRouter}>
        {data.icon ? <i className={`nav-icon ${data.icon || ''}`} /> : <FontAwesomeIcon className='nav-icon' icon={data.faIcon} />}
        {data.text}
      </NavLink>
    </NavItem>
  )
}

const NavTitleTemplate = ({ value }) => {
  return <li className='nav-title'>{value.text || value}</li>
}

const NavCategoryTemplate = ({ data, user }) => {
  return data.items.map((linkData) => {
    return linkData.resolver(user) ? <NavLinkTemplate data={linkData} key={`sub-link-${Math.random()}`} /> : null
  })
}

const SidebarMenu = ({ data, user }) => {
  return (
    <>
      <div className='sidebar'>
        <Nav className='sidebar-nav'>
          <ul className='nav'>
            {data.map((value, indx) => {
              if (!value.resolver(user)) return null

              if (value.type === SIDEBAR_ITEM_TYPE.LINK) {
                return <NavLinkTemplate
                  data={value}
                  key={`link-${Math.random()}`}
                />
              }
              if (value.type === SIDEBAR_ITEM_TYPE.TITLE) {
                return (
                  <NavTitleTemplate value={value} key={`title-${Math.random()}`} />
                )
              }
              if (value.type === SIDEBAR_ITEM_TYPE.CATEGORY) {
                return (
                  <NavCategoryTemplate key={`cat-link-${Math.random()}`} data={value} user={user} />
                )
              }
              return null
            })}
          </ul>
        </Nav>
        <Button className='sidebar-minimizer brand-minimizer' type='button' />
      </div>
    </>
  )
}

export default withCurrentUser(SidebarMenu)
