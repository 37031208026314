import { useMutation } from '@apollo/react-hooks'
import BreadcrumbCommon from 'app/components/common/BreadcrumbCommon'
import BasePage from 'app/components/layout/BasePage'
import UserForm from 'app/pages/users/components/UserForm'
import { CREATE_USER } from 'gql/schema/user/mutations'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { BreadcrumbItem } from 'reactstrap'
import CardBody from 'reactstrap/lib/CardBody'

const CreateUser = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const [createUser] = useMutation(CREATE_USER, {
    onCompleted: ({ createUser: { status } }) => {
      status === 'ok' && history.push('/admin/users')
    }
  })

  const handleSubmit = useCallback(async (values) => {
    if (createUser) {
      const role = values.role.value
      const customerCompanyId = values.customerCompanyId ? values.customerCompanyId.value : null

      const users = values.users.map(v => v.value)
      const companies = values.companies.map(v => v.value)
      const stores = values.stores.map(v => v.value)
      const devices = values.devices.map(v => v.value)

      await createUser({
        variables: {
          ...values,
          role,
          customerCompanyId,
          users,
          companies,
          stores,
          devices,
          localization: values.localization.value
        }
      })
    }
  }, [createUser])

  return (
    <>
      <BreadcrumbCommon>
        <BreadcrumbItem active>
          <Link to='/admin/users'>{t('common.page.admin.users')}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{t('common.page.admin.create')}</BreadcrumbItem>
      </BreadcrumbCommon>
      <BasePage title={t('common.page.admin.add_new_user')}>
        <CardBody>
          <UserForm
            onSubmit={handleSubmit}
            initialValues={{
              name: '',
              surname: '',
              email: '',
              password: '',
              users: [],
              companies: [],
              stores: [],
              devices: [],
              localization: ''
            }}
          />
        </CardBody>
      </BasePage>
    </>
  )
}

export default CreateUser
