import { useQuery } from '@apollo/react-hooks'
import { CompaniesListQuery } from 'gql/schema'

const useFetchCompanies = () => {
  return useQuery(CompaniesListQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })
}
export default useFetchCompanies
