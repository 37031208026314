import { useMutation, useQuery } from '@apollo/react-hooks'
import BreadcrumbCommon from 'app/components/common/BreadcrumbCommon'
import HandlerComponent from 'app/components/common/HandlerComponent'
import BasePage from 'app/components/layout/BasePage'
import ChangePasswordForm from 'app/pages/users/components/ChangePasswordForm'
import EditUserForm from 'app/pages/users/components/EditUserForm'
import { UPDATE_USER } from 'gql/schema/user/mutations'
import { USER_QUERY } from 'gql/schema/user/queries'
import withCurrentUser from 'hoc/withCurrentUser'
import withLocalization from 'hoc/withLocalization'
import useChangePassword from 'hooks/gql/useChangePassword'
import UsersType from 'models/UsersType'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { BreadcrumbItem, Button, Col, Row } from 'reactstrap'
import CardBody from 'reactstrap/lib/CardBody'
import CardFooter from 'reactstrap/lib/CardFooter'

const patchUserModel = (data, t) => {
  if (!data) return
  if (data.user.role === UsersType.CLIENT_COMPANY_ADMIN) {
    data.user.customerCompanyId = {
      value: data.user.company._id,
      label: data.user.company.name
    }
  }

  data.user.role = {
    value: data.user.role,
    label: String(t(`labels.${data.user.role}`))
      .toUpperCase()
  }

  data.user.localization = {
    value: data.user.localization,
    label: String(t(`labels.locale.${data.user.localization}`))
  }
}

const EditUser = ({ user }) => {
  const { t } = useTranslation()
  const params = useParams()
  const history = useHistory()
  const { id } = params

  const { loading, error, data } = useQuery(USER_QUERY, {
    variables: { id: id },
    fetchPolicy: 'no-cache'
  })
  // const isAdminProfile = data && (data.user.role === UsersType.ADMIN || data.user.role === UsersType.COMPANY_ADMIN)

  /* EDIT USER INFO */
  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted ({ updateUser: { status } }) {
      if (status === 'ok') {
        if (user._id === id) {
          window.location.reload()
        } else {
          history.goBack()
        }
      }
    },
    onError (error) {
      console.warn(error.message)
    }
  })

  const handleUserInfoSubmit = useCallback(async (values) => {
    if (updateUser) {
      if (user._id === id) {
        localStorage.setItem('localization', values.localization.value)
      }
      const companyLogo = typeof values.companyLogo !== 'object' ? null : values.companyLogo

      await updateUser({
        variables:
          {
            ...values,
            role: values.role.value,
            customerCompanyId: values.customerCompanyId ? values.customerCompanyId.value : null,
            localization: values.localization.value,
            companyLogo
          }
      })
    }
  }, [updateUser, id, user._id])

  /* CHANGE USER PASSWORD */
  const [chaneUserPassword] = useChangePassword({
    onComplete: ({ changeUserPassword: { status } }) => {
      status === 'ok' && history.push('/users')
    }
  })

  const handleChangePassword = useCallback(async (values) => {
    if (chaneUserPassword) {
      await chaneUserPassword({
        variables: {
          ...values,
          id
        }
      })
    }
  }, [chaneUserPassword, id])

  /* CHANGE USER PERMISSIONS */
  /* const [changeUserPermissions] = useMutation(CHANGE_PERMISSIONS, {
    onCompleted ({ changeUserPermissions: { status } }) {
      status === 'ok' && history.push('/admin/users')
    }
  }) */

  /* const handleChangePermissions = useCallback(async (data) => {
    if (changeUserPermissions) {
      const mapValues = (data) => data.map(item => item.value)
      const values = {}

      Object.keys(data)
        .forEach(key => {
          values[key] = mapValues(data[key])
        })

      await changeUserPermissions(
        {
          variables: {
            ...values,
            id
          }
        }
      )
    }
  }, [changeUserPermissions, id]) */

  /* CONVERT PRIVACY DATA TO SELECT DATA FORMAT  */
  /* const privacy = useMemo(() => {
    if (data) {
      const privacyData = {}
      const { user: { privacy } } = data
      Object.keys(privacy)
        .forEach((key) => {
          privacyData[key] = privacy[key].map(v => ({
            value: v,
            label:
              String(v)
                .toUpperCase()
          }))
        })
      return privacyData
    }
    return {
      users: [],
      companies: [],
      stores: [],
      devices: []
    }
  }, [data]) */

  /* patch user model */
  patchUserModel(data, t)

  return (
    <>
      <BreadcrumbCommon>
        <BreadcrumbItem active>{t('common.page.admin.update_user')}</BreadcrumbItem>
      </BreadcrumbCommon>
      <BasePage title={t('common.page.admin.update_user')}>
        <CardBody>
          <HandlerComponent loading={loading} error={error}/>
          {data && (
            <>
              {/* BASE INFORMATION */}
              <Row className='mb-3'>
                <Col>
                  <EditUserForm
                    initialValues={{ ...data.user }}
                    onSubmit={handleUserInfoSubmit}
                  />
                </Col>
              </Row>
              {/* PRIVACY */}
              {/* {!isAdminProfile && (
                <Row>
                  <Col>
                    <ChangePermissionsForm
                      initialValues={{ ...privacy }}
                      onSubmit={handleChangePermissions}
                    />
                  </Col>
                </Row>
              )} */}
              {/* CHANGE PASSWORD */}
              {(user._id === id) && (
                <Row>
                  <Col>
                    <ChangePasswordForm
                      initialValues={{
                        userName: `${user.name} ${user.surname}`,
                        oldPassword: '',
                        password: '',
                        newPassword: ''
                      }}
                      onSubmit={handleChangePassword}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        </CardBody>
        <CardFooter className='float-right'>
          <Button color='primary' onClick={() => history.goBack()}>
            {t('labels.close')}
          </Button>
        </CardFooter>
      </BasePage>
    </>
  )
}

EditUser.propTypes = {
  user: PropTypes.object
}

export default withLocalization(withCurrentUser(EditUser))
