import { formikForm, TextInput } from 'app/components/forms'
import { Field } from 'formik'
import { required } from 'libs/forms/validators'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Col, Form, FormGroup, Label, Row } from 'reactstrap'

const EditStorageForm = formikForm(({
  isSubmitting, isValid, handleSubmit, error, values, ...rest
}) => {
  const { t } = useTranslation()

  return (
    <Form className='d-flex flex-column justify-content-between' onSubmit={handleSubmit}>
      {error && <Alert color='danger'>{error.message}</Alert>}
      <Row form>
        <Col md={6}>
          <Field
            name='name'
            type='text'
            label={t('labels.storage.name')}
            labelClasses='text-muted'
            component={TextInput}
            validate={required}
          />
          <Field
            name='address'
            type='text'
            label={t('labels.storage.address')}
            labelClasses='text-muted'
            component={TextInput}
            disabled={values.type === 'virtual'}
          />
          <FormGroup>
            <Label className='text-muted'>{t('labels.storage.type')}</Label>
            <Field
              name='type'
              component='select'
              type='select'
              className='form-control'
              disabled={values.default}
            >
              <option value='real'>REAL</option>
              <option value='virtual'>VIRTUAL</option>
            </Field>
          </FormGroup>
        </Col>
      </Row>

      <FormGroup className='d-flex align-items-center justify-content-between m-0'>
        <Button type='submit' color='primary' disabled={isSubmitting || !isValid}>
          {t('labels.update')}
        </Button>
      </FormGroup>
    </Form>
  )
})

export default EditStorageForm
