export default function handleSubmitError (error, formikActions) {
  if (formikActions) {
    const { graphQLErrors } = error

    if (graphQLErrors && graphQLErrors.length > 0) {
      formikActions.setFormikState({ error: graphQLErrors[0] })

      const { state } = graphQLErrors[0]
      if (state) {
        if (Array.isArray(state)) {
          state.forEach(({ param, msg }) => {
            formikActions.setFieldError(param, msg)
          })
        } else {
          const { param, msg } = state
          formikActions.setFieldError(param, msg)
        }
      }
    } else {
      formikActions.setFormikState({ error: error })
    }
    formikActions.setSubmitting(false)
  }
}
