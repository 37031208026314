import { useQuery } from '@apollo/react-hooks'
import { formikForm, SelectField, TextInput } from 'app/components/forms'
import { Field } from 'formik'
import { CUSTOMER_COMPANIES } from 'gql/schema/customerCompany/queries'
import withCurrentUser from 'hoc/withCurrentUser'
import { composeValidators, email, required } from 'libs/forms/validators'
import { AVAILABLE_LOCALIZATIONS } from 'models/localizations'
import { getAllowedUserTypesFor } from 'models/Privacy'
import UsersType from 'models/UsersType'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Form, FormGroup } from 'reactstrap'

const UserForm = (
  {
    handleSubmit,
    isSubmitting,
    isValid,
    error,
    disabledFields,
    initialValues,
    setFieldValue,
    user,
    values,
    dirty,
    ...rest
  }
) => {
  const [customerCompanies, setCustomerCompanies] = useState([])
  const { t } = useTranslation()
  const allowedUserTypes = getAllowedUserTypesFor(user.role)
  const isCustomer = useMemo(() => {
    return values.role && values.role.value === UsersType.CLIENT_COMPANY_ADMIN
  }, [values.role])

  useQuery(CUSTOMER_COMPANIES, {
    variables: {
      id: user.companyId
    },
    onCompleted ({ customerCompaniesList }) {
      setCustomerCompanies(customerCompaniesList.map(company => {
        return {
          value: company._id,
          label: company.name
        }
      }))
    },
    onError (error) {
      console.warn(error.message)
    }
  })

  return (
    <>
      <Form className='d-flex flex-column justify-content-between' onSubmit={handleSubmit}>
        {error && <Alert color='danger'>{error.message}</Alert>}
        <Field
          name='name'
          autoComplete='new-name'
          type='text'
          label={t('labels.user_name')}
          labelClasses='text-muted'
          component={TextInput}
          validate={required}
        />
        {/* USER SECOND NAME */}
        <Field
          name='surname'
          autoComplete='new-surname'
          type='text'
          label={t('labels.user_second_name')}
          labelClasses='text-muted'
          component={TextInput}
          validate={required}
        />
        {/* USER ROLE */}
        <Field
          name='role'
          label={t('labels.account_type')}
          labelClasses='text-muted'
          options={allowedUserTypes.map(role => {
            return {
              label: String(t(`labels.${role}`))
                .toUpperCase(),
              value: role
            }
          })}
          component={SelectField}
          isMulti={false}
          validate={required}
        />
        {isCustomer && <Field
          name='customerCompanyId'
          label={t('labels.company.name')}
          labelClasses='text-muted'
          options={customerCompanies}
          component={SelectField}
          isMulti={false}
          validate={required}
          disabled={!customerCompanies.length}
        />}
        {/* USER EMAIL */}
        <Field
          name='email'
          autoComplete='new-email'
          type='email'
          label={t('labels.email')}
          labelClasses='text-muted'
          component={TextInput}
          validate={composeValidators(email, required)}
        />
        {/* TEMP PASSWORD */}
        <Field
          name='password'
          type='password'
          label={t('labels.password')}
          labelClasses='text-muted'
          component={TextInput}
          validate={required}
          autoComplete='new-password'
        />
        {/* USER ROLE */}
        <Field
          name='localization'
          label={t('labels.language')}
          labelClasses='text-muted'
          options={AVAILABLE_LOCALIZATIONS.map(value => {
            return {
              label: String(t(`labels.locale.${value}`)),
              value: value
            }
          })}
          component={SelectField}
          isMulti={false}
          validate={required}
        />

        {/* PERMISSIONS //TODO */}
        {/* <Card className='card-accent-danger'>
          <CardHeader>
            {'User permission'}
          </CardHeader>
          <CardBody>
            <Row className='mt-2'>
              <Col>
                <Field
                  name='users'
                  id='users'
                  type='text'
                  label='User'
                  labelClasses='text-muted'
                  component={SelectField}
                  isMulti
                  options={userPrivacyTypes}
                  disabled={isAdmin(values.role)}
                />
                <Field
                  name='companies'
                  id='companies'
                  type='text'
                  label='Company'
                  labelClasses='text-muted'
                  component={SelectField}
                  isMulti
                  options={userPrivacyTypes}
                  disabled={isAdmin(values.role)}
                />
                <Field
                  name='stores'
                  id='stores'
                  type='text'
                  label='Store'
                  labelClasses='text-muted'
                  component={SelectField}
                  isMulti
                  options={userPrivacyTypes}
                  disabled={isAdmin(values.role)}
                />
                <Field
                  name='devices'
                  id='devices'
                  type='text'
                  label='Device'
                  labelClasses='text-muted'
                  component={SelectField}
                  isMulti
                  options={userPrivacyTypes}
                  disabled={isAdmin(values.role)}
                />
              </Col>
            </Row>
          </CardBody>
        </Card> */}
        <FormGroup className='d-flex align-items-center justify-content-between m-0'>
          <Button type='submit' color='primary' disabled={isSubmitting || !isValid}>
            {t('labels.create')}
          </Button>
        </FormGroup>
      </Form>
    </>
  )
}

export default formikForm(withCurrentUser(UserForm))
