import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Table } from 'reactstrap'

const DeviceTypesTable = ({ list, deleteHandler }) => {
  const { t } = useTranslation()

  if (!list) return null

  return (
    <>
      <Table borderless responsive className='table-hover'>
        <thead>
          <tr>
            <th>{t('labels.sku')}</th>
            <th>{t('labels.descriptionShort')}</th>
            <th className='text-center'>{t('labels.firmware')}</th>
            <th className='text-center'>{t('labels.action')}</th>
          </tr>
        </thead>
        <tbody>
          {list.map(value => {
            return (
              <tr key={value._id} className='font-sm'>
                <td>{value.sku}</td>
                <td>{value.descriptionShort}</td>
                <td className='text-center'>
                  <Link to={`/device/types/${value._id}/firmware`} className='btn btn-ghost-info'>
                    <FontAwesomeIcon icon={faEye}/><span className='ml-1'>{value.firmware.length}</span>
                  </Link>
                </td>
                <td>
                  <div className='d-flex justify-content-center'>
                    {/* view button */}
                    <Link to={`/device/types/view/${value._id}`} className='btn btn-ghost-info'>
                      <FontAwesomeIcon icon={faEye}/>
                    </Link>
                    {/* edit button */}
                    <Link disabled to={`/device/types/edit/${value._id}`} className='btn btn-ghost-info'>
                      <FontAwesomeIcon icon={faEdit}/>
                    </Link>
                    {/* delete button */}
                    {deleteHandler && (
                      <button
                        className='btn btn-ghost-danger'
                        type='button'
                        onClick={() => deleteHandler(value._id)}>
                        <FontAwesomeIcon icon={faTrashAlt}/>
                      </button>
                    )
                    }
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

export default DeviceTypesTable
