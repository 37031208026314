import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { createHttpLink } from 'apollo-link-http'
import { createUploadLink } from 'apollo-upload-client'

import { API_HOST } from 'config'
import { loader } from 'graphql.macro'
import localStorage from './localStorage'

const authQuery = loader('gql/client/authQuery.graphql')

const uploadLink = createUploadLink({ uri: `${API_HOST}/graphql` })
const httpLink = createHttpLink({ uri: `${API_HOST}/graphql` })

const cache = new InMemoryCache({ addTypename: false })

const logoutMiddleware = onError(({ graphQLErrors }) => {
  if (graphQLErrors && graphQLErrors.length > 0) {
    const { state /* message */ } = graphQLErrors[0]

    if (state && state.reason === 'jwt_expired') {
      localStorage.clear()

      cache.writeQuery({
        query: authQuery,
        data: {
          user: null,
          isAuthenticated: false
        }
      })
    }
  }
})

const authLinkMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => {
    const token = localStorage.getItem('token') || null
    return {
      headers: {
        ...headers,
        ...(token ? { authorization: `Bearer ${token}` } : null)
      }
    }
  })
  return forward(operation)
})

const client = new ApolloClient({
  cache: cache,
  link: ApolloLink.from([
    logoutMiddleware,
    authLinkMiddleware,
    uploadLink,
    httpLink
  ]),
  clientState: {}
  // defaultOptions: options
})

let user = null
try {
  user = JSON.parse(localStorage.getItem('user'))
// eslint-disable-next-line no-empty
} catch (e) {
}

cache.writeQuery({
  query: authQuery,
  data: {
    user: user,
    isAuthenticated: !!user
  }
})

// eslint-disable-next-line import/prefer-default-export
export { client }
