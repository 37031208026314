import React from 'react'
import { Card, CardHeader, Container } from 'reactstrap'

const BasePage = ({ children, title }) => {
  return (
    <>
      <Container>
        <Card>
          {title && <CardHeader><span>{title.toLocaleUpperCase()}</span></CardHeader>}
          {children}
        </Card>
      </Container>
    </>
  )
}

export default BasePage
