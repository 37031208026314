import cx from 'classnames'
import { ErrorMessage, Field, Formik } from 'formik'

import i18n from 'libs/i18n'
import React from 'react'
import { Link } from 'react-router-dom'
import { Alert, Button, Form, FormFeedback, FormGroup, Label } from 'reactstrap'
import * as Yup from 'yup'

const SignInForm = ({
  className, lastError, initialValues, onSubmit
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object()
        .shape({
          email: Yup.string()
            .email('errors.validation.email_invalid')
            .required('errors.validation.email_required'),
          password: Yup.string()
            .required('errors.validation.password_required')
        })}
      onSubmit={onSubmit}>
      {({
        errors, touched, handleSubmit, isSubmitting, isValid
      }) => {
        const { email: emailErrors, password: passwordErrors, _error } = errors
        const { email: emailIsTouched, password: passwordIsTouched } = touched

        return (
          <Form className={className} onSubmit={handleSubmit}>
            {_error && <Alert color='danger'>{_error.message}</Alert>}
            {lastError && <Alert color='danger'>{lastError}</Alert>}
            <div className='card p-4'>
              <div className='card-body'>
                <h3><p>{i18n.t('labels.signin')}</p></h3>
                <FormGroup>
                  <Label className='text-muted'>{i18n.t('labels.email')}</Label>
                  <Field
                    name='email'
                    type='email'
                    className={cx('form-control', { 'is-invalid': emailErrors && emailIsTouched })}
                  />
                  <ErrorMessage name='email' component={FormFeedback}/>
                </FormGroup>
                <FormGroup>
                  <Label className='text-muted'>{i18n.t('labels.password')}</Label>
                  <Field
                    name='password'
                    type='password'
                    className={cx('form-control', { 'is-invalid': passwordErrors && passwordIsTouched })}
                  />
                  <ErrorMessage name='password' component={FormFeedback}/>
                </FormGroup>
                <div className='row'>
                  <div className='col-6'>
                    <Button
                      className='w-100'
                      type='submit'
                      color='primary'
                      disabled={isSubmitting || !isValid}
                    >
                      {i18n.t('auth.sign_in')}
                    </Button>
                  </div>
                  <div className='col-6 d-flex align-items-center'>
                    <Link className='ml-auto' to='/recover'>
                      {i18n.t('auth.forgot_password')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default SignInForm
