import { get } from 'lodash'
import React from 'react'
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap'

const FormInput = ({
  id,
  field,
  form: { errors, touched },
  label,
  labelClasses,
  className,
  hidden,
  ...restProps
}) => {
  const { name } = field

  const invalid = get(errors, name) && get(touched, name)

  return (
    <FormGroup>
      {(label && !hidden) && <Label className={labelClasses} for={name}>{label}</Label>}
      <Input
        name={name}
        id={name}
        invalid={invalid}
        hidden={hidden}
        {...field}
        {...restProps}
      />
      {invalid && <FormFeedback>{get(errors, name)}</FormFeedback>}
    </FormGroup>
  )
}

export default FormInput
