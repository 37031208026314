import { useMutation } from '@apollo/react-hooks'
import BreadcrumbCommon from 'app/components/common/BreadcrumbCommon'

import BasePage from 'app/components/layout/BasePage'
import CompanyForm from 'app/pages/company/components/CompanyForm'
import { CREATE_CUSTOMER_COMPANY } from 'gql/schema/customerCompany/mutations'
import { CUSTOMER_COMPANIES } from 'gql/schema/customerCompany/queries'
import withCurrentUser from 'hoc/withCurrentUser'
import CustomerCompany from 'models/customerCompany'
import { isCompanyAdmin } from 'models/UsersType'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { BreadcrumbItem } from 'reactstrap'
import CardBody from 'reactstrap/lib/CardBody'

/* CREATE COMPANY PAGE */
const CreateSubCompany = ({ user }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [createCustomerCompany] = useMutation(CREATE_CUSTOMER_COMPANY, {
    onCompleted (value) {
      history.push('/sub-companies')
    },
    refetchQueries: [{
      query: CUSTOMER_COMPANIES,
      variables: {
        id: user.companyId
      }
    }]
  })

  const handleSubmit = useCallback(async (values) => {
    /* create customer company */
    if (createCustomerCompany && isCompanyAdmin(user.role)) {
      if (typeof values.companyLogo === 'string') {
        values.companyLogo = null
      }

      await createCustomerCompany({
        variables: {
          ...values
        }
      })
    }
  }, [createCustomerCompany, user])

  return (
    <>
      <BreadcrumbCommon>
        <BreadcrumbItem active>{t('common.page.admin.sub_companies')}</BreadcrumbItem>
        <BreadcrumbItem active>{t('common.page.admin.create')}</BreadcrumbItem>
      </BreadcrumbCommon>
      <BasePage title={t('common.page.admin.create_company')}>
        <CardBody>
          <CompanyForm onSubmit={handleSubmit} initialValues={CustomerCompany.initialData} enableReinitialize user={user}/>
        </CardBody>
      </BasePage>
    </>
  )
}

export default withCurrentUser(CreateSubCompany)
