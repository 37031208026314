import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faExchangeAlt, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import UsersType from 'models/UsersType'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Table } from 'reactstrap'

const DevicesManageTable = ({ devicesList, deleteCallBack, userRole, backPath }) => {
  const { t } = useTranslation()

  if (!devicesList) return null

  const isAdmin = (userRole === UsersType.ADMIN || userRole === UsersType.COMPANY_ADMIN)

  return (
    <>
      <Table borderless responsive className='table-hover'>
        <thead>
          <tr>
            <th>{t('labels.serialNumber')}</th>
            <th>{t('labels.deviceType')}</th>
            <th>{t('labels.descriptionShort')}</th>
            <th className='text-center'>Action</th>
          </tr>
        </thead>
        <tbody>
          {devicesList.map(value => {
            return (
              <tr key={value._id} className='font-sm'>
                <td>{value.serialNumber}</td>
                <td>{value.deviceType && value.deviceType.sku}</td>
                <td>{value.deviceType && value.deviceType.descriptionShort}</td>
                {isAdmin && <td>
                  <div className='d-flex justify-content-center'>
                    {/* view button */}
                    <Link to={`/device/view/${value._id}`} className='btn btn-ghost-info'>
                      <FontAwesomeIcon icon={faEye}/>
                    </Link>
                    <Link disabled to={`/device/move/${value._id}`} className='btn btn-ghost-info'>
                      <FontAwesomeIcon icon={faExchangeAlt}/>
                    </Link>
                    <Link disabled to={{
                      pathname: `/device/edit/${value._id}`,
                      state: { prevPath: backPath }
                    }} className='btn btn-ghost-info'>
                      <FontAwesomeIcon icon={faEdit}/>
                    </Link>
                    <button
                      className='btn btn-ghost-danger'
                      type='button'
                      onClick={() => deleteCallBack && deleteCallBack(value._id)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt}/>
                    </button>
                  </div>
                </td>}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

export default DevicesManageTable
