import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BreadcrumbCommon from 'app/components/common/BreadcrumbCommon'
import HandlerComponent from 'app/components/common/HandlerComponent'
import BasePage from 'app/components/layout/BasePage'
import UsersList from 'app/pages/users/UsersList'
import { UsersListQuery } from 'gql/schema'
import useDeleteUser from 'hooks/gql/useDeleteUser'
import useFetchUsers from 'hooks/gql/useFetchUsers'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { BreadcrumbItem } from 'reactstrap'

const Users = () => {
  const { t } = useTranslation()
  const { loading, error, data } = useFetchUsers()

  /* DELETE USER MUTATION */
  const [deleteUser] = useDeleteUser({
    refetchQueries: UsersListQuery
  })
  const onUserDelete = useCallback(async (userID) => {
    await deleteUser({ variables: { id: userID } })
  }, [deleteUser])

  /* RENDER PAGE */
  return (
    <>
      <BreadcrumbCommon>
        <BreadcrumbItem active>{t('common.page.admin.users')}</BreadcrumbItem>
        {/* ADD NEW USER BUTTON */}
        <li className='breadcrumb-menu'>
          <div className='btn-group' role='group'>
            <Link to='/user/create' className='btn btn-transparent'>
              <FontAwesomeIcon className='nav-icon' icon={faUserPlus} />
              <span className='ml-1'>{t('labels.create')}</span>
            </Link>
          </div>
        </li>
      </BreadcrumbCommon>
      <BasePage title={t('common.page.admin.users')}>
        <HandlerComponent loading={loading} error={error} />
        {data && <UsersList users={data.usersList} deleteCallBack={onUserDelete} />}
      </BasePage>
    </>
  )
}

export default Users
