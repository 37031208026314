import { useMutation, useQuery } from '@apollo/react-hooks'
import BreadcrumbCommon from 'app/components/common/BreadcrumbCommon'
import HandlerComponent from 'app/components/common/HandlerComponent'
import BasePage from 'app/components/layout/BasePage'
import DeviceForm from 'app/pages/devices/forms/DeviceForm'
import { UPDATE_DEVICE } from 'gql/schema/devices/mutations'
import { DEVICE, DEVICES_LIST_PAGINATE_QUERY } from 'gql/schema/devices/queries'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import CardBody from 'reactstrap/lib/CardBody'

const DeviceEdit = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { location: { state } } = history
  const { id } = useParams()
  /* INITIAL DATA */
  const { data, loading, error } = useQuery(DEVICE, {
    variables: { id },
    fetchPolicy: 'no-cache'
  })
  const [device, setDevice] = useState(null)

  useEffect(() => {
    if (data) {
      const { device: { currentStorage, buildingDate, builderId, serialNumber, hardwareVersion, deviceType, deviceFields } } = data
      /* parse storage */
      const _currentStorage = {
        value: currentStorage._id,
        label: currentStorage.name
      }
      /* parse deviceType */
      const _deviceType = {
        value: deviceType._id,
        label: deviceType.sku
      }
      /* parse deviceFields */
      const _deviceFields = deviceFields.map(value => JSON.parse(value))
      /* update state */
      setDevice({
        serialNumber,
        hardwareVersion: hardwareVersion || '',
        deviceType: _deviceType,
        buildingDate: buildingDate || '',
        builderId: builderId || '',
        currentStorageId: _currentStorage,
        deviceFields: _deviceFields
      })
    }
  }, [data])

  /* UPDATE DEVICE */
  const [updateDevice] = useMutation(UPDATE_DEVICE, {
    onCompleted ({ updateDevice: { status } }) {
      if (status === 'ok') history.push('/devices/manage')
    },
    refetchQueries: [{ query: DEVICES_LIST_PAGINATE_QUERY, variables: {} }]
  })

  const handleSubmit = useCallback(async (values) => {
    if (updateDevice) {
      const currentStorageId = values.currentStorageId.value
      const deviceType = values.deviceType.value
      const deviceFields = values.deviceFields.map(value => JSON.stringify(value))

      await updateDevice({
        variables: {
          id,
          ...values,
          currentStorageId,
          deviceType,
          deviceFields
        }
      })
    }
  }, [updateDevice, id])

  return (
    <>
      {state.prevPath ? <BreadcrumbCommon
        customPath={state.prevPath}
        customLinkText={t('common.page.admin.devices_list')}/> : <BreadcrumbCommon/>
      }
      <BasePage title={t('common.page.admin.edit')}>
        <CardBody>
          <HandlerComponent loading={loading} error={error}/>
          {device && <DeviceForm
            onSubmit={handleSubmit}
            initialValues={device}
            editState
          />}
        </CardBody>
      </BasePage>
    </>
  )
}

export default DeviceEdit
