import gql from 'graphql-tag'

export const DEVICE = gql`
    query device($id:ID) {
        device(id:$id) {
            _id
            currentStorage {
                _id
                name
            }
            company {
                _id
                name
            }
            builderId
            buildingDate
            deviceFields
            deviceType {
                _id
                sku
                descriptionShort
                deviceFields
            }
            serialNumber
            hardwareVersion
        }
    }
`

export const DEVICES_LIST_QUERY = gql`
    query devicesList {
        devicesList {
            _id
            deviceUniqueId
            company {
                _id
                name
            }
            currentStorage {
                _id
                name
            }
            builderId
            buildingDate
            deviceFields
            deviceType {
                _id
                sku
                descriptionShort
                deviceFields
            }
            serialNumber
            hardwareVersion
        }
    }
`

export const DEVICES_LIST_PAGINATE_QUERY = gql`
    query devicesListPaginate($page: Int, $count: Int, $filters:[String]) {
        devicesListPaginate(page: $page, count: $count, filters: $filters) {
            list {
                _id
                deviceUniqueId
                company {
                    _id
                    name
                }
                currentStorage {
                    _id
                    name
                }
                builderId
                buildingDate
                deviceFields
                deviceType {
                    _id
                    sku
                    descriptionShort
                    deviceFields
                }
                serialNumber
                hardwareVersion
            }
            page
            count
            total
        }
    }
`

export const CUSTOMER_DEVICES_LIST_QUERY = gql`
    query customerDevicesList {
        customerDevicesList {
            _id
            deviceUniqueId
            company {
                _id
                name
            }
            currentStorage {
                _id
                name
            }
            builderId
            buildingDate
            deviceFields
            deviceType {
                _id
                sku
            }
            serialNumber
            hardwareVersion
        }
    }
`

export const STORAGE_DEVICES_LIST = gql`
    query ($id:ID){
        storageDevicesList(id: $id) {
            _id
            deviceUniqueId
        }
    }
`

export const DEVICE_TYPES_LIST = gql`
    {
        deviceTypesList {
            _id
            sku
            descriptionShort
            descriptionLong
            deviceFields
            firmware {
                _id
                version
                descriptionShort
                descriptionLong
                filePath
                isDefault
            }
        }
    }
`

export const DEVICE_TYPE = gql`
    query ($id: ID!) {
        deviceType(id: $id) {
            _id
            sku
            descriptionShort
            descriptionLong
            deviceFields
            firmware {
                _id
                version
                descriptionShort
                descriptionLong
                filePath
                isDefault
            }
        }
    }
`

export const DEVICE_TYPE_FIRMWARE = gql`
    query ($id:ID!){
        deviceTypeFirmwareList(id: $id) {
            _id
            version
            descriptionShort
            descriptionLong
            filePath
            isDefault
        }
    }
`
