import { formikForm, TextInput } from 'app/components/forms'
import Heading from 'app/components/Heading'
import Text from 'app/components/Text'
import { Field } from 'formik'

import { composeValidators, email, required } from 'libs/forms/validators'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Alert, Button, Col, Form, FormGroup, Row } from 'reactstrap'

const RecoverForm = ({
  handleSubmit,
  isSubmitting,
  isValid,
  error,
  ...rest
}) => {
  const { t } = useTranslation()

  return (
    <Form className='d-flex flex-column justify-content-between' onSubmit={handleSubmit}>
      <Heading size={3}>{t('auth.reset_password_text')}</Heading>
      {error && <Alert color='danger'>{error.message}</Alert>}

      <Row className='mt-2'>
        <Col>
          <Field
            name='email'
            type='email'
            label={t('labels.email')}
            labelClasses='text-muted'
            component={TextInput}
            validate={composeValidators(email, required)}
          />

          <FormGroup className='d-flex align-items-center justify-content-between m-0'>
            <Text tag={Link} to='/'>
                            &larr;&nbsp;
              {t('labels.back_to_home')}
            </Text>

            <Button type='submit' color='primary' disabled={isSubmitting || !isValid}>
              {t('labels.reset_password')}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  )
}

export default formikForm(RecoverForm)
