import { useMutation } from '@apollo/react-hooks'
import BreadcrumbCommon from 'app/components/common/BreadcrumbCommon'
import HandlerComponent from 'app/components/common/HandlerComponent'
import SelectField from 'app/components/forms/SelectField'
import BasePage from 'app/components/layout/BasePage'
import { Field, Formik } from 'formik'
import { MOVE_DEVICE } from 'gql/schema/devices/mutations'
import useFetchStorageListToMove from 'hooks/gql/useFetchStorageListToMove'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { BreadcrumbItem, Button, Form } from 'reactstrap'
import Container from 'reactstrap/lib/Container'
import FormGroup from 'reactstrap/lib/FormGroup'

const DeviceMove = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const history = useHistory()

  /* FETCH STORAGE LIST */
  const { data, loading, error } = useFetchStorageListToMove()
  const [storages, setStorages] = useState(null)

  useEffect(
    () => {
      if (data) {
        const { storageListToMove } = data
        const storages = storageListToMove.map(store => ({
          value: store._id,
          label: store.name
        }))

        setStorages(storages)
      }
    }, [data]
  )

  const [moveDevice] = useMutation(MOVE_DEVICE, {
    onCompleted ({ moveDevice: { status } }) {
      if (status === 'ok') history.push('/devices/manage')
    }
  })

  const handleMove = useCallback(async ({ currentStorage }) => {
    if (moveDevice) {
      await moveDevice({
        variables: {
          id,
          storeID: currentStorage.value
        }
      })
    }
  }, [moveDevice, id])

  return (
    <>
      <BreadcrumbCommon>
        <BreadcrumbItem active>{t('common.page.admin.devices_move')}</BreadcrumbItem>
        {/* ADD NEW USER BUTTON */}
      </BreadcrumbCommon>
      <BasePage title={t('common.page.admin.devices_move')}>
        <HandlerComponent loading={loading} error={error} />
        {storages && <Formik
          enableReinitialize
          initialValues={{
            currentStorage: ''
          }}
          onSubmit={handleMove}>
          {({ isSubmitting, isValid, handleSubmit }) => {
            return (
              <Container>
                <Form className='mt-3' onSubmit={handleSubmit}>
                  <FormGroup>
                    <Field
                      name='currentStorage'
                      label={t('labels.storage.name')}
                      labelClasses='text-muted'
                      options={storages}
                      component={SelectField}
                      /* changeHandler={(v) => onStorageChange(v)} */
                      isMulti={false}
                      isClearable
                    />
                  </FormGroup>
                  <FormGroup>
                    <Button type='submit' color='primary' disabled={isSubmitting || !isValid} className='mt-2'>
                      {t('labels.move')}
                    </Button>
                  </FormGroup>
                </Form>
              </Container>
            )
          }}
        </Formik>}
      </BasePage>
    </>
  )
}

export default DeviceMove
