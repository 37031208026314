import { useApolloClient, useMutation } from '@apollo/react-hooks'
import { faFilter, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DeleteAlert } from 'app/components/alerts'
import BreadcrumbCommon from 'app/components/common/BreadcrumbCommon'
import DevicesManageTable from 'app/components/common/DevicesManageTable'
import PaginationContainer from 'app/components/common/PaginationContainer'
import BasePage from 'app/components/layout/BasePage'
import DeviceFiltersForm, { FILTERS } from 'app/pages/devices/forms/DeviceFiltersForm'
import { DELETE_DEVICE } from 'gql/schema/devices/mutations'
import { DEVICES_LIST_PAGINATE_QUERY } from 'gql/schema/devices/queries'
import withCurrentUser from 'hoc/withCurrentUser'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { BreadcrumbItem } from 'reactstrap'

const DEVICES_COUNT = 10

const DevicesManage = ({ user: { role } }) => {
  const { t } = useTranslation()
  const { location: { pathname } } = useHistory()

  const [state, setState] = useState({
    filterShown: false,
    devicesListFiltered: null,
    devicesList: null,
    paginationData: null,
    page: 1,
    count: DEVICES_COUNT,
    total: 0
  })
  // const [filteredField, setFilteredFields] = useState(FILTERS)
  const client = useApolloClient()

  /* DELETE DEVICE */
  const [deleteDevice] = useMutation(DELETE_DEVICE, {
    onCompleted ({ deleteDevice: { status } }) {
      if (status === 'ok') window.location.reload()
    }
  })

  const devicesLoadedHandler = useCallback((data) => {
    if (data) {
      const { devicesListPaginate } = data
      setState((prev) => ({
        ...prev,
        total: devicesListPaginate.total,
        count: devicesListPaginate.count,
        page: devicesListPaginate.page,
        devicesList: devicesListPaginate.list
      }))
    }
  }, [setState])

  const refetchDevices = useCallback(async (page, filters) => {
    const filtersOptions = filters || FILTERS
    const devicesResponse = await client.query({
      query: DEVICES_LIST_PAGINATE_QUERY,
      variables: {
        page,
        count: DEVICES_COUNT,
        filters: Object.keys(filtersOptions)
          .filter(key => {
            return !!filtersOptions[key]
          })
          .map(key => JSON.stringify({
            [key]: filtersOptions[key]
          }))
      },
      fetchPolicy: 'no-cache'
    })
    devicesLoadedHandler(devicesResponse.data)
  }, [client, devicesLoadedHandler])

  const handleDelete = useCallback(async (id) => {
    if (deleteDevice) {
      DeleteAlert(t)
        .then(value => {
          value && deleteDevice({ variables: { id } })
        })
    }
  }, [deleteDevice, t])

  /* HANDLE DEVICE LIST */
  useEffect(() => {
    refetchDevices(1)
  }, [refetchDevices])

  /* HANDLE FILTERS */
  const filtersHandler = useCallback((values, actions) => {
    refetchDevices(1, {
      companyId: (values.companyId && typeof values.companyId === 'object') ? values.companyId.value : null,
      currentStorageId: (values.currentStorageId && typeof values.currentStorageId === 'object') ? values.currentStorageId.value : null,
      serialNumber: values.serialNumber ? values.serialNumber : null,
      deviceFields: values.deviceFields ? String(values.deviceFields)
        .split(',')
        .filter(value => !!value)
        .join('|') : null,
      deviceType: (values.deviceType && typeof values.deviceType === 'object') ? values.deviceType.value : null
    })
    actions.setSubmitting(false)
  }, [refetchDevices])

  const resetHandler = useCallback((values, actions) => {
    refetchDevices(1)
  }, [refetchDevices])

  return (
    <>
      <BreadcrumbCommon>
        <BreadcrumbItem active>{t('common.page.admin.devices_list')}</BreadcrumbItem>
        {/* ADD NEW USER BUTTON */}
        <li className='breadcrumb-menu'>
          <div className='btn-group' role='group'>
            <Link to='/device/create' className='btn btn-transparent'>
              <FontAwesomeIcon className='nav-icon' icon={faPlus}/>
              <span className='ml-1'>{t('labels.create')}</span>
            </Link>
            <button className='btn btn-transparent' onClick={() => setState((prev) => ({
              ...prev,
              filterShown: !state.filterShown
            }))}>
              <FontAwesomeIcon className='nav-icon' icon={faFilter}/>
              <span className='ml-1'>{t('labels.filter')}</span>
            </button>
          </div>
        </li>
      </BreadcrumbCommon>
      <DeviceFiltersForm
        initialValues={{
          companyId: '',
          currentStorageId: '',
          buildingDate: '',
          serialNumber: '',
          deviceFields: '',
          deviceType: ''
        }}
        isVisible={state.filterShown}
        onSubmit={filtersHandler}
        onReset={resetHandler}
        enableReinitialize
      />
      <BasePage title={t('common.page.admin.devices_list')}>
        {state.devicesList &&
        <DevicesManageTable devicesList={state.devicesList} userRole={role} deleteCallBack={handleDelete} backPath={pathname}/>}
        <PaginationContainer data={{
          page: state.page,
          count: state.count,
          total: state.total
        }} onPageClick={refetchDevices}/>
      </BasePage>
    </>
  )
}

export default withCurrentUser(DevicesManage)
