import { TextInput } from 'app/components/forms'
import FormInputCheckbox from 'app/components/forms/FormInputCheckbox'
import { Field } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'
import Card from 'reactstrap/lib/Card'
import CardBody from 'reactstrap/lib/CardBody'
import CardHeader from 'reactstrap/lib/CardHeader'

const DeviceFields = ({ fieldsKey, fields, index }) => {
  const { t } = useTranslation()
  const formFields = Object.keys(fields[fieldsKey])
    .map(key => {
      return {
        key,
        type: typeof fields[fieldsKey][key] === 'boolean' ? 'checkbox' : 'text',
        component: typeof fields[fieldsKey][key] === 'boolean' ? FormInputCheckbox : TextInput
      }
    })

  return (
    <>
      <Card>
        <CardHeader>{fieldsKey}</CardHeader>
        <CardBody>
          <Row form>
            {formFields.map(fieldData => (
              <Col
                key={`deviceFields[${index}].${fieldsKey}.${fieldData.key}`}
                className='d-flex align-items-center justify-content-start'>
                <Field
                  name={`deviceFields[${index}].${fieldsKey}.${fieldData.key}`}
                  label={t(`labels.${fieldData.key}`)}
                  labelClasses='text-muted'
                  component={fieldData.component}
                  type={fieldData.type}
                />
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default DeviceFields
