import { useQuery } from '@apollo/react-hooks'
import { formikForm, SelectField, TextInput } from 'app/components/forms'
import image from 'assets/images/no-image.png'
import { Field } from 'formik'
import { CUSTOMER_COMPANIES } from 'gql/schema/customerCompany/queries'
import withCurrentUser from 'hoc/withCurrentUser'
import { composeValidators, email, required } from 'libs/forms/validators'
import { AVAILABLE_LOCALIZATIONS } from 'models/localizations'
import { getAllowedUserTypesFor } from 'models/Privacy'
import UsersType from 'models/UsersType'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Card, Form, FormGroup } from 'reactstrap'
import CardBody from 'reactstrap/lib/CardBody'
import CardFooter from 'reactstrap/lib/CardFooter'
import CardHeader from 'reactstrap/lib/CardHeader'

const EditUserForm = (
  {
    handleSubmit,
    isSubmitting,
    isValid,
    error,
    initialValues,
    user,
    values,
    setFieldValue,
    dirty,
    ...rest
  }
) => {
  const [customerCompanies, setCustomerCompanies] = useState([])
  const [companyImage, setCompanyImage] = useState(values.companyLogo || image)
  const { t } = useTranslation()
  const allowedUserTypes = getAllowedUserTypesFor(user.role)

  const isCustomer = useMemo(() => {
    return values.role && values.role.value === UsersType.CLIENT_COMPANY_ADMIN
  }, [values.role])

  useQuery(CUSTOMER_COMPANIES, {
    variables: {
      id: user.companyId
    },
    onCompleted ({ customerCompaniesList }) {
      setCustomerCompanies(customerCompaniesList.map(company => {
        return {
          value: company._id,
          label: company.name
        }
      }))
    }
  })

  return (
    <>
      <Form className='d-flex flex-column justify-content-between' onSubmit={handleSubmit}>
        {error && <Alert color='danger'>{error.message}</Alert>}
        <Card className='card-accent-warning'>
          <CardHeader>{t('labels.change_user_information')}</CardHeader>
          <CardBody>
            <Field
              name='name'
              autoComplete='new-name'
              type='text'
              label={t('labels.user_name')}
              labelClasses='text-muted'
              component={TextInput}
              validate={required}
            />
            {/* USER SECOND NAME */}
            <Field
              name='surname'
              autoComplete='new-surname'
              type='text'
              label={t('labels.user_second_name')}
              labelClasses='text-muted'
              component={TextInput}
              validate={required}
            />
            {/* USER ROLE */}
            {isCustomer && <Field
              name='role'
              label={t('labels.account_type')}
              labelClasses='text-muted'
              options={allowedUserTypes.map(role => {
                return {
                  label: String(t(`labels.${role}`))
                    .toUpperCase(),
                  value: role
                }
              })}
              component={SelectField}
              isMulti={false}
              validate={required}
            />}
            {isCustomer && <Field
              name='customerCompanyId'
              label={t('labels.company.name')}
              labelClasses='text-muted'
              options={customerCompanies}
              component={SelectField}
              isMulti={false}
              validate={required}
              disabled={!customerCompanies.length}
            />}
            {/* USER EMAIL */}
            <Field
              name='email'
              autoComplete='new-email'
              type='email'
              label={t('labels.email')}
              labelClasses='text-muted'
              component={TextInput}
              validate={composeValidators(email, required)}
            />
            <Field
              name='localization'
              label={t('labels.language')}
              labelClasses='text-muted'
              options={AVAILABLE_LOCALIZATIONS.map(locale => {
                return {
                  label: String(t(`labels.locale.${locale}`)),
                  value: locale
                }
              })}
              component={SelectField}
              isMulti={false}
              validate={required}
            />
          </CardBody>
          <CardFooter>
            <FormGroup className='d-flex align-items-center justify-content-between m-0'>
              <Button type='submit' color='primary' disabled={isSubmitting || !isValid || !dirty}>
                {t('labels.update')}
              </Button>
            </FormGroup>
          </CardFooter>
        </Card>
      </Form>
    </>
  )
}

EditUserForm.propTypes = {
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  error: PropTypes.object,
  initialValues: PropTypes.object,
  user: PropTypes.object
}

export default formikForm(withCurrentUser(EditUserForm))
