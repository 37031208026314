import localStorage from 'libs/localStorage'
import React, { useEffect } from 'react'

const LogoutPage = () => {
  useEffect(() => {
    localStorage.removeItem('user')
    window.location.reload()
  })

  return (
    <>
    </>
  )
}

export default LogoutPage
