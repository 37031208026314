import { useQuery } from '@apollo/react-hooks'
import { StorageListQuery } from 'gql/schema'

const useFetchStorageList = () => {
  return useQuery(StorageListQuery, {
    fetchPolicy: 'network-only'
  })
}

export default useFetchStorageList
