import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import CardFooter from 'reactstrap/lib/CardFooter'

const PaginationButton = ({ onClick, title, active }) => {
  return (
    <PaginationItem active={active}>
      <PaginationLink className='p-1' onClick={onClick}>{title}</PaginationLink>
    </PaginationItem>
  )
}

const PaginationContainer = ({ data, onPageClick }) => {
  const { page, count, total } = data
  const buttonsList = []
  const buttonsCount = Math.ceil(total / count)

  for (let i = 1; i <= buttonsCount; i++) {
    buttonsList.push(
      <PaginationButton
        active={i === page}
        key={i}
        onClick={() => onPageClick && onPageClick(i)}
        title={i}
      />
    )
  }

  return (
    <CardFooter className='d-flex justify-content-end'>
      <Pagination aria-label="">
        <PaginationItem disabled={page === 1}>
          <PaginationLink first className='p-1' onClick={() => onPageClick && onPageClick(1)}/>
        </PaginationItem>
        {buttonsList}
        <PaginationItem disabled={page === buttonsCount || !buttonsCount}>
          <PaginationLink last className='p-1' onClick={() => onPageClick && onPageClick(buttonsCount)}/>
        </PaginationItem>
      </Pagination>
    </CardFooter>
  )
}

export default PaginationContainer
