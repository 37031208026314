class LocalStorageService {
  get isStorageAvailable () {
    if (this.isAvailable === undefined) {
      try {
        const x = '__storage_test__'
        window.localStorage.setItem(x, x)
        window.localStorage.removeItem(x)
        this.isAvailable = true
      } catch (e) {
        this.isAvailable = false
      }
    }

    return this.isAvailable
  }

  setItem (key, value) {
    if (this.isStorageAvailable) {
      window.localStorage.setItem(key, value)
    }
  }

  getItem (key) {
    if (this.isStorageAvailable) {
      return window.localStorage.getItem(key)
    }

    return null
  }

  removeItem (key) {
    if (this.isStorageAvailable) {
      return window.localStorage.removeItem(key)
    }

    return null
  }

  clear () {
    if (this.isStorageAvailable) {
      window.localStorage.clear()
    }
  }
}

export default new LocalStorageService()
