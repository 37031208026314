import { get } from 'lodash'
import React from 'react'
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap'

const FormInputCheckbox = ({
  id,
  field,
  form: { errors, touched },
  label,
  labelClasses,
  className,
  hidden,
  ...restProps
}) => {
  const { name } = field

  const invalid = get(errors, name) && get(touched, name)

  return (
    <FormGroup check>
      {(label && !hidden) && <Label check className={labelClasses} for={id}>
        <Input
          name={name}
          id={id}
          invalid={invalid}
          hidden={hidden}
          {...field}
          {...restProps}
        />
        {label}
      </Label>}

      {invalid && <FormFeedback>{get(errors, name)}</FormFeedback>}
    </FormGroup>
  )
}

export default FormInputCheckbox
