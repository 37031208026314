import gql from 'graphql-tag'

export const CREATE_USER = gql`
    mutation createUser(
        $name: String!,
        $surname: String!,
        $email: String!,
        $password: String!,
        $role: RoleEnum!,
        $customerCompanyId:ID,
        $users: [String],
        $companies: [String],
        $stores: [String],
        $devices: [String],
        $localization: String!
    ) {
        createUser(
            name: $name,
            surname: $surname,
            email: $email,
            password: $password,
            role: $role,
            customerCompanyId: $customerCompanyId,
            users: $users,
            companies: $companies,
            stores: $stores,
            devices: $devices,
            localization: $localization,
        ) {
            status
            data
        }
    }
`

export const UPDATE_USER = gql`
    mutation updateUser(
        $_id:String!, 
        $name:String, 
        $surname:String, 
        $email:String, 
        $role:RoleEnum, 
        $customerCompanyId:ID,
        $localization: String!
    ) {
        updateUser(
            _id:$_id, 
            name:$name, 
            surname:$surname, 
            email:$email, 
            role:$role, 
            customerCompanyId: $customerCompanyId,
            localization: $localization
        ) {
            status
            data
        }
    }
`

export const CHANGE_PERMISSIONS = gql`
    mutation changeUserPermissions(
        $id: ID!,
        $users:[String],
        $companies: [String],
        $stores: [String],
        $devices: [String]
    ) {
        changeUserPermissions(
            id: $id,
            users: $users,
            companies: $companies,
            stores: $stores,
            devices: $devices
        ){
            status
            data
        }
    }
`

export const CHANGE_USER_LOCALIZATION = gql`
    mutation ($localization: String!) {
        changeUserLocalization(localization: $localization) {
            status
            data
        }
    }
`
