import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'

const BreadcrumbCommon = ({ children, customPath, customLinkText }) => {
  const { t } = useTranslation()

  const path = customPath || '/'
  const linkText = customLinkText || t('common.page.admin.dashboard')

  return (
    <Breadcrumb>
      <BreadcrumbItem>
        <Link to={path}>
          {customPath && <FontAwesomeIcon className='nav-icon mr-1' icon={faArrowLeft}/>}
          {linkText}
        </Link>
      </BreadcrumbItem>
      {children}
    </Breadcrumb>
  )
}

export default BreadcrumbCommon
