import { useMutation, useQuery } from '@apollo/react-hooks'
import BreadcrumbCommon from 'app/components/common/BreadcrumbCommon'
import HandlerComponent from 'app/components/common/HandlerComponent'
import BasePage from 'app/components/layout/BasePage'
import DeviceTypeForm from 'app/pages/devices/forms/DeviceTypeForm'
import { UPDATE_DEVICE_TYPE } from 'gql/schema/devices/mutations'
import { DEVICE_TYPE, DEVICE_TYPES_LIST } from 'gql/schema/devices/queries'
import withCurrentUser from 'hoc/withCurrentUser'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import CardBody from 'reactstrap/lib/CardBody'

const DeviceTypeEdit = withCurrentUser(() => {
  const { t } = useTranslation()
  const { id } = useParams()
  const history = useHistory()

  const { data, loading, error } = useQuery(DEVICE_TYPE, {
    variables: { id }
  })

  const [updateMutation] = useMutation(UPDATE_DEVICE_TYPE, {
    onCompleted ({ updateDeviceType }) {
      updateDeviceType && history.push('/device/types')
    },
    refetchQueries: [{ query: DEVICE_TYPES_LIST }, {
      query: DEVICE_TYPE,
      variables: { id }
    }]
  })

  const updateHandler = useCallback((values) => {
    if (updateMutation) {
      updateMutation({
        variables: {
          id,
          ...values
        }
      })
    }
  }, [id, updateMutation])

  return (
    <>
      <BreadcrumbCommon
        customPath={'/device/types'}
        customLinkText={t('common.page.admin.device_types')}/>
      <BasePage>
        <CardBody>
          <HandlerComponent loading={loading} error={error}/>
          {data && <DeviceTypeForm
            onSubmit={updateHandler}
            initialValues={data.deviceType}
            editState
          />}

        </CardBody>
      </BasePage>
    </>
  )
})

export default DeviceTypeEdit
