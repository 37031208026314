import { faAddressBook, faBuilding, faListAlt } from '@fortawesome/free-regular-svg-icons'
import { faGripHorizontal, faTable, faThList, faUserPlus, faWarehouse } from '@fortawesome/free-solid-svg-icons'
import SIDEBAR_ITEM_TYPE from 'app/components/sidebar/SidebarItemType'
import i18n from 'libs/i18n'
import { PRIVACY_ACTIONS, PRIVACY_TYPE } from 'models/Privacy'
import { isAdmin, isClientCompanyAdmin, isCompanyAdmin, isSuperAdmin } from 'models/UsersType'

const SidebarData = () => {
  return [
    {
      type: SIDEBAR_ITEM_TYPE.LINK,
      to: '/admin',
      text: i18n.t('common.menu.dashboard'),
      faIcon: faGripHorizontal,
      resolver: (user) => {
        return isAdmin(user.role)
      }
    },
    {
      type: SIDEBAR_ITEM_TYPE.LINK,
      to: '/client/dashboard',
      text: i18n.t('common.menu.dashboard'),
      faIcon: faGripHorizontal,
      resolver: (user) => {
        return isClientCompanyAdmin(user.role)
      }
    },
    {
      type: SIDEBAR_ITEM_TYPE.TITLE,
      text: i18n.t('common.menu.users'),
      resolver: (user) => {
        if (isAdmin(user.role)) return true
      }
    },
    {
      type: SIDEBAR_ITEM_TYPE.CATEGORY,
      resolver: (user) => {
        if (isAdmin(user.role)) return true
      },
      items: [
        {
          type: SIDEBAR_ITEM_TYPE.LINK,
          to: '/admin/users',
          text: i18n.t('common.menu.users_list'),
          faIcon: faAddressBook,
          resolver: (user) => {
            if (isAdmin(user.role)) return true
            return !!user.privacy && user.privacy[PRIVACY_TYPE.USERS].includes(PRIVACY_ACTIONS.VIEW)
          }
        },
        {
          type: SIDEBAR_ITEM_TYPE.LINK,
          to: '/user/create',
          text: i18n.t('labels.create'),
          faIcon: faUserPlus,
          resolver: (user) => {
            if (isAdmin(user.role)) return true
            return !!user.privacy && user.privacy[PRIVACY_TYPE.USERS].includes(PRIVACY_ACTIONS.CREATE)
          }
        }
      ]
    },
    {
      type: SIDEBAR_ITEM_TYPE.TITLE,
      text: i18n.t('common.menu.company'),
      resolver: (user) => {
        if (isAdmin(user.role)) return true
        if (isClientCompanyAdmin(user.role)) return false
        return !!user.privacy && user.privacy[PRIVACY_TYPE.COMPANIES].includes(PRIVACY_ACTIONS.VIEW)
      }
    },
    {
      type: SIDEBAR_ITEM_TYPE.CATEGORY,
      resolver: (user) => {
        if (isAdmin(user.role)) return true
        return !!user.privacy && user.privacy[PRIVACY_TYPE.COMPANIES].includes(PRIVACY_ACTIONS.VIEW)
      },
      items: [
        {
          type: SIDEBAR_ITEM_TYPE.LINK,
          to: '/companies',
          text: i18n.t('common.menu.companies_list'),
          faIcon: faTable,
          resolver: (user) => {
            return (isSuperAdmin(user.role))
          }
        },
        {
          type: SIDEBAR_ITEM_TYPE.LINK,
          to: '/company/info',
          text: i18n.t('common.menu.company'),
          faIcon: faBuilding,
          resolver: (user) => {
            if (isAdmin(user.role)) return true
            if (isClientCompanyAdmin(user.role)) return false
            return !!user.privacy && user.privacy[PRIVACY_TYPE.COMPANIES].includes(PRIVACY_ACTIONS.EDIT)
          }
        },
        {
          type: SIDEBAR_ITEM_TYPE.LINK,
          to: '/sub-companies',
          text: i18n.t('common.menu.sub_companies'),
          faIcon: faBuilding,
          resolver: (user) => {
            return (isCompanyAdmin(user.role))
          }
        }
      ]
    },
    {
      type: SIDEBAR_ITEM_TYPE.TITLE,
      text: i18n.t('common.menu.storage'),
      resolver: (user) => {
        if (isAdmin(user.role) || isClientCompanyAdmin(user.role)) return true
        return !!user.privacy && user.privacy[PRIVACY_TYPE.STORES].includes(PRIVACY_ACTIONS.VIEW)
      }
    },
    {
      type: SIDEBAR_ITEM_TYPE.CATEGORY,
      resolver: (user) => {
        if (isAdmin(user.role) || isClientCompanyAdmin(user.role)) return true
        return !!user.privacy && user.privacy[PRIVACY_TYPE.STORES].includes(PRIVACY_ACTIONS.VIEW)
      },
      items: [
        {
          type: SIDEBAR_ITEM_TYPE.LINK,
          to: '/storage/list',
          text: i18n.t('common.menu.storage_list'),
          faIcon: faThList,
          resolver: (user) => {
            if (isAdmin(user.role) || isClientCompanyAdmin(user.role)) return true
            return !!user.privacy && user.privacy[PRIVACY_TYPE.STORES].includes(PRIVACY_ACTIONS.VIEW)
          }
        }, {
          type: SIDEBAR_ITEM_TYPE.LINK,
          to: '/storage/create',
          text: i18n.t('labels.create'),
          faIcon: faWarehouse,
          resolver: (user) => {
            if (isAdmin(user.role)) return true
            return !!user.privacy && user.privacy[PRIVACY_TYPE.STORES].includes(PRIVACY_ACTIONS.CREATE)
          }
        }

      ]
    },
    {
      type: SIDEBAR_ITEM_TYPE.TITLE,
      text: i18n.t('common.menu.devices'),
      resolver: (user) => {
        if (isAdmin(user.role) || isClientCompanyAdmin(user.role)) return true
        return !!user.privacy && user.privacy[PRIVACY_TYPE.DEVICES].includes(PRIVACY_ACTIONS.VIEW)
      }
    },
    {
      type: SIDEBAR_ITEM_TYPE.CATEGORY,
      resolver: (user) => {
        if (isAdmin(user.role) || isClientCompanyAdmin(user.role)) return true
        return !!user.privacy && user.privacy[PRIVACY_TYPE.DEVICES].includes(PRIVACY_ACTIONS.VIEW)
      },
      items: [
        {
          type: SIDEBAR_ITEM_TYPE.LINK,
          to: '/devices/manage',
          text: i18n.t('common.menu.devices_menage'),
          faIcon: faListAlt,
          resolver: (user) => {
            return isSuperAdmin(user.role)
          }
        },
        {
          type: SIDEBAR_ITEM_TYPE.LINK,
          to: '/devices/list',
          text: i18n.t('common.menu.devices_list'),
          faIcon: faListAlt,
          resolver: (user) => {
            if (isCompanyAdmin(user.role) || isClientCompanyAdmin(user.role)) return true
            return !!user.privacy && user.privacy[PRIVACY_TYPE.DEVICES].includes(PRIVACY_ACTIONS.VIEW)
          }
        },
        /* {
          type: SIDEBAR_ITEM_TYPE.LINK,
          to: '/device/create',
          text: i18n.t('labels.create'),
          faIcon: faMobileAlt,
          resolver: (user) => {
            return isSuperAdmin(user.role)
          }
        }, */
        {
          type: SIDEBAR_ITEM_TYPE.LINK,
          to: '/device/types',
          text: i18n.t('common.menu.device_types'),
          faIcon: faListAlt,
          resolver: (user) => {
            if (isSuperAdmin(user.role)) return true
          }
        }
      ]
    }
  ]
}

export default SidebarData
