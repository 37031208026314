import { useQuery } from '@apollo/react-hooks'
import { faCircle as faCheckCircleRegular } from '@fortawesome/free-regular-svg-icons'
import { faCheckCircle as faCheckCircleSolid } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BreadcrumbCommon from 'app/components/common/BreadcrumbCommon'
import HandlerComponent from 'app/components/common/HandlerComponent'
import BasePage from 'app/components/layout/BasePage'
import { DEVICE } from 'gql/schema/devices/queries'
import withCurrentUser from 'hoc/withCurrentUser'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ListGroup, ListGroupItem, Table } from 'reactstrap'
import CardBody from 'reactstrap/lib/CardBody'
import ListGroupItemHeading from 'reactstrap/lib/ListGroupItemHeading'
import ListGroupItemText from 'reactstrap/lib/ListGroupItemText'

const DeviceFieldsList = ({ list }) => {
  const { t } = useTranslation()
  if (!list) return null
  return (
    <Table borderless>
      <thead>
        <tr>
          <th>#</th>
          <th>{t('labels.value')}</th>
          <th>{t('labels.command')}</th>
          <th>{t('labels.validation')}</th>
          <th className='text-center'>{t('labels.isExternal')}</th>
        </tr>
      </thead>
      <tbody>
        {list.map((value, index) => {
          const fieldData = JSON.parse(value)
          const key = Object.keys(fieldData)
            .shift()
          return (
            <tr key={key}>
              <th scope="row">{key}</th>
              <td>{fieldData[key].value}</td>
              <td>{fieldData[key].command}</td>
              <td>{fieldData[key].validation}</td>
              <td className='text-center text-primary'>{
                fieldData[key].isExternal
                  ? <FontAwesomeIcon icon={faCheckCircleSolid}/>
                  : <FontAwesomeIcon size='1x' icon={faCheckCircleRegular}/>
              }
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

const DeviceInfo = ({ data, id }) => {
  const { t } = useTranslation()

  if (!data) return null

  const { device } = data

  return (
    <ListGroup>
      <ListGroupItem active>
        <ListGroupItemHeading>{t('labels.serialNumber')}</ListGroupItemHeading>
        <ListGroupItemText>{device.serialNumber}</ListGroupItemText>
      </ListGroupItem>
      <ListGroupItem>
        <ListGroupItemHeading>{t('labels.company.name')}</ListGroupItemHeading>
        <ListGroupItemText>{device.company.name}</ListGroupItemText>
      </ListGroupItem>
      <ListGroupItem>
        <ListGroupItemHeading>{t('labels.storage.name')}</ListGroupItemHeading>
        <ListGroupItemText>{device.currentStorage.name}</ListGroupItemText>
      </ListGroupItem>
      <ListGroupItem>
        <ListGroupItemHeading>{t('labels.devices.builderId')}</ListGroupItemHeading>
        <ListGroupItemText>{device.builderId}</ListGroupItemText>
      </ListGroupItem>
      <ListGroupItem>
        <ListGroupItemHeading>{t('labels.devices.building_date')}</ListGroupItemHeading>
        <ListGroupItemText>{device.buildingDate}</ListGroupItemText>
      </ListGroupItem>
      <ListGroupItem>
        <ListGroupItemHeading>{t('labels.deviceType')}</ListGroupItemHeading>
        <div className='d-flex flex-row justify-content-between align-items-center'>
          <span>{device.deviceType.sku}</span>
          <span className='text-muted'>{device.deviceType.descriptionShort}</span>
        </div>
      </ListGroupItem>
      <ListGroupItem>
        <ListGroupItemHeading>{t('labels.deviceFields')}</ListGroupItemHeading>
        <DeviceFieldsList list={device.deviceFields}/>
      </ListGroupItem>
    </ListGroup>
  )
}

const DeviceView = withCurrentUser(() => {
  const { t } = useTranslation()
  const { id } = useParams()
  const deviceQuery = useQuery(DEVICE, {
    variables: {
      id
    }
  })

  return (
    <>
      <BreadcrumbCommon
        customPath={'/devices/manage'}
        customLinkText={t('common.page.admin.devices_list')}/>
      <BasePage title={`${t('labels.deviceInfo')}: ${id}`}>
        <CardBody className='d-flex justify-content-start flex-column'>
          <HandlerComponent loading={deviceQuery.loading} error={deviceQuery.error}/>
          <DeviceInfo data={deviceQuery.data} id={id}/>
        </CardBody>
      </BasePage>
    </>
  )
})

export default DeviceView
