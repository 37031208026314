import { useMutation } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import styled from 'styled-components'
import ResetForm from './ResetForm'

const resetPasswordConfirmMutation = loader('gql/schema/auth/resetPasswordConfirm.graphql')

const ResetContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #20a8d8;
  
  .card {
    margin: 10vh 0 0;
    min-width: 40vw;
    min-height: 20vh;
    flex-direction: column;
    justify-content: center;
  }
`

const ResetPassword = ({ match: { params }, history }) => {
  const [resetPasswordConfirm] = useMutation(resetPasswordConfirmMutation, {
    onCompleted ({ resetPasswordConfirm: { status } }) {
      if (status === 'ok') history.push('/')
    }
  })

  const onSubmit = (values) => {
    return resetPasswordConfirm({ variables: { ...values } })
  }

  return (
    <ResetContainer>
      <Card className='p-4'>
        <CardBody>
          <ResetForm
            onSubmit={onSubmit}
            initialValues={{
              token: params.token,
              password: '',
              confirmPassword: ''
            }}
          />
        </CardBody>
      </Card>
    </ResetContainer>
  )
}

export default withRouter(ResetPassword)
