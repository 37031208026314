import { Field } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Col, Form, FormGroup, Row } from 'reactstrap'
import { composeValidators, required } from '../../../libs/forms/validators'
import { formikForm, TextInput } from '../../components/forms'
import Heading from '../../components/Heading'

const ResetForm = (
  {
    handleSubmit,
    isSubmitting,
    isValid,
    error,
    ...rest
  }
) => {
  const { t } = useTranslation()

  return (
    <Form className='d-flex flex-column justify-content-between' onSubmit={handleSubmit}>
      <Heading size={3}>{t('auth.reset_password_text')}</Heading>
      {error && <Alert color='danger'>{error.message}</Alert>}

      <Row className='mt-2'>
        <Col>
          <Field
            name='password'
            id='password'
            type='password'
            label={t('labels.new_password')}
            labelClasses='text-muted'
            component={TextInput}
            validate={composeValidators(required)}
          />
          <Field
            name='confirmPassword'
            id='confirmPassword'
            type='password'
            label={t('labels.password_confirm')}
            labelClasses='text-muted'
            component={TextInput}
            validate={composeValidators(required)}
          />

          <FormGroup className='d-flex align-items-center justify-content-between m-0'>
            <Button type='submit' color='primary' disabled={isSubmitting || !isValid}>
              {t('labels.reset_password')}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  )
}

export default formikForm(ResetForm)
