import { useMutation } from '@apollo/react-hooks'
import { faGlobe, faSignOutAlt, faUserEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChangeLocaleAlert } from 'app/components/alerts/ChangeLocaleAlert'
import { API_HOST } from 'config'
import { CHANGE_USER_LOCALIZATION } from 'gql/schema/user/mutations'
import withCurrentUser from 'hoc/withCurrentUser'
import withLocalization from 'hoc/withLocalization'
import UsersType from 'models/UsersType'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink as NavLinkRouter } from 'react-router-dom'
import { Badge, Button, NavItem, NavLink } from 'reactstrap'

const BaseHeader = ({ user: { name, surname, _id, role, company }, localization }) => {
  const { t } = useTranslation()
  const isAdmin = role === UsersType.ADMIN || role === UsersType.COMPANY_ADMIN
  const [changeLocaleMutation] = useMutation(CHANGE_USER_LOCALIZATION, {
    onCompleted ({ changeUserLocalization }) {
      if (changeUserLocalization && changeUserLocalization.status === 'ok') {
        localStorage.setItem('localization', changeUserLocalization.data)
        window.location.reload()
      }
    }
  })

  const changeLang = useCallback((locale) => {
    ChangeLocaleAlert(t, locale, async (value) => {
      if (changeLocaleMutation) {
        await changeLocaleMutation({
          variables: {
            localization: value
          }
        })
      }
    })
  }, [changeLocaleMutation, t])

  return (
    <header className='app-header navbar pl-2 pr-2'>
      {/* TOGGLE MD BUTTON */}
      <button className='navbar-toggler sidebar-toggler d-lg-none mr-auto' type='button' data-toggle='sidebar-show'>
        <span className='navbar-toggler-icon'/>
      </button>
      <div className='navbar-brand'>
        <div className='navbar-brand-full'>{t('common.app_name')}</div>
        <div className='navbar-brand-minimized'>{t('common.app_name')}</div>
      </div>
      {/* TOGGLE LG BUTTON */}
      <Button className='navbar-toggler sidebar-toggler d-md-down-none' type='button' data-toggle='sidebar-lg-show'>
        <span className='navbar-toggler-icon'/>
      </Button>
      <ul className='nav navbar-nav mr-auto'>
        <NavItem>
          {company && <div>
            <img src={`${API_HOST}/${company.companyLogo}?rnd=${Math.random()}`} alt="logo" style={{
              maxWidth: '155px',
              maxHeight: '45px'
            }}/>
          </div>}
        </NavItem>
      </ul>
      {/* BUTTONS MENU */}
      <ul className='nav navbar-nav ml-auto justify-content-start'>
        <NavItem>
          <NavLink to='' tag={NavLinkRouter} onClick={() => changeLang(localization)}>
            <FontAwesomeIcon icon={faGlobe} className='info-color'/>
            <Badge color="primary" pill>{`${localization}`}</Badge>
          </NavLink>
        </NavItem>
        {isAdmin && <NavItem>
          <NavLink to={`/user/edit/${_id}`} tag={NavLinkRouter}>
            <FontAwesomeIcon icon={faUserEdit} className='info-color'/>
          </NavLink>
        </NavItem>}
        <NavItem>
          <NavLink to='/logout' tag={NavLinkRouter}>
            <FontAwesomeIcon icon={faSignOutAlt} className='danger-color'/>
          </NavLink>
        </NavItem>

      </ul>
    </header>
  )
}

export default withLocalization(withCurrentUser(BaseHeader))
