import gql from 'graphql-tag'

export const CREATE_COMPANY = gql`
    mutation createCompany(
        $name: String!, 
        $defaultCompany:Boolean, 
        $address: AddressInputType, 
        $email: String!, 
        $phone: String!, 
        $contactName: String!, 
        $companyAdmins: [ID],
        $companyLogo: Upload
    ) {
        createCompany(
            name: $name, 
            defaultCompany: $defaultCompany, 
            address: $address, 
            email: $email, 
            phone: $phone, 
            contactName: $contactName, 
            companyAdmins: $companyAdmins,
            companyLogo: $companyLogo
        ) {
            status
            data
        }
    }
`

export const UPDATE_COMPANY = gql`
    mutation updateCompany(
        $_id: ID!, 
        $name: String, 
        $address: AddressInputType, 
        $email: String, 
        $phone: String, 
        $contactName: String, 
        $companyAdmins: [ID],
        $companyLogo: Upload
    ) {
        updateCompany(
            _id: $_id, 
            name: $name, 
            address: $address, 
            email: $email, 
            phone: $phone, 
            contactName: $contactName, 
            companyAdmins: $companyAdmins,
            companyLogo: $companyLogo
        ) {
            status
            data
        }
    }
`
