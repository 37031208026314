import { useMutation, useQuery } from '@apollo/react-hooks'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DeleteAlert } from 'app/components/alerts'
import BreadcrumbCommon from 'app/components/common/BreadcrumbCommon'
import DeviceFirmwareTable from 'app/components/common/DeviceFirmwareTable'
import HandlerComponent from 'app/components/common/HandlerComponent'
import BasePage from 'app/components/layout/BasePage'
import FirmwareForm from 'app/pages/devices/forms/FirmwareForm'
import { DELETE_FIRMWARE, UPLOAD_FIRMWARE } from 'gql/schema/devices/mutations'
import { DEVICE_TYPE_FIRMWARE, DEVICE_TYPES_LIST } from 'gql/schema/devices/queries'
import withCurrentUser from 'hoc/withCurrentUser'
import Firmware from 'models/firmware'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Alert, Button, Card, Container, Spinner } from 'reactstrap'
import CardBody from 'reactstrap/lib/CardBody'

const UPLOAD_FORM_STATUS = {
  opened: false,
  uploading: false,
  error: null
}

const refetchQueries = (id) => [
  {
    query: DEVICE_TYPE_FIRMWARE,
    variables: { id }
  },
  {
    query: DEVICE_TYPES_LIST
  }
]

const DeviceTypeFirmwareList = withCurrentUser(() => {
  const { t } = useTranslation()
  const { id } = useParams()

  const [uploadFormStatus, setUploadFormStatus] = useState(UPLOAD_FORM_STATUS)

  const { data, loading, error } = useQuery(DEVICE_TYPE_FIRMWARE, { variables: { id } })

  const [firmwareCreateMutation] = useMutation(UPLOAD_FIRMWARE, {
    onCompleted ({ uploadFirmware }) {
      setUploadFormStatus(UPLOAD_FORM_STATUS)
    },
    onError (error) {
      setUploadFormStatus({
        ...UPLOAD_FORM_STATUS,
        error: error.message
      })
    },
    fetchPolicy: 'no-cache',
    refetchQueries: refetchQueries(id)
  })

  const handleFirmwareSubmit = useCallback(async (value, formActions) => {
    if (firmwareCreateMutation) {
      formActions.resetForm()

      setUploadFormStatus({
        ...uploadFormStatus,
        uploading: true
      })

      await firmwareCreateMutation(
        {
          variables: {
            ...value,
            deviceTypeId: id
          }
        }
      )
    }
  }, [id, firmwareCreateMutation, setUploadFormStatus, uploadFormStatus])

  const [firmwareDeleteMutation] = useMutation(DELETE_FIRMWARE, {
    onError (error) {
      setUploadFormStatus({
        ...UPLOAD_FORM_STATUS,
        error: error.message
      })
    },
    refetchQueries: refetchQueries(id)
  })

  const handleFirmwareDelete = useCallback((id) => {
    if (firmwareDeleteMutation) {
      DeleteAlert(t)
        .then((actionConfirmed) => {
          actionConfirmed && firmwareDeleteMutation({ variables: { id } })
        })
    }
  }, [firmwareDeleteMutation, t])

  return (
    <>
      <BreadcrumbCommon
        customPath={'/device/types'}
        customLinkText={t('common.page.admin.device_types')}>
        {/* ADD NEW USER BUTTON */}
        <li className='breadcrumb-menu'>
          <div className='btn-group' role='group'>
            <Button className='btn-ghost-danger' onClick={() => setUploadFormStatus({
              ...uploadFormStatus,
              opened: !uploadFormStatus.opened
            })}>
              <FontAwesomeIcon className='nav-icon' icon={faPlus}/>
              <span className='ml-1'>{t('labels.create')}</span>
            </Button>
          </div>
        </li>
      </BreadcrumbCommon>
      <Container>
        <Alert color="danger" isOpen={!!uploadFormStatus.error} toggle={() => setUploadFormStatus({
          ...uploadFormStatus,
          error: null
        })}>
          {uploadFormStatus.error}
        </Alert>
        <Alert color="light" isOpen={uploadFormStatus.uploading} className='text-center'>
          <Spinner color="primary"/>
        </Alert>
      </Container>
      {/* Firmware Form */}
      {uploadFormStatus.opened && <Container>
        <Card>
          <CardBody className='justify-content-center'>
            <FirmwareForm
              initialValues={{ ...Firmware.initialData }}
              onSubmit={handleFirmwareSubmit}
              enableReinitialize/>
          </CardBody>
        </Card>
      </Container>}
      <BasePage title={t('common.page.admin.device_types_firmware')}>
        <CardBody>
          <HandlerComponent loading={loading} error={error}/>
          {data && <DeviceFirmwareTable
            list={data.deviceTypeFirmwareList}
            onDelete={handleFirmwareDelete}
            onDownload={(path) => {
              window.open(path, '_blank')
            }}
          />}
        </CardBody>
      </BasePage>
    </>
  )
})

export default DeviceTypeFirmwareList
