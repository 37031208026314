import { formikForm } from 'app/components/forms'
import FormInput from 'app/components/forms/FormInput'
import FormInputCheckbox from 'app/components/forms/FormInputCheckbox'
import { Field } from 'formik'
import { composeValidators, required, version } from 'libs/forms/validators'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Form, FormGroup, Label } from 'reactstrap'
import Button from 'reactstrap/lib/Button'
import Col from 'reactstrap/lib/Col'
import Row from 'reactstrap/lib/Row'

const FirmwareForm = formikForm((
  { isSubmitting, isValid, handleSubmit, error, setFieldValue, values, ...rest }
) => {
  const { t } = useTranslation()

  return (
    <Form className='d-flex flex-column justify-content-between' onSubmit={handleSubmit}>
      {error && <Alert color='danger'>{error.message}</Alert>}
      <Row form>
        <Col>
          <Field
            name='version'
            label={t('labels.version')}
            labelClasses='text-muted'
            component={FormInput}
            type='text'
            validate={composeValidators(required, version)}
            placeholder={'0.0.0'}
          />
        </Col>
        <Col>
          <Field
            name='descriptionShort'
            label={t('labels.descriptionShort')}
            labelClasses='text-muted'
            component={FormInput}
            type='text'
            validate={required}
          />
        </Col>
      </Row>
      <Row form>
        <Col>
          <Field
            name='descriptionLong'
            label={t('labels.descriptionLong')}
            labelClasses='text-muted'
            component={FormInput}
            type='textarea'
          />
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <Label className={'text-muted mr-1'}>{t('labels.fileSelect')}</Label>
            <input required name="file" type="file" className='w-100' onChange={(event) => {
              setFieldValue('file', event.currentTarget.files[0])
            }}/>
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <Field
            name='isDefault'
            label={t('labels.default')}
            labelClasses='text-muted'
            component={FormInputCheckbox}
            type='checkbox'
          />
        </Col>
      </Row>
      <FormGroup className='d-flex align-items-center justify-content-between mt-3'>
        <Button type='submit' color='danger' disabled={isSubmitting || !isValid}>
          {t('labels.create')}
        </Button>
      </FormGroup>
    </Form>
  )
})

export default FirmwareForm
