import cx from 'classnames'
import React from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'

const styles = {
  multiValue: (base, state) => {
    return state.data.isFixed ? {
      ...base,
      backgroundColor: 'gray'
    } : base
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? {
        ...base,
        fontWeight: 'bold',
        color: 'white',
        paddingRight: 6
      }
      : base
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? {
      ...base,
      display: 'none'
    } : base
  }
}

// eslint-disable-next-line react/display-name
export default (
  { async, value, isMulti, isClearable, ...props }
) => {
  const { disabled, invalid } = props

  const C = async ? AsyncSelect : Select

  return (
    <C
      value={value}
      styles={styles}
      {...props}
      isMulti={isMulti}
      className={cx('react-select-container', { 'react-select-invalid': invalid })}
      classNamePrefix='react-select'
      isDisabled={disabled}
      isClearable={(isMulti && value && value.length) ? value.some(v => !v.isFixed) : isClearable}
    />
  )
}
