import composeValidators from './composeValidators'

export { composeValidators }

export const required = (value) => {
  return value || typeof value === 'number' ? undefined : 'Required'
}

export const maxLength = (max) => {
  return (value) => {
    return value && value.toString().length > max ? `Max length: ${max}` : undefined
  }
}

export const inRange = (min, max) => {
  return (value) => {
    if (value && value.toString().length > max) {
      return `Max length: ${max}`
    }
    if (value && value.toString().length < min) {
      return `Min length: ${min}`
    }

    return undefined
  }
}

export const email = (value) => {
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined
}

export const phone = (value) => {
  return value && !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/i.test(value)
    ? 'Invalid phone number'
    : undefined
}

export const match = (_match) => (value) => (value ? value === _match : undefined)

const SNAKE_CASE_REGEX = /^([a-z]{1,})(_[a-z0-9]{1,})*$/
export const apnName = (value) => {
  return value && !SNAKE_CASE_REGEX.test(value)
    ? 'Invalid apn name. Must to be in snake case'
    : undefined
}

export const version = (value) => {
  return value && !/^(\d+\.)(\d+\.)(\*|\d+)$/.test(value)
    ? 'Invalid version number'
    : undefined
}
