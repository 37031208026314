import { useApolloClient, useMutation } from '@apollo/react-hooks'
import { AuthQuery, SignIn } from 'gql/schema'
import { ME_QUERY } from 'gql/schema/user/queries'
import i18n from 'libs/i18n'
import localStorage from 'libs/localStorage'
import React, { useState } from 'react'
import styled from 'styled-components'

import SignInForm from './SignInForm'

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #20a8d8;
  
  .MuiIcon-root {
    font-size: 1.3rem;
  }
  
  .card {
    margin: 10vh 0 0;
    min-width: 40vw;
    min-height: 40vh;
    flex-direction: column;
    justify-content: center;
  }
`

const AppNameContainer = styled.div`
  text-transform: uppercase;
  background: #63c2de;
  width: 100vw;
  text-align: center;
  user-select: none;
`

const FORM_INITIAL_VALUES = {
  email: '',
  password: ''
}

const LoginPage = (props) => {
  const client = useApolloClient()
  const [error, setError] = useState(null)
  const [initialValues, setInitialValues] = useState(FORM_INITIAL_VALUES)

  const [signIn] = useMutation(SignIn, {
    onCompleted ({ signIn: { token, localization } }) {
      localStorage.setItem('token', token)
      localStorage.setItem('localization', localization)
    }
  })

  const onSubmit = async (values, actions) => {
    try {
      await signIn({ variables: values })
      const { data: { me } } = await client.query({ query: ME_QUERY })

      localStorage.setItem('user', JSON.stringify(me))

      client.cache.writeQuery({
        query: AuthQuery,
        data: {
          user: me,
          isAuthenticated: true
        }
      })

      props.history.push(me.role === 'admin' ? '/admin' : '/')
    } catch (e) {
      setError(e.message)
      setInitialValues(FORM_INITIAL_VALUES)
      actions.setSubmitting(false)
    }
  }

  return (
    <LoginContainer>
      <AppNameContainer className='display-4'>
        {i18n.t('common.app_name')}
      </AppNameContainer>
      <SignInForm
        initialValues={initialValues}
        lastError={error}
        onSubmit={onSubmit}
      />
    </LoginContainer>
  )
}

export default LoginPage
