import swal from '@sweetalert/with-react'
import { AVAILABLE_LOCALIZATIONS } from 'models/localizations'
import React from 'react'
import { Button } from 'reactstrap'

const LocaleButton = ({ title, value, disabled, onClick }) => (
  <Button disabled={disabled} color="primary" outline onClick={() => {
    onClick && onClick(value)
    swal.close()
  }} className='m-2'>{title}</Button>
)

export const ChangeLocaleAlert = (t, locale, onClick) => {
  return swal({
    icon: 'info',
    title: t('dialog.change_locale_title'),
    buttons: {
      cancel: t('dialog.cancel_text')
    },
    content: (
      <div className='d-flex flex-row flex-nowrap align-items-center justify-content-center'>
        {AVAILABLE_LOCALIZATIONS.map(value => <LocaleButton
          key={value}
          title={t(`labels.locale.${value}`)}
          value={value}
          disabled={locale === value}
          onClick={onClick}
        />)}
      </div>
    )
  })
}
