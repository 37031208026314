import { useQuery } from '@apollo/react-hooks'
import { formikForm, TextInput } from 'app/components/forms'
import ImageField from 'app/components/forms/ImageField'
import SelectField from 'app/components/forms/SelectField'
import image from 'assets/images/no-image.png'
import { Field } from 'formik'
import { COMPANY_ADMINS } from 'gql/schema/user/queries'
import withCurrentUser from 'hoc/withCurrentUser'
import { composeValidators, email, inRange, phone, required } from 'libs/forms/validators'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Col, Form, FormGroup, Row } from 'reactstrap'
import Card from 'reactstrap/lib/Card'
import CardBody from 'reactstrap/lib/CardBody'
import CardHeader from 'reactstrap/lib/CardHeader'

const CompanyForm = formikForm(withCurrentUser(({
  isSubmitting, isValid, handleSubmit, error, user, values, setFieldValue, dirty, editState = false, ...rest
}) => {
  const { t } = useTranslation()
  const [companyImage, setCompanyImage] = useState(values && !!values.companyLogo ? values.companyLogo : image)

  const { loading, data } = useQuery(COMPANY_ADMINS)
  const [selectOptions, setSelectOptions] = useState([])

  useEffect(() => {
    if (!loading && data) {
      const { companyAdmins } = data
      setSelectOptions(
        companyAdmins
          .filter(({ companyId }) => !companyId)
          .map(({ _id, name, surname }) => {
            return {
              value: _id,
              label: `${name} ${surname}`
            }
          })
      )
    }
  }, [loading, data])

  return (
    <Form
      className='d-flex flex-column justify-content-between'
      onSubmit={handleSubmit}
    >
      {error && <Alert color='danger'>{error.message}</Alert>}
      <Row form>
        <Col>
          <Card>
            <CardBody>
              <Field
                name='name'
                type='text'
                label={t('labels.company.name')}
                labelClasses='text-muted'
                component={TextInput}
                validate={required}
              />
              <Row form>
                <Col md={6}>
                  <Field
                    name='email'
                    type='email'
                    label={t('labels.company.email')}
                    labelClasses='text-muted'
                    component={TextInput}
                    validate={composeValidators(email, required)}
                  />
                </Col>
                <Col md={6}>
                  <Field
                    name='phone'
                    type='text'
                    label={t('labels.company.phone')}
                    labelClasses='text-muted'
                    component={TextInput}
                    validate={composeValidators(phone, required)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    name='contactName'
                    type='text'
                    label={t('labels.company.contact_name')}
                    labelClasses='text-muted'
                    component={TextInput}
                    validate={required}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody>
              <ImageField src={companyImage} onChange={(event) => {
                setFieldValue('companyLogo', event.currentTarget.files[0])
                setCompanyImage(event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : image)
              }}/>
            </CardBody>
          </Card>
          {values.companyAdmins && <Card>
            <CardHeader>{t('labels.company.admins')}</CardHeader>
            <CardBody>
              <Row form>
                <Col>
                  <Field
                    name='companyAdmins'
                    labelClasses='text-muted'
                    options={selectOptions || []}
                    component={SelectField}
                    isMulti
                    isLoading={loading}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>}
        </Col>
      </Row>
      <Row form>
        <Col>
          <Card>
            <CardHeader>{t('labels.company.address')}</CardHeader>
            <CardBody>
              <Row form>
                <Col>
                  <Field
                    name='address.country'
                    autoComplete='country'
                    type='text'
                    label={t('labels.country')}
                    labelClasses='text-muted'
                    component={TextInput}
                    validate={required}
                  />
                </Col>
                <Col>
                  <Field
                    name='address.region'
                    autoComplete='region'
                    type='text'
                    label={t('labels.region')}
                    labelClasses='text-muted'
                    component={TextInput}
                    validate={required}
                  />
                </Col>
              </Row>
              <Row form>
                <Col>
                  <Field
                    name='address.province'
                    autoComplete='province'
                    type='text'
                    label={t('labels.province')}
                    labelClasses='text-muted'
                    component={TextInput}
                    validate={required}
                  />
                </Col>
                <Col>
                  <Field
                    name='address.zip'
                    autoComplete='zip'
                    type='number'
                    label={t('labels.zip')}
                    labelClasses='text-muted'
                    component={TextInput}
                    validate={composeValidators(required, inRange(4, 10))}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    name='address.address'
                    autoComplete='address'
                    type='textarea'
                    label={t('labels.address')}
                    labelClasses='text-muted'
                    component={TextInput}
                    validate={required}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <FormGroup className='d-flex align-items-center justify-content-between m-0'>
        <Button type='submit' color='primary' disabled={isSubmitting || !isValid || !dirty}>
          {editState ? t('labels.update') : t('labels.create')}
        </Button>
      </FormGroup>
    </Form>
  )
}))

export default CompanyForm
