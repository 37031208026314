import { formikForm } from 'app/components/forms'
import TextInput from 'app/components/forms/TextInput'
import { Field } from 'formik'
import composeValidators from 'libs/forms/composeValidators'
import { required } from 'libs/forms/validators'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Card, Col, Form, FormGroup, Row } from 'reactstrap'
import CardBody from 'reactstrap/lib/CardBody'
import CardFooter from 'reactstrap/lib/CardFooter'
import CardHeader from 'reactstrap/lib/CardHeader'

const ChangePasswordForm = (
  {
    handleSubmit,
    isSubmitting,
    isValid,
    error,
    ...rest
  }
) => {
  const { t } = useTranslation()

  return (
    <Form className='d-flex flex-column justify-content-between' onSubmit={handleSubmit}>

      {error && <Alert color='danger'>{error.message}</Alert>}
      <Card className='card-accent-danger'>
        <CardHeader>
          {t('labels.change_password')}
        </CardHeader>
        <CardBody>
          <Row className='mt-2'>
            <Col>
              <Field
                name='userName'
                id='userName'
                type='text'
                label={t('labels.account_name')}
                labelClasses='text-muted'
                component={TextInput}
                autoComplete='current-user-name'
                hidden
              />
              <Field
                name='oldPassword'
                id='oldPassword'
                type='password'
                label={t('labels.old_password')}
                labelClasses='text-muted'
                component={TextInput}
                validate={composeValidators(required)}
                autoComplete='current-password'
              />
              <Field
                name='password'
                id='password'
                type='password'
                label={t('labels.new_password')}
                labelClasses='text-muted'
                component={TextInput}
                validate={composeValidators(required)}
                autoComplete='new-password'
              />
              <Field
                name='confirmPassword'
                id='confirmPassword'
                type='password'
                label={t('labels.password_confirm')}
                labelClasses='text-muted'
                component={TextInput}
                validate={composeValidators(required)}
                autoComplete='new-password-confirm'
              />
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <FormGroup className='d-flex align-items-center justify-content-between m-0'>
            <Button type='submit' color='danger' disabled={isSubmitting || !isValid}>
              {t('labels.change')}
            </Button>
          </FormGroup>
        </CardFooter>
      </Card>
    </Form>
  )
}

ChangePasswordForm.propTypes = {
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  error: PropTypes.object
}

export default formikForm(ChangePasswordForm)
