import gql from 'graphql-tag'

export const ORDER_CREATE = gql`
    mutation ($companyId:ID, $customerId:ID, $order:[ID], $deliveryStorageId:ID, $deliveryInformation:String){
        orderCreate(
            companyId: $companyId,
            customerId: $customerId,
            order: $order,
            deliveryStorageId: $deliveryStorageId,
            deliveryInformation: $deliveryInformation
        ){
            status
        }

    }
`

export const ORDER_UPDATE = gql`
    mutation ($id:ID!, $status:String!, $trackingNumber:String!, $deliveryStorageId:ID, $deliveryInformation:String){
        orderUpdate(
            id:$id,
            status: $status,
            trackingNumber: $trackingNumber,
            deliveryStorageId: $deliveryStorageId,
            deliveryInformation: $deliveryInformation
        ){
            status
        }

    }
`

export const ORDER_STATUS_UPDATE = gql`
    mutation ($id:ID!, $status:String!){
        orderStatusUpdate(
            id:$id,
            status:$status
        ){
            status
        }

    }
`

export const ORDER_DELETE = gql`
    mutation ($id:ID!){
        orderDelete(
            id:$id
        ){
            status
        }

    }
`
