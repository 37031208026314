import { useMutation } from '@apollo/react-hooks'
import { faTruckLoading } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TextInput } from 'app/components/forms'
import OrderFormCompany from 'app/pages/orders/components/OrderFormCompany'
import OrderFormCustomer from 'app/pages/orders/components/OrderFormCustomer'
import { Field, Formik } from 'formik'
import { ORDER_CREATE } from 'gql/schema/orders/mutations'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Col, Form, FormGroup, Row } from 'reactstrap'
import Card from 'reactstrap/lib/Card'
import CardBody from 'reactstrap/lib/CardBody'
import CardFooter from 'reactstrap/lib/CardFooter'

const ORDER_INITIAL_VALUES = {
  companyId: null,
  customerId: null,
  companyStore: null,
  deliveryStorageId: null,
  order: null,
  deliveryInformation: ''
}

const OrderCreateForm = () => {
  const { t } = useTranslation()
  /* create order mutation */
  const [createOrder] = useMutation(ORDER_CREATE, {
    onCompleted: ({ orderCreate: { status } }) => {
      status === 'ok' && window.location.reload()
    }
  })
  const orderSubmit = useCallback(async (values) => {
    if (createOrder) {
      const companyId = values.companyId.value
      const customerId = values.customerId.value
      const deliveryStorageId = values.deliveryStorageId.value
      const order = values.order.map(v => v.value)
      const deliveryInformation = values.deliveryInformation

      await createOrder({
        variables: {
          companyId,
          customerId,
          order,
          deliveryStorageId,
          deliveryInformation
        }
      })
    }
  }, [createOrder])

  /* RENDER COMPONENT */
  return (
    <Card>
      <Formik
        enableReinitialize
        onSubmit={orderSubmit}
        initialValues={ORDER_INITIAL_VALUES}>
        {({
          error,
          setFieldValue,
          isSubmitting,
          isValid,
          handleSubmit
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <CardBody>
                {error && <Alert color='danger'>{error.message}</Alert>}
                <Row>
                  <Col md={5}>
                    <OrderFormCompany setFieldValue={setFieldValue} />
                  </Col>
                  <Col md={2} className='d-flex flex-column justify-content-center align-items-center'>
                    <FontAwesomeIcon size='3x' icon={faTruckLoading} />
                  </Col>
                  <Col md={5}>
                    <OrderFormCustomer setFieldValue={setFieldValue} />
                  </Col>
                </Row>
                {/* DELIVERY INFORMATION */}
                <Row>
                  <Col md={12}>
                    <Field
                      name='deliveryInformation'
                      autoComplete='deliveryInformation'
                      type='textarea'
                      label={t('labels.additionalInformation')}
                      labelClasses='text-muted'
                      component={TextInput}
                    />
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <FormGroup className='d-flex align-items-center justify-content-between m-0'>
                  <Button type='submit' color='primary' disabled={isSubmitting || !isValid}>
                    {t('labels.create')}
                  </Button>
                </FormGroup>
              </CardFooter>
            </Form>
          )
        }}
      </Formik>

    </Card>
  )
}

export {
  ORDER_INITIAL_VALUES
}
export default OrderCreateForm
