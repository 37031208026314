import { useQuery } from '@apollo/react-hooks'
import HandlerComponent from 'app/components/common/HandlerComponent'
import { formikForm, TextInput } from 'app/components/forms'
import SelectField from 'app/components/forms/SelectField'
import { Field } from 'formik'
import { DEVICE_TYPES_LIST } from 'gql/schema/devices/queries'
import useFetchCompanies from 'hooks/gql/useFetchCompanies'
import useFetchStorageList from 'hooks/gql/useFetchStorageList'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Fade, Form, FormGroup, Row } from 'reactstrap'
import Card from 'reactstrap/lib/Card'
import Col from 'reactstrap/lib/Col'
import Container from 'reactstrap/lib/Container'

export const FILTERS = {
  companyId: null,
  currentStorageId: null,
  serialNumber: null,
  deviceFields: null
}

const DeviceFiltersForm = formikForm(({
  isVisible,
  handleSubmit,
  handleReset,
  /* formik props */
  dirty,
  resetForm
}) => {
  const { t } = useTranslation()
  /* GETTING LIST OF COMPANIES */
  const companiesQuery = useFetchCompanies()
  const [companies, setCompanies] = useState([])
  /* GETTING LIST OF STORAGE */
  const storagesQuery = useFetchStorageList()
  const [storages, setStorages] = useState([])
  /* GETTING LIST OF DEVICE TYPES */
  const deviceTypesQuery = useQuery(DEVICE_TYPES_LIST)
  const [deviceTypes, setDeviceTypes] = useState([])

  /* companies */
  useEffect(() => {
    if (companiesQuery.data) {
      const { companiesList } = companiesQuery.data
      setCompanies(companiesList.map(({ _id, name }) => {
        return {
          value: _id,
          label: `${name}`
        }
      }))
    }
  }, [companiesQuery.data])

  /* storages */
  useEffect(() => {
    if (storagesQuery.data) {
      const { storageList } = storagesQuery.data
      setStorages(storageList.map(({ _id, name }) => {
        return {
          value: _id,
          label: `${name}`
        }
      }))
    }
  }, [storagesQuery.data])

  /* device types */
  useEffect(() => {
    if (deviceTypesQuery.data) {
      const { deviceTypesList } = deviceTypesQuery.data
      setDeviceTypes(deviceTypesList.map(({ _id, sku }) => {
        return {
          value: _id,
          label: `${sku}`
        }
      }))
    }
  }, [deviceTypesQuery.data])

  return (<Container>
    {isVisible && <Card>
      <HandlerComponent loading={storagesQuery.loading || companiesQuery.loading} error={storagesQuery.error || companiesQuery.error}/>
      <Fade in={isVisible} tag={Container} className='mt-3'>
        <Form className='d-flex flex-column justify-content-between' onSubmit={handleSubmit} onReset={handleReset}>
          <Row form>
            {/* COMPANIES */}
            <Col>
              <Field
                name='companyId'
                label={t('labels.company.name')}
                labelClasses='text-muted'
                options={companies}
                component={SelectField}
                isLoading={companiesQuery.loading}
                changeHandler={(selectedCompany) => {
                  if (storagesQuery.data) {
                    const { storageList } = storagesQuery.data
                    const storagesOptions = storageList.map(({ _id, name }) => {
                      return {
                        value: _id,
                        label: `${name}`
                      }
                    })

                    const companyStorages = selectedCompany ? storageList.filter(storage => storage.companyId === selectedCompany.value)
                      .map(({ _id, name }) => {
                        return {
                          value: _id,
                          label: `${name}`
                        }
                      }) : []

                    setStorages(selectedCompany ? companyStorages : storagesOptions)
                  }
                }}
                isMulti={false}
                isClearable
              />
            </Col>
            {/* STORAGES */}
            <Col>
              <Field
                name='currentStorageId'
                label={t('labels.storage.name')}
                labelClasses='text-muted'
                options={storages}
                component={SelectField}
                isMulti={false}
                isClearable
                isLoading={storagesQuery.loading}
              />
            </Col>
            {/* DEVICE TYPES */}
            <Col>
              <Field
                name='deviceType'
                label={t('labels.deviceType')}
                labelClasses='text-muted'
                options={deviceTypes}
                component={SelectField}
                isMulti={false}
                isClearable
                isLoading={deviceTypesQuery.loading}
              />
            </Col>
          </Row>
          <Row form>
            <Col>
              <Field
                name='serialNumber'
                label={t('labels.serialNumber')}
                labelClasses='text-muted'
                component={TextInput}
                type='text'
              />
            </Col>
          </Row>
          <Row form>
            <Col>
              <Field
                name='deviceFields'
                label={t('labels.deviceFields')}
                labelClasses='text-muted'
                component={TextInput}
                type='text'
              />
            </Col>
          </Row>
          <Row form className='mb-3'>
            <Col>
              <FormGroup className='d-flex align-items-center m-0'>
                <Button type='submit' color='primary'>
                  {t('labels.filter')}
                </Button>
                <Button type='reset' color='danger' className='ml-3' disabled={!dirty}>
                  {t('labels.reset')}
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </Fade>
    </Card>}
  </Container>)
})

export default DeviceFiltersForm
