import BreadcrumbCommon from 'app/components/common/BreadcrumbCommon'
import BasePage from 'app/components/layout/BasePage'
import CreateStorageForm from 'app/pages/storage/CreateStorageForm'
import useCreateStorage from 'hooks/gql/useCreateStorage'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import CardBody from 'reactstrap/lib/CardBody'

const StorageCreate = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const [createStorage] = useCreateStorage({
    onComplete: ({ createStorage: { status } }) => {
      status === 'ok' && history.push('/storage/list')
    }
  })

  const handleSubmit = useCallback(async (values) => {
    if (createStorage) {
      await createStorage({ variables: { ...values } })
    }
  }, [createStorage])

  return (
    <>
      <BreadcrumbCommon />
      <BasePage title={t('common.page.admin.create')}>
        <CardBody>
          <CreateStorageForm
            onSubmit={handleSubmit}
            initialValues={{
              name: '',
              address: '',
              type: 'real'
            }}
          />
        </CardBody>
      </BasePage>
    </>
  )
}

export default StorageCreate
