import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { API_HOST } from 'config'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Badge, Table } from 'reactstrap'
import Button from 'reactstrap/lib/Button'

const DeviceFirmwareTable = ({ list, onDelete, onDownload }) => {
  const { t } = useTranslation()

  if (!list || !list.length) return null

  return (
    <>
      <Table borderless responsive className='table-hover'>
        <thead>
          <tr>
            <th>{t('labels.version')}</th>
            <th>{t('labels.filePath')}</th>
            <th>{t('labels.default')}</th>
            <th className='d-flex justify-content-center'>{t('labels.action')}</th>
          </tr>
        </thead>
        <tbody>
          {list.map(value => {
            const fileName = String(value.filePath)
              .split('/')
              .pop()
            const downloadPath = `${API_HOST}/${value.filePath}`

            return (
              <tr key={value._id} className='font-sm'>
                <td>{value.version}</td>
                <td>
                  <Badge color="primary">{fileName}</Badge>
                </td>
                <td>{String(value.isDefault)}</td>
                <td className='d-flex justify-content-center'>
                  <Button
                    className='btn-ghost-primary'
                    onClick={() => onDownload(downloadPath)}
                    disabled={!onDownload}>
                    <FontAwesomeIcon className='nav-icon' icon={faCloudDownloadAlt}/>
                  </Button>
                  {onDelete && (
                    <button
                      className='btn btn-ghost-danger'
                      type='button'
                      onClick={() => onDelete(value._id)}>
                      <FontAwesomeIcon icon={faTrashAlt}/>
                    </button>
                  )
                  }
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

export default DeviceFirmwareTable
