export default {
  common: {
    app_name: 'Provisioning tool V2',
    page: {
      admin: {
        dashboard: 'Dashboard',
        users: 'Users List',
        add_new_user: 'Add New User',
        update_user: 'Edit User',
        edit_user: 'Edit User',
        companies_list: 'Companies list',
        create: 'Create',
        update: 'Update',
        edit: 'Edit',
        create_company: 'Create Company',
        edit_company: 'Edit Company',
        storage_list: 'Storage list',
        devices_list: 'Devices list',
        devices_move: 'Devices move',
        sub_company: 'Customer Company',
        device_types: 'Device Types',
        device_types_firmware: 'Firmware',
        sub_companies: 'Customers'
      }
    },
    menu: {
      dashboard: 'Dashboard',
      users: 'Users',
      users_list: 'Users List',
      company: 'Company',
      companies_list: 'Companies List',
      company_edit: 'Edit Company',
      storage: 'Storage',
      storage_list: 'Storage List',
      devices: 'Devices',
      devices_list: 'Devices List',
      devices_menage: 'Manage',
      devices_move: 'Move',
      create_sub_company: 'Create Customer',
      sub_companies: 'Customers list',
      device_types: 'Device Types'
    }
  },
  auth: {
    forgot_password: 'Forgot password?',
    not_a_member: 'Not a member?',
    already_member: 'Already has an account?',
    sign_in: 'Sign in',
    reset_password_text: 'You can reset your password here',
    activation_success: 'Your account has been activate. Now you can sign in to the app',
    password_reset_sent_text_1: 'You will receive an email with instructions about how to reset your password.',
    password_reset_sent_text_2: 'Follow them to create a new one',
    password_choose_new: 'Choose new password',
    change_password: 'Change password',
    change_password_gratz: 'Congratulations!',
    password_was_changed_text: 'Now you can login to the app using your new password'
  },
  errors: {
    validation: {
      email_invalid: 'Email is invalid',
      email_required: 'Email is required',
      password_too_short: 'Password must be at least {{length}} characters',
      password_required: 'Password is required',
      password_match_error: 'Passwords must match',
      account_name_required: 'Account name is Required'
    },
    network: {
      401: 'Unauthorized. You\'re not logged in.',
      403: 'Forbidden. You\'re not authorized to access this data.',
      500: 'Internal server error'
    },
    unknown_error: 'Something went wrong. Try again later.'
  },
  text: {
    loading: 'Loading...',
    ok: 'OK',
    cancel: 'Cancel'
  },
  labels: {
    email: 'E-mail',
    id: 'ID',
    type: 'Type',
    status: 'Status',
    account_type: 'Account type',
    account_status: 'Account status',
    account_details: 'Account Details',
    account_name: 'Account name',
    password: 'Password',
    old_password: 'Old password',
    new_password: 'New Password',
    password_confirm: 'Confirm password',
    password_confirm_new: 'Confirm your new password',
    signin: 'Sign In to your account',
    signup: 'Sign Up',
    back_to_home: 'Back to home',
    logout: 'Logout',
    authorize: 'Authorize',
    save_changes: 'Save changes',
    update: 'Update',
    edit_user: 'Edit User',
    user_details: 'User details',
    role: 'Role',
    create_user: 'CreateCompany user',
    create: 'Create',
    delete: 'Delete',
    reset_password: 'Reset your password',
    account_name_placeholder: 'Enter an Account name...',
    email_placeholder: 'Enter an E-mail...',
    user_name: 'User Name',
    user_second_name: 'User Second Name',
    company: {
      name: 'Company Name',
      address: 'Company Address',
      email: 'Email',
      phone: 'Phone',
      contact_name: 'Contact Name',
      admins: 'Company admins',
      defaultCompany: 'Default Company',
      companyLogo: 'Company Logo'
    },
    storage: {
      name: 'Storage Name',
      address: 'Address',
      type: 'Storage type'
    },
    devices: {
      sensors: 'Device Sensors',
      components: 'Device Components',
      building_date: 'Building date',
      builderId: 'Builder ID',

    },
    close: 'Close',
    change: 'Change',
    change_password: 'Change Password',
    change_user_information: 'Change User Information',
    filter: 'Filter',
    reset: 'Reset',
    move: 'Move',
    ordersList: 'Orders List',
    customer: 'Customer',
    additionalInformation: 'Additional Information',
    trackingNumber: 'Tracking Number',
    admin: 'Admin',
    company_admin: 'Company Admin',
    client_company_admin: 'Client Company Admin',
    delivery_storage: 'Delivery Storage',
    delivery_information: 'Delivery Information',
    order_status: 'Order status',
    hardwareVersion: 'Hardware version',
    serialNumber: 'Serial number',
    deviceFields: 'Device Fields',
    deviceFieldsPlaceholder: 'IMEI,RADIO,etc...',
    firmware: 'Firmware',
    firmwares: 'Firmwares list',
    sku: 'SKU',
    action: 'Action',
    descriptionShort: 'Short description',
    descriptionLong: 'Long description',
    noDescription: 'No description',
    fileSelect: 'Select file',
    version: 'Version',
    filePath: 'File path',
    default: 'Default',
    information: 'Information',
    specifications: 'Specifications',
    deviceType: 'Device Type',
    value: 'Value',
    command: 'Command',
    validation: 'Validation',
    isExternal: 'External',
    deviceInfo: 'Device',
    language: 'Profile language',
    country: 'Country',
    region: 'Region',
    province: 'Province',
    zip: 'ZIP code',
    address: 'Address',
    locale: {
      en: 'English',
      it: 'Italian'
    },
    empty: 'Empty'
  },
  dialog: {
    warning_title: 'Warning',
    delete_confirm: 'Do you really want to delete this?',
    confirm_text: 'Confirm',
    cancel_text: 'Cancel',
    change_locale_title: 'Change localization'
  }
}
