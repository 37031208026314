import { useApolloClient, useQuery } from '@apollo/react-hooks'
import SelectField from 'app/components/forms/SelectField'
import { Field } from 'formik'
import { ORDER_COMPANIES } from 'gql/schema/company/queries'
import { STORAGE_DEVICES_LIST } from 'gql/schema/devices/queries'
import { COMPANY_STORAGES } from 'gql/schema/storage/queries'
import { required } from 'libs/forms/validators'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'

const OrderFormCompany = (
  {
    setFieldValue
  }
) => {
  const { t } = useTranslation()
  const client = useApolloClient()

  /* retrieve companies list */
  const companiesQuery = useQuery(ORDER_COMPANIES)
  const companiesOptions = useMemo(() => {
    if (companiesQuery.data) {
      const { orderCompanies } = companiesQuery.data
      return orderCompanies.map(v => {
        return {
          value: v._id,
          label: v.name
        }
      })
    } else {
      return null
    }
  }, [companiesQuery.data])

  /* retrieve company storage list */
  /* customer storages list */
  const [companyStorages, setCompanyStorages] = useState([])
  const fetchStorages = useCallback(async v => {
    /* reset filed */
    setCompanyStorages([])
    setFieldValue('companyStore', null)

    const { data: { companyStorages } } = await client.query({
      query: COMPANY_STORAGES,
      variables: {
        id: v
      }
    })
    setCompanyStorages(companyStorages.map(company => {
      return {
        label: company.name,
        value: company._id,
        default: company.default
      }
    }))
  }, [setCompanyStorages, client, setFieldValue])

  /* devices list */
  const [devicesList, setDevicesList] = useState([])
  const fetchDevices = useCallback(async v => {
    /* reset filed */
    setDevicesList([])
    setFieldValue('order', [])

    const { data: { storageDevicesList } } = await client.query({
      query: STORAGE_DEVICES_LIST,
      variables: {
        id: v
      }
    })
    setDevicesList(storageDevicesList.map(device => {
      return {
        label: device.deviceUniqueId,
        value: device._id
      }
    }))
  }, [setDevicesList, client, setFieldValue])

  return (
    <>
      <Row>
        <Col md={12}>
          <Field
            name='companyId'
            label={t('labels.company.name')}
            labelClasses='text-muted'
            options={companiesOptions}
            component={SelectField}
            isMulti={false}
            validate={required}
            onChange={company => {
              setFieldValue('companyId', company)

              /* reset filed */
              setCompanyStorages([])
              setDevicesList([])
              setFieldValue('companyStore', null)
              setFieldValue('order', [])

              fetchStorages(company.value)
            }}
          />
        </Col>
        {/* <Col md={12}>
          <Field
            name='companyStore'
            label={t('labels.storage.name')}
            labelClasses='text-muted'
            options={companyStorages}
            component={SelectField}
            isMulti={false}
            disabled={!companyStorages.length}
            onChange={store => {
              setFieldValue('companyStore', store)
              fetchDevices(store.value)
            }}
          />
        </Col>
        <Col md={12}>
          <Field
            name='order'
            label={t('common.page.admin.devices_list')}
            labelClasses='text-muted'
            options={devicesList}
            component={SelectField}
            isMulti
            disabled={!devicesList.length}
            validate={required}
          />
        </Col> */}
      </Row>
    </>
  )
}

export default OrderFormCompany
