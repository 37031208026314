class DeviceType {
  constructor (data = {}) {
    const {
      sku,
      descriptionShort,
      descriptionLong,
      hardwareVersion,
      serialNumber,
      firmware,
      deviceFields
    } = data

    this.sku = sku
    this.hardwareVersion = hardwareVersion
    this.serialNumber = serialNumber
    this.descriptionShort = descriptionShort
    this.descriptionLong = descriptionLong
    this.firmware = firmware
    this.deviceFields = deviceFields
  }
}

Object.defineProperty(DeviceType, 'initialData', {
  value: {
    sku: '',
    hardwareVersion: '',
    serialNumber: '',
    deviceFields: '',
    descriptionShort: '',
    descriptionLong: '',
    firmware: []
  },
  writable: false
})

export default DeviceType
