import { useMutation, useQuery } from '@apollo/react-hooks'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BreadcrumbCommon from 'app/components/common/BreadcrumbCommon'
import DevicesTable from 'app/components/common/DevicesTable'
import HandlerComponent from 'app/components/common/HandlerComponent'
import BasePage from 'app/components/layout/BasePage'
import DevicesFilter, { FILTERS } from 'app/pages/devices/forms/DeviceFiltersForm'
import { DELETE_DEVICE } from 'gql/schema/devices/mutations'
import { CUSTOMER_DEVICES_LIST_QUERY, DEVICES_LIST_QUERY } from 'gql/schema/devices/queries'
import withCurrentUser from 'hoc/withCurrentUser'
import { isCompanyAdmin } from 'models/UsersType'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BreadcrumbItem } from 'reactstrap'

const CompanyDevicesList = withCurrentUser(({ filterHandler, filteredField, user: { role } }) => {
  const [devicesList, setDevicesList] = useState(null)
  /* FETCH DEVICES LIST */
  const { data, loading, error } = useQuery(DEVICES_LIST_QUERY, { fetchPolicy: 'no-cache' })

  /* HANDLE DEVICE LIST */
  useEffect(() => {
    if (data) {
      const { devicesList } = data
      const filtered = filterHandler(devicesList)
      setDevicesList(filtered)
    }
  }, [data, filteredField, filterHandler])

  /* DELETE DEVICE */
  const [deleteDevice] = useMutation(DELETE_DEVICE, {
    onCompleted ({ deleteDevice: { status } }) {
      if (status === 'ok') window.location.reload()
    }
  })

  const handleDelete = useCallback(async (id) => {
    if (deleteDevice) {
      await deleteDevice({ variables: { id } })
    }
  }, [deleteDevice])

  return (
    <>
      <HandlerComponent loading={loading} error={error}/>
      {devicesList && <DevicesTable devicesList={devicesList} userRole={role} deleteCallBack={handleDelete}/>}
    </>
  )
})

const CustomersDevicesList = withCurrentUser(({ user: { role } }) => {
  /* FETCH DEVICES LIST */
  const { data, loading, error } = useQuery(CUSTOMER_DEVICES_LIST_QUERY, { fetchPolicy: 'no-cache' })
  const customerDevicesList = useMemo(() => {
    return data ? data.customerDevicesList : null
  }, [data])

  return (
    <>
      <HandlerComponent loading={loading} error={error}/>
      {customerDevicesList && <DevicesTable devicesList={customerDevicesList} userRole={role}/>}
    </>
  )
})

const DevicesList = ({ user: { role } }) => {
  const { t } = useTranslation()
  const [filterShown, setFilterShown] = useState(false)
  const [filteredField, setFilteredFields] = useState(FILTERS)

  /* FILTER */
  const filterHandler = useCallback((list) => {
    const filtersCount = Object.keys(filteredField).length
    return list.filter(value => {
      const pass = []
      for (const key in filteredField) {
        const filter = filteredField[key]
        if (!filter || filter.resolver(value, filter.data)) pass.push(true)
      }
      return pass.length === filtersCount
    })
  }, [filteredField])

  return (
    <>
      <BreadcrumbCommon>
        <BreadcrumbItem active>{t('common.page.admin.devices_list')}</BreadcrumbItem>
        {/* ADD NEW USER BUTTON */}
        <li className='breadcrumb-menu'>
          <div className='btn-group' role='group'>
            <button className='btn btn-transparent' onClick={() => setFilterShown(!filterShown)}>
              <FontAwesomeIcon className='nav-icon' icon={faFilter}/>
              <span className='ml-1'>{t('labels.filter')}</span>
            </button>
          </div>
        </li>
      </BreadcrumbCommon>
      <DevicesFilter
        isVisible={filterShown}
        onStorageChange={(storage) => {
          setFilteredFields((prev) => ({
            ...prev,
            currentStorage: {
              ...prev.currentStorage,
              data: storage || null
            }
          }))
        }}
        onBuildingDateChange={(buildingDate) => {
          setFilteredFields((prev) => ({
            ...prev,
            buildingDate: {
              ...prev.buildingDate,
              data: buildingDate || null
            }
          }))
        }}
      />

      <BasePage title={t('common.page.admin.devices_list')}>
        <CompanyDevicesList filterHandler={filterHandler} filteredField={filteredField}/>
      </BasePage>
      {isCompanyAdmin(role) && <BasePage title={t('common.page.admin.sub_companies')}>
        <CustomersDevicesList/>
      </BasePage>}
    </>
  )
}

export default withCurrentUser(DevicesList)
