import { useQuery } from '@apollo/react-hooks'
import { faBox, faCheckCircle, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BreadcrumbCommon from 'app/components/common/BreadcrumbCommon'
import HandlerComponent from 'app/components/common/HandlerComponent'
import BasePage from 'app/components/layout/BasePage'
import { DEVICE_TYPE, DEVICE_TYPE_FIRMWARE } from 'gql/schema/devices/queries'
import withCurrentUser from 'hoc/withCurrentUser'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Alert, ListGroup, ListGroupItem } from 'reactstrap'
import CardBody from 'reactstrap/lib/CardBody'
import ListGroupItemHeading from 'reactstrap/lib/ListGroupItemHeading'
import ListGroupItemText from 'reactstrap/lib/ListGroupItemText'

const styles = {
  firmwareList: {
    container: {
      maxWidth: '310px',
      minWidth: '310px'
    },
    root: {
      maxHeight: '305px',
      overflow: 'hidden',
      overflowY: 'scroll'
    },
    shortDescription: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }
}

const DeviceFields = ({ deviceFields }) => {
  return <>
    {deviceFields
      .split(',')
      .map((value) => <Alert className='m-1 p-1' key={value} color="info">{value}</Alert>)
    }
  </>
}

const FirmwaresList = ({ deviceTypeId }) => {
  const { t } = useTranslation()
  const { data, loading, error } = useQuery(DEVICE_TYPE_FIRMWARE, {
    variables: {
      id: deviceTypeId
    }
  })
  return (
    <>
      <HandlerComponent loading={loading} error={error}/>
      {data && <div
        className='d-flex flex-row align-items-start justify-content-start flex-wrap'
        style={styles.firmwareList.root}
      >
        {data.deviceTypeFirmwareList
          .sort((a, b) => {
            if (a.isDefault) return -1
            return 0
          })
          .map(({ _id, version, filePath, descriptionShort, isDefault }) => {
            return (
              <ListGroup
                key={_id}
                className='m-2 p-0 border-0'
                style={styles.firmwareList.container}
              >
                <ListGroupItem color={isDefault ? 'danger' : 'info'}>
                  {isDefault && <FontAwesomeIcon className='mr-2' icon={faCheckCircle}/>}
                  {`${t('labels.version')}: ${version}`}
                </ListGroupItem>
                <ListGroupItem style={styles.firmwareList.shortDescription}>
                  <FontAwesomeIcon className='mr-2 text-primary' icon={faFileAlt}/>
                  {descriptionShort}
                </ListGroupItem>
                <ListGroupItem style={styles.firmwareList.shortDescription}>
                  <FontAwesomeIcon className='mr-2 text-primary' icon={faBox}/>
                  {filePath.split('/')
                    .pop()}
                </ListGroupItem>
              </ListGroup>
            )
          })}
      </div>}
    </>
  )
}

const DeviceTypeInfo = ({ data, id }) => {
  const { t } = useTranslation()

  if (!data) return null

  const { deviceType } = data

  return (
    <ListGroup>
      <ListGroupItem active>
        <ListGroupItemHeading>{t('labels.sku')}</ListGroupItemHeading>
        <ListGroupItemText>{deviceType.sku}</ListGroupItemText>
      </ListGroupItem>
      <ListGroupItem>
        <ListGroupItemHeading>{t('labels.deviceFields')}</ListGroupItemHeading>
        <div className='d-flex flex-row mb-0'>
          <DeviceFields deviceFields={deviceType.deviceFields}/>
        </div>
      </ListGroupItem>
      <ListGroupItem>
        <ListGroupItemHeading>{t('labels.descriptionShort')}</ListGroupItemHeading>
        {
          deviceType.descriptionShort ? <span>{deviceType.descriptionShort}</span> : <Alert color="light">
            {t('labels.noDescription')}
          </Alert>
        }
      </ListGroupItem>
      <ListGroupItem>
        <ListGroupItemHeading>{t('labels.descriptionLong')}</ListGroupItemHeading>
        {
          deviceType.descriptionLong ? <span>{deviceType.descriptionLong}</span> : <Alert color="light">
            {t('labels.noDescription')}
          </Alert>
        }
      </ListGroupItem>
      <ListGroupItem>
        <ListGroupItemHeading>{t('labels.firmwares')}</ListGroupItemHeading>
        <FirmwaresList deviceTypeId={id}/>
      </ListGroupItem>
    </ListGroup>
  )
}

const DeviceTypeView = withCurrentUser(() => {
  const { t } = useTranslation()
  const { id } = useParams()
  const deviceTypeQuery = useQuery(DEVICE_TYPE, {
    variables: {
      id
    }
  })

  return (
    <>
      <BreadcrumbCommon
        customPath={'/device/types'}
        customLinkText={t('common.page.admin.device_types')}/>
      <BasePage title={`${t('labels.deviceType')}: ${id}`}>
        <CardBody className='d-flex justify-content-start flex-column'>
          <HandlerComponent loading={deviceTypeQuery.loading} error={deviceTypeQuery.error}/>
          <DeviceTypeInfo data={deviceTypeQuery.data} id={id}/>
        </CardBody>
      </BasePage>
    </>
  )
})

export default DeviceTypeView
