import '@coreui/coreui'

import 'assets/styles/index.scss'

import { client } from 'libs/apollo'
import React from 'react'
import ReactDOM from 'react-dom'
import Root from './Root'
import * as serviceWorker from './serviceWorker'

const node = <Root client={client} />

const target = document.getElementById('rootContainer')
ReactDOM.render(node, target)

serviceWorker.unregister()
