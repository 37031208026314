import { faBuilding } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BreadcrumbCommon from 'app/components/common/BreadcrumbCommon'
import BasePage from 'app/components/layout/BasePage'
import SubCompanyList from 'app/pages/company/components/SubCompanyList'
import withCurrentUser from 'hoc/withCurrentUser'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { BreadcrumbItem } from 'reactstrap'

const SubCompanies = ({ user: { companyId } }) => {
  const { t } = useTranslation()

  return (
    <>
      <BreadcrumbCommon>
        <BreadcrumbItem active>{t('common.page.admin.sub_companies')}</BreadcrumbItem>
        {/* ADD NEW COMPANY BUTTON */}
        <li className='breadcrumb-menu d-md-down-none'>
          <div className='btn-group' role='group'>
            <Link to='/sub-company/create' className='btn btn-transparent'>
              <FontAwesomeIcon className='nav-icon' icon={faBuilding} />
              <span className='ml-1'>{t('labels.create')}</span>
            </Link>
          </div>
        </li>
      </BreadcrumbCommon>
      <BasePage title={t('common.page.admin.sub_companies')}>
        <SubCompanyList masterCompanyID={companyId} />
      </BasePage>
    </>
  )
}

export default withCurrentUser(SubCompanies)
