const ADMIN = 'admin'
const COMPANY_ADMIN = 'company_admin'
const CLIENT_COMPANY_ADMIN = 'client_company_admin'
const SELLER = 'seller'
const TESTER = 'tester'

export const isAdmin = (role) => role === ADMIN || role === COMPANY_ADMIN
export const isSuperAdmin = (role) => role === ADMIN
export const isCompanyAdmin = (role) => role === COMPANY_ADMIN
export const isClientCompanyAdmin = (role) => role === CLIENT_COMPANY_ADMIN

export default {
  ADMIN: ADMIN,
  COMPANY_ADMIN: COMPANY_ADMIN,
  CLIENT_COMPANY_ADMIN: CLIENT_COMPANY_ADMIN,
  SELLER: SELLER,
  TESTER: TESTER
}
