import cx from 'classnames'
import * as PropTypes from 'prop-types'
import React from 'react'
import { Util } from 'reactstrap'

const Heading = (props) => {
  const {
    tag: Tag,
    className,
    size,
    ...attributes
  } = props

  const classes = cx(`h${size}`, 'd-block', className)

  return <Tag {...attributes} className={classes} />
}

Heading.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  tag: Util.tagPropType,
  size: PropTypes.number
}

Heading.defaultProps = {
  tag: 'span',
  size: 1
}

export default Heading
