import gql from 'graphql-tag'

export const COMPANY_STORAGES = gql`
    query ($id:ID!) {
        companyStorages(id: $id){
            _id
            name
            default
        }
    }
`

export const STORAGES_LIST = gql`
    query {
        storageList {
            _id
            name
            address
            type
            companyId
            default
        }
    }
`

export const STORAGES_LIST_TO_MOVE = gql`
    query {
        storageListToMove {
            _id
            name
            address
            type
            companyId
            default
        }
    }
`

export const CLIENTS_STORAGES = gql`
    query {
        customerStorageList {
            _id
            name
            address
            type
            companyId
            default
        }
    }
`
