import { useMutation } from '@apollo/react-hooks'
import BreadcrumbCommon from 'app/components/common/BreadcrumbCommon'
import BasePage from 'app/components/layout/BasePage'
import CompanyForm from 'app/pages/company/components/CompanyForm'
import { CREATE_COMPANY } from 'gql/schema/company/mutations'
import { COMPANIES_LIST } from 'gql/schema/company/queries'
import withCurrentUser from 'hoc/withCurrentUser'
import Company from 'models/company'
import { isSuperAdmin } from 'models/UsersType'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { BreadcrumbItem } from 'reactstrap'
import CardBody from 'reactstrap/lib/CardBody'

/* CREATE COMPANY PAGE */
const CreateCompany = ({ user }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [createCompany] = useMutation(CREATE_COMPANY, {
    onCompleted: () => {
      history.push('/companies')
    },
    onError: (e) => {
      console.warn(e.message)
    },
    refetchQueries: [
      { query: COMPANIES_LIST }
    ]
  })

  const handleSubmit = useCallback(async (values) => {
    /* create base company */
    if (createCompany && isSuperAdmin(user.role)) {
      const companyAdmins = values.companyAdmins.map(admin => admin.value)
      if (typeof values.companyLogo === 'string') {
        values.companyLogo = null
      }

      await createCompany({
        variables: {
          ...values,
          companyAdmins,
          defaultCompany: values.defaultCompany.value
        }
      })
    }
  }, [createCompany, user])

  return (
    <>
      <BreadcrumbCommon>
        <BreadcrumbItem active>{t('common.page.admin.create')}</BreadcrumbItem>
      </BreadcrumbCommon>
      <BasePage title={t('common.page.admin.create_company')}>
        <CardBody>
          <CompanyForm onSubmit={handleSubmit} initialValues={Company.initialData} enableReinitialize/>
        </CardBody>
      </BasePage>
    </>
  )
}

export default withCurrentUser(CreateCompany)
