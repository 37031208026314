import { useMutation, useQuery } from '@apollo/react-hooks'
import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HandlerComponent from 'app/components/common/HandlerComponent'
import { DELETE_CUSTOMER_COMPANY } from 'gql/schema/customerCompany/mutations'
import { CUSTOMER_COMPANIES } from 'gql/schema/customerCompany/queries'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Table } from 'reactstrap'

const SubCompanyList = ({ masterCompanyID }) => {
  const { t } = useTranslation()

  const { data, loading, error } = useQuery(CUSTOMER_COMPANIES, {
    variables: {
      id: masterCompanyID
    }
  })

  const [deleteCustomerCompany] = useMutation(DELETE_CUSTOMER_COMPANY, {
    onCompleted (value) {
      window.location.reload()
    },
    fetchPolicy: 'no-cache'
  })

  const deleteCompany = useCallback(async (id) => {
    if (deleteCustomerCompany) {
      await deleteCustomerCompany(
        {
          variables: {
            id
          }
        }
      )
    }
  }, [deleteCustomerCompany])

  return (
    <>
      <HandlerComponent loading={loading} error={error} />
      {data && <Table borderless responsive className='table-hover'>
        <thead>
          <tr>
            <th>{t('labels.company.name')}</th>
            <th>{t('labels.company.contact_name')}</th>
            <th>{t('labels.company.email')}</th>
            <th className='text-center'>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.customerCompaniesList.map(subCompany => {
            return (
              <tr key={subCompany._id}>
                <td>{subCompany.name}</td>
                <td>{subCompany.contactName || '-'}</td>
                <td>{subCompany.email}</td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <Link to={`/sub-company/edit/${subCompany._id}`} className='btn btn-ghost-info'>
                      <FontAwesomeIcon icon={faEdit} />
                    </Link>
                    <button
                      className='btn btn-ghost-danger'
                      type='button'
                      onClick={() => deleteCompany(subCompany._id)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>}
    </>
  )
}

export default SubCompanyList
