import { useQuery } from '@apollo/react-hooks'
import { StorageQuery } from 'gql/schema'

const useFetchStorage = ({ variables }) => {
  return useQuery(StorageQuery, {
    variables: variables,
    fetchPolicy: 'network-only'
  })
}

export default useFetchStorage
