import { useMutation } from '@apollo/react-hooks'
import { StorageCreateMutation } from 'gql/schema'

const useCreateStorage = ({ onComplete, refetchQueries }) => {
  return useMutation(StorageCreateMutation, {
    onCompleted (data) {
      onComplete && onComplete(data)
    },
    refetchQueries: refetchQueries ? [{ query: refetchQueries }] : null
  })
}

export default useCreateStorage
