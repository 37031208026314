import { useMutation } from '@apollo/react-hooks'

import Heading from 'app/components/Heading'
import Text from 'app/components/Text'
import { loader } from 'graphql.macro'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import styled from 'styled-components'
import RecoverForm from './RecoverForm'

const resetPasswordMutation = loader('gql/schema/auth/resetPassword.graphql')

const RecoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #20a8d8;
  
  .card {
    margin: 10vh 0 0;
    min-width: 40vw;
    min-height: 20vh;
    flex-direction: column;
    justify-content: center;
  }
`

const body = ({ reseted, onSubmit, t }) => {
  if (reseted) {
    return (
      <>
        <Heading className='text-left' size={2}>{t('auth.password_reset_sent_text_1')}</Heading>
        <Heading className='text-left mb-3' size={4}>{t('auth.password_reset_sent_text_2')}</Heading>
        <Text tag={Link} to='/' fontWeight='bold'>
          {t('labels.back_to_home')}
        </Text>
      </>
    )
  }
  return <RecoverForm onSubmit={onSubmit} initialValues={{ email: '' }} />
}

const RecoverPage = () => {
  const { t } = useTranslation()

  const [reseted, setPasswordReseted] = useState(false)
  const [resetPassword] = useMutation(resetPasswordMutation, {
    onCompleted () {
      setPasswordReseted(true)
    }
  })

  const onSubmit = (values) => {
    return resetPassword({ variables: { ...values } })
  }

  return (
    <RecoverContainer>
      <Card className='p-4'>
        <CardBody>
          {body({ reseted: reseted, onSubmit: onSubmit, t: t })}
        </CardBody>
      </Card>
    </RecoverContainer>
  )
}

export default RecoverPage
