import BaseFooter from 'app/components/layout/BaseFooter'
import AdminHeader from 'app/components/layout/BaseHeader'
import Sidebar from 'app/pages/sidebar'
import React from 'react'

document.body.classList.add('header-fixed', 'sidebar-fixed', 'sidebar-lg-show')

const BaseLayout = ({ children }) => {
  return (
    <div id='root' className='app'>
      <AdminHeader />
      <div className='app-body'>
        <Sidebar />
        <div className='main'>
          {children}
        </div>
      </div>
      <BaseFooter />
    </div>
  )
}

export default BaseLayout
