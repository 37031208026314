import gql from 'graphql-tag'

export const USER_QUERY = gql`
    query user($id:String) {
        user(_id:$id) {
            _id
            name
            surname
            role
            privacy {
                users
                companies
                stores
                devices
            }
            active
            email
            companyId
            company {
                _id
                name
            }
            localization
        }
    }
`

export const COMPANY_ADMINS = gql`
    query {
        companyAdmins {
            _id
            name
            surname
            role
            privacy {
                users
                companies
                stores
                devices
            }
            active
            email
            companyId
        }
    }
`

export const ME_QUERY = gql`
    query me {
        me {
            _id
            name
            surname
            role
            active
            email
            companyId
            company {
                name
                companyLogo
            }
            privacy {
                users
                companies
                stores
                devices
            }
            localization
        }
    }
`
