import swal from '@sweetalert/with-react'

export const DeleteAlert = (t, value) => {
  return swal({
    icon: 'warning',
    title: t('dialog.warning_title'),
    text: `${t('dialog.delete_confirm')}\n${value || ''}`,
    buttons: {
      confirm: t('dialog.confirm_text'),
      cancel: t('dialog.cancel_text')
    }
  })
}
