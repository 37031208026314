import { useQuery } from '@apollo/react-hooks'
import { UsersListQuery } from 'gql/schema'

const useFetchUsers = () => {
  return useQuery(UsersListQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })
}
export default useFetchUsers
