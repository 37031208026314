import { useQuery } from '@apollo/react-hooks'
import { faBuilding } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BreadcrumbCommon from 'app/components/common/BreadcrumbCommon'
import HandlerComponent from 'app/components/common/HandlerComponent'
import BasePage from 'app/components/layout/BasePage'
import CompaniesList from 'app/pages/company/components/CompaniesList'
import { CompaniesListQuery } from 'gql/schema'
import { COMPANIES_LIST } from 'gql/schema/company/queries'
import withCurrentUser from 'hoc/withCurrentUser'
import useDeleteCompany from 'hooks/gql/useDeleteCompany'
import { isSuperAdmin } from 'models/UsersType'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { BreadcrumbItem } from 'reactstrap'

const Companies = ({ user }) => {
  const { t } = useTranslation()
  /* FETCH COMPANIES */
  const { loading, error, data } = useQuery(COMPANIES_LIST)

  /* DELETE COMPANY MUTATION */
  const [deleteCompany] = useDeleteCompany({
    refetchQueries: CompaniesListQuery
  })
  const onUserDelete = useCallback(async (id) => {
    await deleteCompany({ variables: { id: id } })
  }, [deleteCompany])

  return (
    <>
      <BreadcrumbCommon>
        <BreadcrumbItem active>{t('common.page.admin.companies_list')}</BreadcrumbItem>
        {/* ADD NEW COMPANY BUTTON */}
        <li className='breadcrumb-menu d-md-down-none'>
          <div className='btn-group' role='group'>
            {isSuperAdmin(user.role) && <Link to='/company/create' className='btn btn-transparent'>
              <FontAwesomeIcon className='nav-icon' icon={faBuilding}/>
              <span className='ml-1'>{t('labels.create')}</span>
            </Link>}
          </div>
        </li>
      </BreadcrumbCommon>
      <BasePage title={t('common.page.admin.companies_list')}>
        <HandlerComponent loading={loading} error={error}/>
        {data && <CompaniesList companiesList={data.companiesList} deleteCompanyHandler={onUserDelete}/>}
      </BasePage>
    </>
  )
}

export default withCurrentUser(Companies)
