import { useMutation, useQuery } from '@apollo/react-hooks'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DeleteAlert } from 'app/components/alerts'
import BreadcrumbCommon from 'app/components/common/BreadcrumbCommon'
import DeviceTypesTable from 'app/components/common/DeviceTypesTable'
import HandlerComponent from 'app/components/common/HandlerComponent'
import BasePage from 'app/components/layout/BasePage'
import { DELETE_DEVICE_TYPE } from 'gql/schema/devices/mutations'
import { DEVICE_TYPES_LIST } from 'gql/schema/devices/queries'
import withCurrentUser from 'hoc/withCurrentUser'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { BreadcrumbItem } from 'reactstrap'
import CardBody from 'reactstrap/lib/CardBody'

const DeviceTypesList = withCurrentUser(() => {
  const { t } = useTranslation()
  const { data, loading, error } = useQuery(DEVICE_TYPES_LIST)
  const [deleteDeviceTypeMutation] = useMutation(DELETE_DEVICE_TYPE, {
    onCompleted (data) {
    },
    onError (error) {
      console.error(error)
    },
    refetchQueries: [{ query: DEVICE_TYPES_LIST }]
  })

  const deleteDeviceType = useCallback((deviceTypeId) => {
    const id = deviceTypeId
    if (deleteDeviceTypeMutation) {
      DeleteAlert(t)
        .then((actionConfirmed) => {
          actionConfirmed && deleteDeviceTypeMutation({
            variables: { id }
          })
        })
    }
  }, [deleteDeviceTypeMutation, t])

  return (
    <>
      <BreadcrumbCommon>
        <BreadcrumbItem active>{t('common.page.admin.device_types')}</BreadcrumbItem>
        {/* ADD NEW USER BUTTON */}
        <li className='breadcrumb-menu'>
          <div className='btn-group' role='group'>
            <Link to='/device/types/create' className='btn btn-transparent'>
              <FontAwesomeIcon className='nav-icon' icon={faPlus}/>
              <span className='ml-1'>{t('labels.create')}</span>
            </Link>
          </div>
        </li>
      </BreadcrumbCommon>
      <BasePage title={t('common.page.admin.device_types')}>
        <CardBody>
          <HandlerComponent loading={loading} error={error}/>
          {data && <DeviceTypesTable list={data.deviceTypesList} deleteHandler={deleteDeviceType}/>}
        </CardBody>
      </BasePage>
    </>
  )
})

export default DeviceTypesList

/*
const UploadFile = () => {
  const [uploadFileMutation] = useMutation(UPLOAD_FILE)
  const apolloClient = useApolloClient()

  const onChange = ({
    target: {
      validity,
      files: [file]
    }
  }) =>
    validity.valid &&
    uploadFileMutation({ variables: { file } })
      .then(() => {
        apolloClient.resetStore()
      })

  return <input type="file" required onChange={onChange}/>
}
*/
