import { useApolloClient, useQuery } from '@apollo/react-hooks'
import SelectField from 'app/components/forms/SelectField'
import { Field } from 'formik'
import { CUSTOMER_COMPANIES_FOR_ORDER } from 'gql/schema/customerCompany/queries'
import { COMPANY_STORAGES } from 'gql/schema/storage/queries'
import withCurrentUser from 'hoc/withCurrentUser'
import { required } from 'libs/forms/validators'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'

const OrderFormCustomer = (
  {
    user,
    setFieldValue
  }
) => {
  const { t } = useTranslation()
  const client = useApolloClient()

  /* retrieve clients companies list */
  const customersQuery = useQuery(CUSTOMER_COMPANIES_FOR_ORDER, {
    variables: {
      id: user.companyId
    }
  })
  const customersOptions = useMemo(() => {
    if (customersQuery.data) {
      const { customerCompaniesListForOrder } = customersQuery.data
      return customerCompaniesListForOrder.map(v => {
        return {
          value: v._id,
          label: v.name
        }
      })
    } else {
      return null
    }
  }, [customersQuery.data])

  /* customer storages list */
  const [customerStorages, setCustomerStorages] = useState([])
  const fetchStorages = useCallback(async v => {
    const { data: { companyStorages } } = await client.query({
      query: COMPANY_STORAGES,
      variables: {
        id: v
      }
    })
    setCustomerStorages(companyStorages.map(company => {
      return {
        label: company.name,
        value: company._id
      }
    }))
  }, [setCustomerStorages, client])

  return (
    <>
      <Row>
        <Col md={12}>
          <Field
            name='customerId'
            label={t('labels.customer')}
            labelClasses='text-muted'
            options={customersOptions}
            component={SelectField}
            isMulti={false}
            disabled={!customersOptions}
            onChange={(v) => {
              fetchStorages(v.value)
              setFieldValue('customerId', v)
            }}
            validate={required}
          />
        </Col>
        <Col md={12}>
          <Field
            name='deliveryStorageId'
            label={t('labels.storage.name')}
            labelClasses='text-muted'
            options={customerStorages}
            component={SelectField}
            isMulti={false}
            disabled={!customerStorages.length}
            validate={required}
          />
        </Col>
      </Row>
    </>
  )
}

export default withCurrentUser(OrderFormCustomer)
